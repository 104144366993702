<template>
  <div
    :dir="locale === 'en' ? 'ltr' : 'rtl'"
    class="preview-wrapper flex relative h-full overflow-hidden">
    <RightDrawer
      v-if="!hideOutline"
      :show="showMenu"
      @update:show="showMenu = !showMenu"
      @open="showCompanionBible = false">
      <LessonMenu
        :title="title"
        :outline="outline"
        :sections="sections"
        :current-outline="currentOutline"
        :current-section="currentSection"
        :last-section-index="lastSectionIndex"
        @click:outline="handleOutline"
        @click:navigation="enterSection"/>
    </RightDrawer>
    <div
      v-if="!hideOutline"
      class="hidden md:block p-2 h-full bg-secondary-75 flex cursor-pointer"
      :style="{ 'z-index': 998 }"
      @click="
        showMenu = !showMenu;
        showCompanionBible = false;
      ">
      <div
        class="flex justify-center items-center bg-white w-8 h-8 rounded-md hover:bg-secondary-300">
        <Transition name="slide">
          <Menu v-if="!showMenu" />
          <ChevronRight v-else :class="{ 'scale-x-[-1]': !rtl }" />
        </Transition>
      </div>
    </div>
    <PresentationDeck :id="id">
      <template #banner>
        <slot name="banner" />
      </template>
      <template #controls>
        <slot name="controls" />
      </template>
    </PresentationDeck>
    <LeftDrawer :show="showCompanionBible" @open="handleOpen" />
  </div>
</template>

<script setup lang="ts">
// @ts-nocheck
import PresentationDeck from './PresentationDeck.vue';
import { LessonMenu } from '@/components/index';
import { computed, provide, reactive, ref } from 'vue';
import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';
import { Menu, ChevronRight } from 'lucide-vue-next';
import RightDrawer from '@/components/RightDrawer.vue';
import LeftDrawer from '@/components/LeftDrawer.vue';
import { PROVIDE_TELEPORT_TARGETS } from './teleport/const';

provide(PROVIDE_TELEPORT_TARGETS, reactive({}));
provide('inDeck', ref(true));

withDefaults(
  defineProps<{
    id: string;
    title: string;
    hideOutline: boolean;
  }>(),
  {
    id: '',
    title: '',
    hideOutline: false,
  },
);

const { onOutlineChange, onNavigationChange } = useInteractions();
const handleOutline = selected => onOutlineChange.trigger(selected);
const showMenu = ref(true);
const showCompanionBible = ref(false);
const {
  outline,
  sections,
  lastSectionIndex,
  trackSlides,
  onSlide,
  toSlide,
  rtl,
  locale,
} = useReveal();
const currentSection = ref();
const currentOutline = computed(() => {
  let selected = outline.value.find(item => item.selected);
  let current = selected || outline.value[0];
  return current?.id;
});
defineEmits(['close-menu']);

const handleOpen = () => {
  showCompanionBible.value = true;
  showMenu.value = false;
};

onSlide(({ event }) => {
  let page = trackSlides.value.find(
    (page: any) => page.id === event.currentSlide.dataset.page,
  );

  currentSection.value = sections.value.find(
    lessonSection => lessonSection.id === page?.section,
  )?.id;
});

const isSectionAllowed = sectionIndex => {
  return sectionIndex <= lastSectionIndex.value;
};

const enterSection = (section, sectionIndex) => {
  if (!isSectionAllowed(sectionIndex)) return;
  onNavigationChange.trigger({});
  currentSection.value = section;
  toSlide(trackSlides.value.findIndex((page: any) => page.section === section));
};
</script>

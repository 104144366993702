<template>
  <SideDrawer name="users">
    <div class="flex flex-col justify-start gap-7 mb-7 w-60">
      <div class="flex flex-col gap-2 justigy-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.first_name') }}
          <span class="px-0.5 text-red-500 translate-y-1">*</span>
        </div>
        <BaseInput
          v-model="selectedUser.first_name"
          :disabled="isShachafUser"
          class="w-full"
          :class="{ 'cursor-not-allowed': isShachafUser }"></BaseInput>
      </div>
      <div class="flex flex-col gap-2 justigy-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.last_name') }}
          <span class="px-0.5 text-red-500 translate-y-1">*</span>
        </div>
        <BaseInput
          v-model="selectedUser.last_name"
          :disabled="isShachafUser"
          class="w-full"
          :class="{ 'cursor-not-allowed': isShachafUser }"></BaseInput>
      </div>
      <div v-if="!isShachafUser" class="flex flex-col gap-2 justify-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.grade') }}
        </div>
        <BaseSelect
          :current-value="getHebrewGrade(selectedUser.grade)"
          class="rounded-lg base-input"
          :class="{ 'cursor-not-allowed': isShachafUser }"
          :options="hebrewGrades"
          :fixed="false"
          @select="handleGradeSelect"/>
      </div>
      <div class="flex flex-col gap-2 justigy-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.grade_number') }}
        </div>
        <BaseInput
          v-model="selectedUser.gradeNumber"
          :disabled="isShachafUser"
          type="number"
          class="w-full"
          :class="{ 'cursor-not-allowed': isShachafUser }"></BaseInput>
      </div>
      <div class="flex flex-col gap-2 justigy-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.id_number') }}
          <span
            v-if="!selectedUser.id"
            class="px-0.5 text-red-500 translate-y-1">
            *
          </span>
        </div>
        <BaseInput
          v-model="selectedUser.id_number"
          :disabled="isShachafUser"
          class="w-full"
          :class="{ 'cursor-not-allowed': isShachafUser }"></BaseInput>
      </div>
      <div v-if="!isShachafUser" class="flex flex-col gap-2 justigy-start">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.gender') }}
          <span class="px-0.5 text-red-500 translate-y-1">*</span>
        </div>
        <BaseSelect
          :current-value="getHebrewGender(selectedUser.gender)"
          class="rounded-lg base-input"
          :class="{ 'cursor-not-allowed': isShachafUser }"
          :options="hebrewOptions"
          :fixed="false"
          @select="handleSelect"/>
      </div>
      <div class="flex flex-col gap-4">
        <div
          class="text-base font-SimplerRegular text-secondary-975 flex items-center">
          {{ t('users.role') }}
          <span class="px-0.5 text-red-500 translate-y-1">*</span>
        </div>

        <div class="card flex flex-wrap justify-center gap-4 min-w-80">
          <div class="flex items-center gap-2">
            <PVCheckbox
              v-model="selectedUser.role"
              :disabled="isShachafUser"
              name="student_role"
              value="student"/>
            <label for="student_role">{{ t('users.student') }}</label>
          </div>
          <div class="flex items-center gap-2">
            <PVCheckbox
              v-model="selectedUser.role"
              :disabled="isShachafUser"
              name="teacher_role"
              value="teacher"/>
            <label for="teacher_role">{{ t('users.teacher') }}</label>
          </div>
          <div class="flex items-center gap-2">
            <PVCheckbox
              v-model="selectedUser.role"
              :disabled="isShachafUser"
              name="editor_role"
              value="editor"/>
            <label for="editor_role">{{ t('users.editor') }}</label>
          </div>
          <div class="flex items-center gap-2">
            <PVCheckbox
              v-model="selectedUser.role"
              :disabled="isShachafUser"
              name="admin_role"
              value="admin"/>
            <label for="admin_role">{{ t('users.admin') }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center gap-2 max-w-sm">
      <div class="flex items-center justify-between w-full gap-2">
        <div class="flex flex-col items-start">
          <div
            class="text-base font-SimplerRegular text-secondary-975 flex items-center">
            {{ t('users.user_name') }}
            <span
              v-if="!selectedUser.id"
              class="px-0.5 text-red-500 translate-y-1">
              *
            </span>
          </div>
          <BaseInput v-model="selectedUser.username" class="w-full"></BaseInput>
        </div>
        <div class="flex flex-col items-start">
          <div
            class="text-base font-SimplerRegular text-secondary-975 flex items-center">
            {{ t('users.password') }}
            <span
              v-if="!selectedUser.id"
              class="px-0.5 text-red-500 translate-y-1">
              *
            </span>
          </div>
          <BaseInput v-model="selectedUser.password" class="w-full"></BaseInput>
        </div>
      </div>
    </div>
  </SideDrawer>
</template>

<script setup lang="ts">
import { SideDrawer, useDrawer } from '@amit/layout';
import BaseSelect from '../partials/BaseSelect.vue';
import { BaseInput } from '@nwire/amit-design-system';
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useSchoolStore } from '/@/views/playground/store/school-store';
import { createUser, updateUser } from '../school-service';
import swal from 'sweetalert';
import { useI18n } from 'vue-i18n';
import validator from 'validator';
import { isEmpty, isNumber } from 'lodash';
import PVCheckbox from 'primevue/checkbox';
import { hasChanges } from '@/utils';

const schoolStore = useSchoolStore();
const { openDrawer, onDrawerAction, closeDrawer, setActionDisable } =
  useDrawer();

const { t } = useI18n();

const route = useRoute();

interface User {
  first_name: string;
  last_name: string;
  id_number: string;
  school: string | string[];
  gender: 'male' | 'female';
  role: Array<'student' | 'teacher' | 'editor' | 'admin'>;
  username: string;
  password: string;
  grade: number;
  gradeNumber: number;
  groups: string[];
}

const selectedUser = ref<User>({
  first_name: '',
  last_name: '',
  id_number: '',
  school: route.params.school as string,
  gender: 'male',
  role: ['student'],
  username: '',
  password: '',
  grade: 0,
  gradeNumber: 0,
  groups: [],
});
const initialUserData = ref({} as User);

const isShachafUser = computed(() => {
  return 'sis_id' in selectedUser.value;
});

const hebrewOptions = !isShachafUser.value ? ['זכר', 'נקבה'] : [];
const hebrewGrades = !isShachafUser.value
  ? ['ז', 'ח', 'ט', 'י', 'יא', 'יב']
  : [];

const genderMap = {
  זכר: 'male',
  נקבה: 'female',
};

const reverseGenderMap = {
  male: t('users.male'),
  female: t('users.female'),
};

watch(
  () => selectedUser.value.grade,
  () => {
    if (!isNumber(selectedUser.value.grade)) {
      selectedUser.value.grade = null;
    }
  },
);

const getHebrewGender = gender => reverseGenderMap[gender] || '';
const getHebrewGrade = grade => {
  return hebrewGrades[grade - 7];
};

const handleSelect = selectedOption => {
  selectedUser.value.gender = genderMap[selectedOption];
};

const handleGradeSelect = selectedOption => {
  const index = hebrewGrades.findIndex(grade => grade === selectedOption);
  selectedUser.value.grade = index + 7;
};

const ensureUserFields = (user, fields) => {
  fields.forEach(field => {
    if (!Object.prototype.hasOwnProperty.call(user, field)) {
      user[field] = '';
    }
  });
};

const open = userId => {
  if (userId) {
    selectedUser.value = {
      ...schoolStore.users.find(user => user.id === userId),
    } as User;
    ensureUserFields(selectedUser.value, [
      'first_name',
      'last_name',
      'gender',
      'role',
      'username',
      'password',
      'id_number',
    ]);
    initialUserData.value = JSON.parse(JSON.stringify(selectedUser.value));
  } else {
    selectedUser.value = null;
  }

  if (!selectedUser.value?.id) {
    selectedUser.value = {
      first_name: '',
      last_name: '',
      id_number: '',
      school: route.params.school,
      gender: 'male',
      role: ['student'],
      username: '',
      password: '',
      groups: [],
    };
  }

  openDrawer({
    name: 'users',
    title: selectedUser.value?.id
      ? t('users.edit_user')
      : t('users.create_user'),
    isDrawerActionDisabled: true,
  });
};

defineExpose({ open });

watch(
  selectedUser,
  newValue => {
    if (initialUserData.value) {
      const hasChange = hasChanges(newValue, initialUserData.value);
      setActionDisable(!hasChange);
    }
  },
  { deep: true },
);

watch(
  () => selectedUser.value.role,
  newRole => {
    const isInvalidRoleCombination =
      Array.isArray(newRole) &&
      newRole.includes('student') &&
      newRole.length > 1;

    if (isInvalidRoleCombination) {
      setActionDisable(true);
    } else {
      const hasChange = hasChanges(selectedUser.value, initialUserData.value);
      setActionDisable(!hasChange);
    }
  },
  { deep: true },
);

const validateForm = () => {
  if (isShachafUser.value) return true;
  const validations = {
    firstNameValid: !validator.isEmpty(selectedUser.value.first_name.trim()),
    lastNameValid: !validator.isEmpty(selectedUser.value.last_name.trim()),
    genderValid: !validator.isEmpty(selectedUser.value.gender.trim()),
    roleValid:
      Array.isArray(selectedUser.value.role) &&
      selectedUser.value.role.length > 0 &&
      selectedUser.value.role.every(role => !validator.isEmpty(role.trim())) &&
      ((selectedUser.value.role.includes('student') &&
        selectedUser.value.role.length === 1) ||
        !selectedUser.value.role.includes('student')),
  };
  if (Object.values(validations).every(Boolean)) {
    setActionDisable(true);
  }
  return Object.values(validations).every(Boolean);
};

const validateMinLength = (inputString: string, minLength: number) => {
  const stringWithoutSpaces = validator.blacklist(inputString, ' ');

  return stringWithoutSpaces.length >= minLength;
};

const showMessage = (type, action, showText = true) => {
  const message = {
    title: t(`users.${type}.${action}.title`),
    text: showText ? t(`users.${type}.${action}.text`) : '',
    icon: type === 'success' ? 'success' : 'error',
  };
  swal(message);
};

onDrawerAction.on(() => {
  const formIsValid = validateForm();
  const userNameValid = isEmpty(selectedUser.value.username)
    ? !isEmpty(selectedUser.value.id)
    : validateMinLength(selectedUser.value.username, 4);
  const passwordValid = isEmpty(selectedUser.value.password)
    ? !isEmpty(selectedUser.value.id)
    : validateMinLength(selectedUser.value.password, 8);
  const idNumberValid = isEmpty(selectedUser.value.id_number)
    ? !isEmpty(selectedUser.value.id)
    : selectedUser.value.id_number.split('').length === 9;
  if (!formIsValid) {
    showMessage('errors', 'invalid_form');
    return;
  }
  if (!userNameValid) {
    showMessage('errors', 'invalid_user_name');
    return;
  }
  if (!passwordValid) {
    showMessage('errors', 'invalid_password');
    return;
  }
  if (!idNumberValid) {
    showMessage('errors', 'invalid_id');
    return;
  }

  const data = JSON.parse(JSON.stringify(selectedUser.value));

  if (!Array.isArray(data.role)) {
    data.role = [data.role];
  }

  const successMessageType = selectedUser.value?.id ? 'edited' : 'created';

  let operation = selectedUser.value?.id ? updateUser : createUser;
  const { onError, onSuccess } = operation(data);

  onSuccess(user => {
    showMessage('success', successMessageType, false);
    selectedUser.value.password = '';
    if (selectedUser.value?.id) {
      let index = schoolStore.users.findIndex(
        u => u.id === selectedUser.value.id,
      );
      schoolStore.users.splice(index, 1, user.data);
    } else {
      schoolStore.users.push(user.data);
    }
    selectedUser.value = {
      first_name: '',
      last_name: '',
      id_number: '',
      school: route.params.school,
      gender: 'male',
      role: ['student'],
      username: '',
      password: '',
      groups: [],
    };
    closeDrawer();
  });

  const errorMap = {
    'id-duplicate': 'id_number_duplication_error',
    'username-duplicate': 'user_name_duplication_error',
    'general-error': 'server_error',
  };

  onError(error => {
    showMessage('errors', errorMap[error.message]);
  });
});
</script>

<style scoped>
.custom-radio {
  @apply appearance-none h-5 w-5 border-2 rounded-full transition-all duration-200 ease-in-out;
  position: relative;
}

.custom-radio:checked {
  @apply border-blue-600;
}

.custom-radio:focus {
  @apply ring-2 ring-gray-300 ring-offset-2;
}

.custom-radio:checked::before {
  content: '';
  @apply absolute w-2 h-2 bg-blue-600 rounded-full;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<template>
  <PVDialog
    v-model:visible="weightManagement.isWeightManagementDialogOpen"
    modal
    closable
    :dismissable-mask="false"
    class="transition-all duration-500"
    :style="{ minWidth: '30vw', maxWidth: '80vw', width: 'unset' }"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      content: {
        class: ['overflow-x-auto !p-0'],
      },
      footer: {
        class: [
          'border-t-1 border-t-secondary-200 pt-2 px-5 pb-3 !justify-between',
        ],
      },
    }">
    <template #header>
      <p class="text-secondary-800 font-semibold">ניהול מרכיבי הקורס</p>
    </template>

    <div class="p-5 inline-block min-w-full">
      <div class="flex gap-3">
        <div
          class="flex flex-col gap-3 bg-secondary-50 px-5 py-3 flex-shrink-0">
          <p class="text-secondary-800 text-sm h-[24px] content-center">
            שיעורים
          </p>
          <div
            class="flex gap-y-2 gap-x-10 flex-wrap flex-col max-h-[60vh] min-w-[240px]">
            <div
              v-for="item in weightManagement._updatedData.filter(item =>
                ['assessment', 'lesson'].includes(item.type ?? ''),
              )"
              :key="item.id"
              class="flex flex-grow-0 gap-4 items-center w-fit">
              <label
                v-tooltip.top="item.title"
                for="criteria"
                class="text-secondary-900 truncate w-[130px]">
                {{ item.title }}
              </label>
              <WeightField
                :weight="item.weight"
                size="small"
                @update-weight="
                  newWeight => {
                    item.weight = newWeight;
                  }
                "/>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col gap-3 bg-secondary-50 px-5 py-3 flex-shrink-0">
          <div class="flex justify-between h-[24px] items-center">
            <p class="text-secondary-800 text-sm">מרכיבי הערכה נוספים</p>
            <PVButton
              severity="neutral"
              :size="'small'"
              class="!px-1.5 !py-[3px] !m-0.5"
              @click="addNewCriteria">
              <template #icon>
                <Plus :size="12" />
              </template>
            </PVButton>
          </div>
          <div
            class="flex gap-y-2 gap-x-10 flex-wrap flex-col max-h-[60vh] w-[240px]">
            <div
              v-for="(item, index) in weightManagement._updatedData.filter(
                item => item.type === 'criteria',
              )"
              :key="item.id"
              class="flex flex-grow-0 gap-2 items-center w-fit group relative">
              <div class="relative w-[150px]">
                <label
                  for="criteria"
                  class="text-secondary-900 truncate absolute inset-0 transition-all duration-0 group-hover:opacity-0 group-hover:invisible flex items-center"
                  :class="{ '!opacity-0 !invisible': !item.title }">
                  {{ item.title }}
                </label>
                <PVInputText
                  id="criteria"
                  v-model="item.title"
                  placeholder="מרכיב הערכה"
                  autocomplete="off"
                  :invalid="hasEmptyTitles && !item.title"
                  :class="[
                    '!px-2 !py-2.5 w-[120px] invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200',
                    {
                      '!visible !opacity-100': !item.title,
                    },
                  ]"
                  :pt-options="{ mergeSections: false, mergeProps: true }"
                  :pt="{
                    root: () => ({
                      class: [
                        'focus:ring-1 focus:ring-primary focus:border-primary focus:z-10',
                      ],
                    }),
                  }"/>
              </div>

              <WeightField
                :weight="item.weight"
                size="small"
                class="transition-all duration-300 group-hover:translate-x-[32px]"
                @update-weight="
                  newWeight => {
                    item.weight = newWeight;
                  }
                "/>

              <button
                class="opacity-0 group-hover:opacity-100 absolute left-0 -translate-x-[10%] transition-all duration-300 flex items-center justify-center w-[30px]"
                @click="
                  () => {
                    weightManagement._updatedData =
                      weightManagement._updatedData.filter(
                        ({ id }) => id !== item.id,
                      );
                  }
                ">
                <PVButton
                  severity="neutral"
                  text
                  :size="'small'"
                  class="!px-1.5 !py-[3px] !m-0.5 !bg-secondary-50 hover:!bg-secondary-100">
                  <template #icon>
                    <Trash2 :size="15" class="text-secondary-900" />
                  </template>
                </PVButton>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div
        class="flex justify-between items-center border-1 rounded-4 py-2.5 px-3 w-[17.5rem]"
        :class="{
          'border-green-500 bg-secondary-50': totalWeightCorrect,
          'border-red-400 bg-red-50': !totalWeightCorrect,
        }">
        <span
          v-if="totalWeightCorrect"
          class="text-sm gap-2 text-green-500 flex items-center"
          style="line-height: normal">
          <CircleCheck :size="12" />
          הגעת למקסימום
        </span>
        <span
          v-else
          class="text-sm gap-2 flex items-center text-red-500"
          style="line-height: normal">
          <TriangleAlert :size="12" />
          יש {{ Math.abs(weightExceededBy) }}%
          {{ weightExceededBy < 0 ? 'מיותרים' : 'חסרים' }}
        </span>

        <span
          class="text-sm flex items-center py-0.5"
          :class="{
            'text-green-500': totalWeightCorrect,
            'text-red-500': !totalWeightCorrect,
          }"
          style="line-height: normal">
          סה"כ {{ totalWeight }}%
        </span>
      </div>
      <div v-if="weightManagement.errorMessage" class="flex items-center">
        <p class="text-red-500 text-sm">{{ weightManagement.errorMessage }}</p>
      </div>
      <div class="flex items-center">
        <PVButton
          severity="neutral"
          text
          label="ביטול"
          class="font-semibold text-secondary-800"
          @click="
            () => {
              weightManagement.isWeightManagementDialogOpen = false;
              emit('onCancel');
            }
          "/>
        <PVButton
          class="!py-1.5 !px-3"
          label="שמירה"
          :loading="weightManagement.isLoading"
          :disabled="!totalWeightCorrect"
          @click="handleSave"/>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import PVInputText from 'primevue/inputtext';
import WeightField from './WeightField.vue';
import { CircleCheck, Plus, Trash2, TriangleAlert } from 'lucide-vue-next';
import { useWeightManagement } from './store/WeightManagement';

const weightManagement = useWeightManagement();

const emit = defineEmits(['onSave', 'onCancel']);

const hoveredIndex = ref<number | null>(null);

const totalWeight = computed(() => {
  return weightManagement._updatedData.reduce(
    (acc, item) => acc + item.weight,
    0,
  );
});

const totalWeightCorrect = computed(() => {
  return totalWeight.value === 100;
});

const weightExceededBy = computed(() => {
  return 100 - totalWeight.value;
});

const hasEmptyTitles = ref(false);

watch(
  () => weightManagement._updatedCriteria.weight,
  newWeight => {
    weightManagement._updatedData = weightManagement._updatedData.map(item => ({
      ...item,
      weight:
        item.id === weightManagement._originalCriteria?.id
          ? newWeight
          : item.weight,
    }));
  },
  { immediate: true },
);

let tempIdCounter = 0;

const addNewCriteria = () => {
  weightManagement._updatedData = [
    ...(weightManagement._updatedData ?? []),
    {
      id: `temp-${tempIdCounter++}`,
      title: '',
      weight: 0,
      type: 'criteria',
    },
  ];
  hoveredIndex.value =
    weightManagement._updatedData.filter(item => item.type === 'criteria')
      .length - 1;
};

const handleSave = async () => {
  if (
    weightManagement._updatedData.some(
      item => item.type === 'criteria' && !item.title.trim(),
    )
  ) {
    hasEmptyTitles.value = true;
    return; // Exit the function if there are validation errors
  }

  weightManagement._updatedData = weightManagement._updatedData.map(item => ({
    ...item,
    id: item.id.startsWith('temp-') ? '' : item.id,
  }));

  await weightManagement.handleSaveChanges({
    updatedData: weightManagement._updatedData,
    onSave: () => {
      emit('onSave');
    },
    weightUpdated: true,
  });
};
</script>

import { ref, computed, watchEffect } from 'vue';
import { useSendbird } from './useSendbird';
import { sendbirdUtils } from './utils';
import { useChannelDemo } from './useChannelDemo';
import { PostData, Post } from '../types/post';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';

const { formatPostMessage, sortedByDate } = sendbirdUtils;
const {
  sendMessageDemo,
  editMessageDemo,
  deleteMessageDemo,
  reactToMessageDemo,
} = useChannelDemo();

export function useChannel(channelId, appId, token, user, readOnly = false) {
  const loading = ref(false);
  const postsStore = ref<Array<Post>>(
    !readOnly ? Array.from(new Array(4)) : [],
  );
  const channel = ref(null);
  const isInitLoaded = ref(false);
  const count = ref(0);
  const channelUrl = ref(channelId);
  const isOperator = ref<boolean>(false);

  const messageHandlers = {
    onMessagesAdded: (context, channel, messages) => {
      if (context.source !== 'EVENT_MESSAGE_RECEIVED') {
        return;
      }
      const message = messages[0];
      const formatted = formatPostMessage(message);
      postsStore.value = sortedByDate([
        ...postsStore.value,
        formatted,
      ]) as Post[];
    },
    onMessagesDeleted: (context, channel, messageIds) => {
      postsStore.value = postsStore.value.filter(post => {
        return !messageIds.includes(post.message_id);
      });
    },
    onMessagesUpdated: async (context, channel, messages) => {
      if (!postsStore.value) {
        postsStore.value = [];
      }

      const index = postsStore.value.findIndex(
        post => post?.message_id && post.message_id === messages[0].messageId,
      );
      if (index !== -1) {
        let threadParams: any = null;
        if (messages[0]?.threadInfo?.replyCount) {
          threadParams = await getParamsForThreading(messages[0], channel);
        }
        const formatted = formatPostMessage(
          messages[0],
          threadParams?.threadedMessages,
        );
        postsStore.value[index] = formatted;
      }
    },
  };

  const onCacheResult = (err, messages) => {};
  const onApiResult = async (err, messages, channel) => {
    isInitLoaded.value = true;
    for (const message of messages) {
      let threadParams: any = null;
      if (message?.threadInfo?.replyCount) {
        threadParams = await getParamsForThreading(message, channel);
      }
      const formatted = formatPostMessage(
        message,
        threadParams?.threadedMessages,
      );
      postsStore.value = sortedByDate([
        ...postsStore.value.filter(post => !!post),
        formatted,
      ]) as Post[];
    }
  };

  const {
    sendMessageToChannel,
    retrieveChannel,
    retrieveMessagesForChannel,
    reactToMessageInGroupChannel,
    deleteMessage,
    editMessage,
    getParamsForThreading,
    getMessage,
    login,
    connected,
  } = useSendbird(appId, token, user, messageHandlers, onApiResult);

  const isTest = ref(false);

  const initChannelAndMessages = async () => {
    loading.value = true;
    console.log('init');
    if (!channel.value) {
      channel.value = await retrieveChannel(channelUrl.value);
      if (!channel.value) return;
      postsStore.value = [];
      isOperator.value = channel.value['myRole'] === 'operator';
      await retrieveMessagesForChannel(
        channel.value,
        messageHandlers,
        onCacheResult,
        (err, messages) => onApiResult(err, messages, channel.value),
      );
    }
    loading.value = false;
  };

  const sendMessage = async (
    messageData: PostData,
    parentId: number | null = null,
  ) => {
    if (!messageData.text && !messageData.media) return;
    loading.value = true;
    let options = { ...messageData };
    if (!isTest.value) {
      await sendMessageToChannel(
        channel.value,
        messageData.text,
        options,
        true,
        postsStore,
        parentId,
      );
    } else sendMessageDemo(messageData, postsStore, parentId);
    loading.value = false;
  };

  const updateMessage = (post: PostData) => {
    if (!isTest.value) {
      const data = {
        backgroundColor: post.backgroundColor ? post.backgroundColor : '',
        media: post.media ? post.media : [],
      };
      const dataStr = JSON.stringify(data);

      editMessage(post, dataStr, channelUrl.value).then(
        (userMessage: UserMessage) => {
          if (userMessage.parentMessageId) {
            const parentMessageIndex = postsStore.value.findIndex(
              post => post.message_id === userMessage.parentMessageId,
            );
            if (parentMessageIndex !== -1) {
              let parentPost = postsStore.value[parentMessageIndex];
              let commentIndex = parentPost.comments.findIndex(
                comment => comment.messageId === userMessage.messageId,
              );
              if (commentIndex !== -1) {
                parentPost.comments[commentIndex].text = userMessage.message;
                parentPost.comments[commentIndex].message = userMessage.message;
                postsStore.value[parentMessageIndex] = parentPost;
              }
            }
          }
        },
        error => {
          console.log('Error updating message:', error);
        },
      );
    } else editMessageDemo(post, postsStore);
  };

  const removeMessage = (post: PostData) => {
    !isTest.value
      ? deleteMessage(post, channelUrl.value)
      : deleteMessageDemo(post, postsStore);
  };

  const reactToMessage = async (
    messageId,
    cancelReaction: boolean = false,
    emojiKey = 'like',
    userId: string = '',
  ) => {
    if (isTest.value) {
      reactToMessageDemo(
        postsStore,
        messageId,
        emojiKey,
        cancelReaction,
        userId,
      );
      return;
    }
    const baseMessage = await getMessage(messageId, channelUrl.value);
    if (!baseMessage) return;
    await reactToMessageInGroupChannel(
      channel.value!,
      baseMessage as BaseMessage,
      emojiKey,
      cancelReaction,
    );
  };

  watchEffect(() => {
    if (
      channelUrl.value &&
      !channel.value &&
      !isInitLoaded.value &&
      count.value < 3
    ) {
      count.value += 1;
      initChannelAndMessages();
    }
  });

  const setChannel = channelId => {
    channelUrl.value = channelId;
  };

  const setIsTest = isTestValue => {
    isTest.value = isTestValue;
    if (isTest.value) {
      postsStore.value = [];
    }
  };

  return {
    loading,
    posts: computed(() => postsStore.value),
    sendMessage,
    reactToMessage,
    removeMessage,
    updateMessage,
    setChannel,
    login,
    setIsTest,
    connected,
    isOperator,
  };
}

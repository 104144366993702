import { reactive } from 'vue';

const editingState = reactive({
  isAnyEditing: false,
});

export function useEditingTracker() {
  return {
    editingState,
    setEditing(isEditing) {
      editingState.isAnyEditing = isEditing;
    },
  };
}

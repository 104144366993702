import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const createEnrollments = ({ group, course }) =>
  useApi(http.post(`/groups/${group}/enrollments`, { course }));

export const cancelDistribution = ({ group, course, permanent }) =>
  useApi(
    http.post(`/groups/${group}/enrollments/cancel`, { course, permanent }),
  );

export const fetchCourseModule = ({ group, course }) =>
  useApi(
    http.get(`/groups/${group}/enrollments`, {
      params: { course, populate: 'summary.lesson' },
    }),
  );

export const setTeams = ({ group, module, teams }) =>
  useApi(http.post(`/groups/${group}/modules/${module}/teams`, { teams }));

export const setEnrollmentGroup = ({ group, course, user }) =>
  useApi(http.post(`/groups/${group}/enrollments/move`, { course, user }));

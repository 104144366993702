<template>
  <PVDialog
    v-model:visible="isOpen"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    class="transition-all duration-500"
    :style="{ width: dialogWidth }"
    :pt="feedbackDialogPT(send)"
    @hide="emit('close')">
    <template #header>
      <p class="text-secondary-800 font-semibold">שליחת משוב / תיקון</p>
    </template>
    <Transition name="slide-fade">
      <div v-if="!send" class="flex flex-col gap-3">
        <button
          v-if="
            exercise.questions &&
            exercise.questions.every(question => question.name === 'free_text')
          "
          class="bg-secondary-100 py-2 px-3 flex items-center gap-2 hover:bg-secondary-250 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed w-fit"
          :disabled="loading || aiFeedbackLoading"
          style="border: 1px solid #e3e9f2"
          @click="getAIFeedback()">
          <Wand2 class="w-[14px] h-[14px]" />
          <span class="text-sm">{{ t('exercises_review.ai_feedback') }}</span>
        </button>

        <div v-if="aiFeedbackLoading" class="flex flex-col gap-2 flex-1">
          <Skeleton width="80%" height="4rem" />
        </div>

        <PVTextArea
          v-if="!aiFeedbackLoading"
          v-model="feedback"
          :rows="isAIFeedback ? 7 : 2"
          placeholder="כתיבת משוב"
          :pt="feedbackFieldPT"
          auto-resize/>

        <p v-if="aiError" class="text-red-500 text-sm">
          אופס יש לנו שגיאה, יש לנסות שוב
        </p>

        <div v-if="isAIFeedback">
          <p>{{ t('exercises_review.ai_grade') }}</p>
          <PVInputNumber
            v-model="score"
            class="mt-1 w-4/5"
            :max="120"
            :min="0"
            input-id="score"
            :disabled="aiFeedbackLoading"
            @blur="!score ? (score = 0) : null"/>
        </div>

        <div class="flex gap-2 align-items-center">
          <PVCheckbox
            v-model="changesRequested"
            input-id="fix"
            name="changesRequested"
            :value="true"
            :disabled="aiFeedbackLoading"/>
          <label for="fix" class="text-secondary-900 text-sm">
            שליחת התרגיל לתיקון
          </label>
        </div>
      </div>

      <div v-else class="flex flex-col items-center gap-3">
        <div class="w-full flex justify-end">
          <X
            :size="16"
            class="stroke-secondary-800 cursor-pointer"
            @click="emit('close')"/>
        </div>
        <img :src="HandFeedback" width="100" height="100" alt="" />
        <span class="font-bold text-secondary-900 w-50 text-center">
          הפידבק שכתבת נשלח בהצלחה לתלמיד
        </span>
      </div>
    </Transition>

    <template #footer>
      <div v-if="!send" class="flex gap-4 justify-end">
        <PVButton
          label=" שליחה"
          :disabled="loading || !feedback?.trim().length || aiFeedbackLoading"
          :loading="loading"
          @click="sendFeedback"/>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { X, Wand2 } from 'lucide-vue-next';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import PVCheckbox from 'primevue/checkbox';
import PVTextArea from 'primevue/textarea';

import { ref, watch } from 'vue';
import { reviewSubmission, reviewFeedbackAI } from '/@/app/services/students';
import { useRoute } from 'vue-router';

import HandFeedback from '/@/assets/hand-feedback.png';
import {
  feedbackDialogPT,
  feedbackFieldPT,
} from '/@/views/components/LessonSubmission/styles';
import { useStudentsStore } from '/@/app/store/students';
import Skeleton from 'primevue/skeleton';
import PVInputNumber from 'primevue/inputnumber';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface AIFeedbackResponse {
  request_id: string;
  score: number;
  feedback: string;
}

const route = useRoute();
const studentsStore = useStudentsStore();

const emit = defineEmits(['close', 'update-score']);
const isOpen = defineModel<boolean>('open');
const dialogWidth = ref('500px');
const changesRequested = ref(false);
const isAIFeedback = ref<boolean>(false);

const send = ref(false);
const loading = ref(false);
const aiFeedbackLoading = ref(false);
const score = ref<number | null>(null);
const props = defineProps({
  exercise: {
    type: Object,
    default: () => ({}),
  },
  student: {
    type: String,
    default: '',
  },
  submission: {
    type: Object,
    default: () => ({}),
  },
  aiAutoFeedback: {
    type: Boolean,
    default: false,
  },
});
const feedback = ref(props.submission?.review_notes);
const aiError = ref(false);

const getAIFeedback = async () => {
  aiFeedbackLoading.value = true;
  aiError.value = false;
  const { onSuccess, onError } = reviewFeedbackAI(props.submission?.id);
  onSuccess(aiFeedback => {
    const feedbackRes: AIFeedbackResponse = aiFeedback.data;
    feedback.value = feedbackRes.feedback;
    score.value = feedbackRes.score;
    isAIFeedback.value = true;
    aiFeedbackLoading.value = false;
  });
  onError(() => {
    aiFeedbackLoading.value = false;
    aiError.value = true;
  });
};

const sendFeedback = () => {
  loading.value = true;
  let data: any = {
    title: 'תגובה חדשה ממורה',
    notes: feedback.value,
    metadata: {
      course: route.params.course,
      lesson: route.params.lesson,
      exercise: props.exercise.id,
      page: props.exercise.questions[0].page,
    },
  };

  if (changesRequested.value) {
    data = {
      ...data,
      changes_requested: true,
      metadata: {
        ...data.metadata,
        changes_requested: true,
      },
    };
  }

  const { onSuccess } = reviewSubmission(props.submission?.id, data);
  onSuccess(async () => {
    if (score.value !== null) {
      emit('update-score', score.value);
    } else {
      studentsStore.fetchSubmissions(route.params.group, route.params.course);
    }
    loading.value = false;
    send.value = true;
    dialogWidth.value = '300px';
  });
};

watch(
  () => props.aiAutoFeedback,
  newValue => {
    if (newValue && isOpen.value) {
      getAIFeedback();
    }
  },
  { immediate: true },
);

watch(
  () => feedback.value,
  () => {
    if (aiError.value) {
      aiError.value = false;
    }
  },
);
</script>

<template>
  <div
    class="relative gap-2 flex flex-col items-center h-full rounded-2xl overflow-hidden">
    <video
      v-if="type === 'custom'"
      ref="videoRef"
      controls
      class="bg-black h-full aspect-video"
      :style="{ width: width }"
      @canplay="onCanPlay"/>
    <div
      v-if="type === 'vimeo'"
      :id="`vimeo-player-${vimeoId}`"
      ref="vimeoRef"
      class="aspect-video w-full max-w-4xl"></div>

    <YoutubePlayer
      v-if="type === 'youtube' && youtubeReady"
      ref="youtubeRef"
      class="aspect-video w-full max-w-4xl"
      :src="src"
      :width="width"
      height="100%"
      @ready="onPlayerReady"
      @state-change="handleYoutubeState"/>
    <div
      v-if="processing && type === 'custom'"
      class="absolute h-full w-full flex items-center justify-center bg-[#F2F4F8E5]">
      <span
        class="font-simplerRegular font-normal w-20/100 text-center text-sm"
        dir="rtl">
        כמה דקות והסרטון יהיה זמין לצפייה, בינתיים ניתן להמשיך לעבוד!
      </span>
    </div>
    <div
      v-if="type === 'custom' || !type"
      class="absolute bottom-[80px] left-4 z-50">
      <BaseTooltip popper-class="z-[9999999]" placement="top">
        <template #trigger>
          <button
            v-if="block.settings.display !== 'hint'"
            class="h-8 w-8 flex items-center justify-center z-50 bg-primary-500 rounded-md p-2"
            @click="openQuestionsModal">
            <PenLine v-if="isRulesBlocks.length" class="h-10 stroke-white" />
            <Plus v-else class="h-8 stroke-white" />
          </button>
        </template>
        <template #content>
          <span v-if="!isRulesBlocks.length">
            {{ t('blocksSettings.custom_video.add_exercise_modal') }}
          </span>
          <span v-else>
            {{ t('blocksSettings.custom_video.edit_exercise_modal') }}
          </span>
        </template>
      </BaseTooltip>
    </div>
    <loading-overlay
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
      :opacity="0.8"
      :z-index="9999999"
      color="#fff"
      background-color="#333"/>
  </div>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, watch, ref, nextTick } from 'vue';
import { useMediaControls, useEventBus } from '@vueuse/core';
import LoadingOverlay from 'vue-loading-overlay';
import YoutubePlayer from 'vue3-youtube';
import { useYoutubeApi } from '@/composables/useYoutubeApi';
import VimeoPlayer, { Player } from '@vimeo/player';
import { nanoid } from 'nanoid';
import { Plus, PenLine } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { BaseTooltip } from '@amit/foundation';

const toast = inject('moshaToast');

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: '100%',
  },
  type: {
    type: String,
    default: 'custom',
  },
  block: {
    type: Object,
    required: true,
  },
  isRulesBlocks: {
    type: Array,
    required: true,
  },
  showAddRule: {
    type: Boolean,
    default: true,
  },
});
const videoRef = ref<HTMLVideoElement | null>(null);
const vimeoRef = ref();
const loading = ref(true);
const youtubeRef = ref();
const processing = ref(false);
const vimeoId = ref(nanoid());
const youtubePlaying = ref(false);
const { t } = useI18n();
const emit = defineEmits(['update-end-time']);
const { youtubeReady } = useYoutubeApi();

const openQuestionsModal = () => {
  const blockSpecificBus = useEventBus(
    `questions-modal-${props.block.page}-${props.block.id}`,
  );
  blockSpecificBus.emit({
    type: 'open-questions-modal',
    pageId: props.block.page,
    blockId: props.block.id,
    block: props.block,
  });
};

const lowQualitySrc = computed(() => {
  const match = '/upload/';
  const quality = 'q_auto:low';
  return props.src.includes(match)
    ? props.src.replace(match, `${match}${quality}/`)
    : props.src;
});

useMediaControls(videoRef, {
  src: lowQualitySrc.value,
});

const handleYoutubeState = state => {
  youtubePlaying.value = state.data === 1;
};

const onCanPlay = () => {
  if (typeof toast === 'function' && processing.value) {
    toast('הסרטון מוכן לצפייה!', {
      position: 'top-center',
      type: 'success',
      hideProgressBar: true,
      transition: 'zoom',
    });
  }
  loading.value = false;
  processing.value = false;
};

const onPlayerReady = () => {
  loading.value = false;
  emit('update-end-time', youtubeRef.value.getDuration());
};

let vimeoPlayer: Player | null = null;

const adaptVimeo = () => {
  let iframe = vimeoRef.value.querySelector('iframe');
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('height', '100%');
};

onMounted(async () => {
  setTimeout(() => {
    if (loading.value) {
      loading.value = false;
      processing.value = true;
    }
  }, 5000);
  const options = {
    url: props.src,
  };
  if (props.type === 'vimeo') {
    await nextTick();

    vimeoPlayer = new VimeoPlayer(vimeoRef.value, options);
    vimeoPlayer.on('loaded', () => {
      adaptVimeo();
    });
  }
});

watch(
  () => props.src,
  newSrc => {
    if (videoRef.value) {
      videoRef.value.src = newSrc;
      videoRef.value.load();
    }
  },
);
</script>

<template>
  <div class="flex flex-col gap-5 pb-5">
    <div class="flex justify-between gap-7 bg-blue-sea-75 px-6 py-7">
      <div class="flex flex-col gap-4 w-full">
        <div class="flex justify-between items-center">
          <span
            class="text-secondary-900 font-simplerRegular font-bold text-lg">
            {{ course?.caption }}
          </span>
          <div class="flex justify-end gap-1 whitespace-nowrap">
            <div v-for="(discipline, index) in disciplinesList" :key="index">
              <span
                class="h-5 rounded-full bg-secondary-75 px-5 py-1.5 text-sm text-secondary-900 border-1 border-secondary-250">
                {{ discipline }}
              </span>
            </div>
            <div>
              <span
                class="h-5 rounded-full bg-secondary-75 px-5 py-1.5 text-sm text-secondary-900 border-1 border-secondary-250">
                {{ course?.outline?.length || 1 }}
                {{ isSurvey ? 'שאלונים' : 'שיעורים' }}
              </span>
            </div>
          </div>
        </div>
        <p
          ref="descriptionEl"
          v-sanitize-html="{ html: course?.description }"
          :dir="course?.locale === 'en' ? 'ltr' : 'rtl'"
          class="course-description tiptap"></p>
        <div class="flex gap-1.5">
          <CourseDistributionSidebar
            v-model="distributionSidebarVisible"
            :is-survey="isSurvey"
            :course="course"/>
          <PVButton
            class="h-[33px]"
            :label="
              $t(
                isSurvey
                  ? 'course_page.distribute_survey'
                  : 'course_page.distribute_course',
              )
            "
            @click="distributionSidebarVisible = true">
            <template #icon><Send :size="12" /></template>
          </PVButton>

          <MentorshipDistributionSidebar
            v-model="mentorshipDistributionSidebarVisible"
            :course="course"
            :groups="courseMentorshipGroups"/>
          <PVButton
            severity="secondary"
            class="h-[33px] border-1 border-primary !text-primary hover:bg-[#F3FBFF]"
            :label="$t('course_page.distribute_mentorship')"
            @click="mentorshipDistributionSidebarVisible = true"></PVButton>
        </div>
      </div>
      <div
        v-if="course?.cover"
        class="relative bg-cover bg-right h-40 w-85 rounded-4"
        :style="{
          backgroundImage: `url(${course?.cover})`,
        }"></div>
    </div>
    <div class="flex flex-col gap-3">
      <div class="flex items-end justify-between px-[1px]">
        <span class="text-secondary-975 font-simplerBold text-lg">
          {{
            isSurvey
              ? $t('course_page.surveys_list')
              : $t('course_page.course_structure')
          }}
        </span>
        <div class="flex gap-3">
          <RouterLink
            v-if="!isSurvey && unitsLessons?.[0]?.lessons?.[0]?.id"
            target="_blank"
            :to="{
              name: 'preview',
              params: {
                course: course?.id,
                lesson: unitsLessons?.[0]?.lessons?.[0]?.id,
              },
            }">
            <PVButton
              class="!rounded-lg !py-2 !px-3"
              :label="$t('course_page.preview')"
              severity="neutral"
              plain>
              <template #icon>
                <span
                  class="flex p-3 justify-center items-center bg-blue-sea-100 rounded-md">
                  <Play :size="12" />
                </span>
              </template>
            </PVButton>
          </RouterLink>

          <CourseFilesLibrarySidebar
            v-model="filesSidebarVisible"
            :links="links"
            :introduction="course?.introduction"/>
          <PVButton
            v-if="!isSurvey"
            class="!rounded-lg !py-2 !px-3"
            :label="$t('course_page.files_library')"
            plain
            severity="neutral"
            @click="filesSidebarVisible = true">
            <template #icon>
              <span
                class="flex p-3 justify-center items-center bg-cyan-gentle-100 rounded-md">
                <CloudDownload :size="12" />
              </span>
            </template>
          </PVButton>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div v-for="unitLesson in unitsLessons" :key="unitLesson.id">
          <div class="flex flex-col gap-2">
            <span class="text-secondary-975 text-base font-simplerRegular">
              {{ unitLesson.unitTitle || t(`level.${unitLesson.level}`) }}
            </span>
            <div class="flex flex-col gap-2">
              <div
                v-for="(
                  { title, id, lessonIndex }, index
                ) in unitLesson.lessons"
                :key="id"
                class="bg-white flex rounded-md px-4 py-3 justify-between items-center shadow">
                <RouterLink
                  class="w-full"
                  target="_blank"
                  :to="
                    isSurvey
                      ? {
                          name: 'survey-preview',
                          params: { survey: course.id, questionnaire: id },
                        }
                      : {
                          name: 'preview',
                          params: { course: course.id, lesson: id },
                        }
                  ">
                  <div class="flex justify-between items-center w-full">
                    <div class="flex gap-2 items-center">
                      <span
                        class="flex justify-center items-center p-3 rounded-full bg-purple-100">
                        <BookOpen :size="13" class="text-secondary-900" />
                      </span>
                      <span class="text-secondary-900 font-simplerBold text-sm">
                        {{ $t(isSurvey ? 'survey' : 'lesson') }}
                        {{ isSurvey ? index + 1 : lessonIndex }}
                      </span>
                      <span class="text-sm">{{ title }}</span>
                    </div>

                    <div
                      class="flex p-1 bg-secondary-200 rounded-full w-5 h-5 items-center justify-cente cursor-pointer">
                      <ArrowLeft :size="16" />
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {
  computed,
  DirectiveArguments,
  onMounted,
  ref,
  withDirectives,
} from 'vue';
import { fetchCourse, listFiles } from '/@/app/services/courses';
import { getSurvey } from '/@/app/services/surveys';
import { useRoute } from 'vue-router';
import { get } from 'lodash';
import {
  Send,
  Play,
  ArrowLeft,
  BookOpen,
  CloudDownload,
} from 'lucide-vue-next';
import { useAccountStore } from '/@/app/store/account';
import { vSanitizeHtml } from '@nanogiants/vue3-sanitize-html';
import CourseFilesLibrarySidebar from '/@/views/playground/components/CourseFilesLibrarySidebar.vue';
import CourseDistributionSidebar from '/@/views/playground/components/CourseDistributionSidebar.vue';
import MentorshipDistributionSidebar from '/@/views/playground/components/MentorshipDistributionSidebar.vue';
import PVButton from 'primevue/button';
import { TEACHER_TRAINING } from '@/utils';
const { t } = useI18n();
const route = useRoute();
const accountStore = useAccountStore();
const course = ref();
const links = ref([]);
const descriptionEl = ref();
const filesSidebarVisible = ref(false);
const distributionSidebarVisible = ref(false);
const mentorshipDistributionSidebarVisible = ref(false);

withDirectives(descriptionEl.value, vSanitizeHtml as DirectiveArguments);
const hasTeacherTraining = computed(() =>
  course.value.disciplines.includes(TEACHER_TRAINING),
);
const isSurvey = computed(() => route.name?.toString() === 'survey-library');
const unitsLessons = computed(() => {
  if (!isSurvey.value) {
    const units = get(course.value, 'units', []);
    const lessons = get(course.value, 'outline', []).map((lesson, index) => ({
      ...lesson,
      lessonIndex: index + 1,
    }));
    return units.map(unit => {
      return {
        unitId: unit.id,
        unitTitle: unit.title,
        level: unit.level,
        lessons: lessons.filter(lesson => lesson.unit === unit.id),
      };
    });
  } else {
    return [
      {
        unitId: 'surveys',
        unitTitle: 'שאלונים',
        lessons: course.value?.outline ?? [],
      },
    ];
  }
});

const disciplinesList = computed(() => {
  return [
    ...(course.value?.disciplines ?? []),
    ...(course.value?.gates && !hasTeacherTraining.value
      ? course.value.gates
      : []),
    ...(course.value?.grades && !hasTeacherTraining.value
      ? course.value.grades
      : []),
  ];
});

const courseMentorshipGroups = computed(() => {
  return accountStore.mentorshipGroups.filter(group =>
    group?.courses?.some(({ id }) => id === course.value?.id),
  );
});

onMounted(() => {
  const { onSuccess } = isSurvey.value
    ? getSurvey(route.params.course)
    : fetchCourse(route.params.course);
  onSuccess(({ data }) => {
    course.value = data;
  });
  const listFilesResp = listFiles(route.params.course as string);
  listFilesResp.onSuccess(({ data }) => {
    links.value = data.sort((a, b) => a.order - b.order);
  });

  accountStore.setMentorshipGroups();
});
</script>
<style>
.course-description {
  p {
    @apply font-simplerRegular text-sm text-secondary-800 !important;
  }
  p a {
    @apply inline;
  }
}
</style>

export interface PostData {
  message_id?: number;
  title?: string;
  text?: string;
  parentId?: number;
  backgroundColor?: string;
  media?: PostMedia[];
}

export interface Post {
  likes: any[]; // Can't be further typed without knowing likes structure (likely array of user IDs or objects)
  group_name: string;
  comments: any[]; // Can't be further typed without knowing comments structure (likely array of comment objects)
  post_id: string;
  message_id: number;
  reactions: any[]; // Can't be further typed without knowing reactions structure (likely array of reaction objects)
  title: string;
  metadata: PostMetadata;
  created_at: string;
  updated_at: string;
  cover_img: null;
  author: Author;
  status: 'ACTIVE';
  comment_status: boolean;
  backgroundColor: string;
  media: PostMedia[];
}

interface PostMetadata {
  message_id: string;
  title: string;
  text: string;
}

interface Author {
  _iid: string;
  userId: string;
  nickname: string;
  plainProfileUrl: string;
  requireAuth: boolean;
  metaData: AuthorMetaData;
  connectionStatus: string;
  isActive: boolean;
  lastSeenAt: null;
  preferredLanguages: string[];
  friendDiscoveryKey: null;
  friendName: null;
  isBlockedByMe: boolean;
  role: string;
}

interface AuthorMetaData {
  grade: string;
  role: string;
  school: string;
  school_id: string;
  username: string;
}

export const postColors = [
  'bg-white',
  'bg-green-spring-100',
  'bg-pink-100',
  'bg-lime-100',
  'bg-orange-mango-100',
  'bg-aqua-marine-100',
  'bg-blue-sea-100',
  'bg-secondary-250',
  'bg-cyan-gentle-100',
];

export interface PostMedia {
  url: string;
  type: string;
}

<template>
  <div class="relative flex gap-5 mt-3">
    <div class="flex-1">
      <PVTabView v-model:active-index="activeType" :pt="coursePanelPT">
        <PVTabPanel v-for="(tab, index) in tabs" :key="index">
          <CardSkeleton v-if="editor.loading" :number-of-cards="5" />
          <div
            v-else-if="tab.content.length"
            class="grid grid-cols-1 xs:grid-cols-2 gap-3">
            <CourseCard
              v-for="course in tab.content"
              :key="course.id"
              type="editor"
              :course="course"
              :loading="editor.loading">
              <div class="actions flex items-center justify-end gap-2">
                <PVButton
                  severity="neutral"
                  class="!m-0 !p-1.5 !rounded-md"
                  :title="t('tooltips.settings')"
                  @click="
                    $router.push({
                      name: 'edit-course-settings',
                      params: { course: course.id },
                    })
                  ">
                  <Settings :size="16" />
                </PVButton>
                <PVButton
                  class="!m-0 !p-1.5 !rounded-4"
                  :title="t('tooltips.toEdit')"
                  @click="editCourse(course.id)">
                  <ArrowUpLeft :size="16" />
                </PVButton>
              </div>
            </CourseCard>
          </div>
          <div
            v-else
            class="flex flex-col gap-2 flex-1 items-center justify-center min-h-80">
            <NoCourses v-if="index == activeType" />
            <p class="text-secondary-800 font-bold">
              {{ t('lobby_room.no_courses_exists') }}
            </p>
          </div>
          <template #header>
            {{ tab.label }}
          </template>
        </PVTabPanel>
      </PVTabView>
    </div>
    <div class="absolute -top-0.5 end-0 flex items-end justify-end">
      <PVButton
        class="!m-0 !py-1.5 !px-2"
        @click="$router.push({ name: 'create-course' })">
        <div class="flex items-center gap-1">
          <Plus :size="16" />
          <span class="text-sm leading-sm">
            {{ t('lobby_room.editor_tab.create_new_content') }}
          </span>
        </div>
      </PVButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue';
import { useEditorStore } from '/@/app/store/editor';
import PVTabView from 'primevue/tabview';
import PVTabPanel from 'primevue/tabpanel';
import PVButton from 'primevue/button';

import { computed, ref, watch } from 'vue';
import { CourseCard } from '@amit/components';
import { Plus, ArrowUpLeft, Settings } from 'lucide-vue-next';
import NoCourses from '/@/assets/no-courses.svg';
import { useI18n } from 'vue-i18n';
import { coursePanelPT } from '/@/views/Lobby/styles';
import { useRouteQuery } from '@vueuse/router';
import { impersonate } from '/@/app/services/accounts';
import { useAccountStore } from '/@/app/store/account';
import CardSkeleton from '/@/views/Lobby/CourseSettings/partials/CardSkeleton.vue';

enum COURSES_TYPES {
  UNPUBLISHED,
  PUBLISHED,
}
const { t } = useI18n();
const editor = useEditorStore();
const accountStore = useAccountStore();

const tabs = computed(() => [
  {
    label: t('lobby_room.editor_tab.unpublished'),
    content: editor.unPublishedCourses,
  },
  {
    label: t('lobby_room.editor_tab.published'),
    content: editor.publishedCourses,
  },
]);

const type = useRouteQuery<string>('type', COURSES_TYPES[0]);
const activeType = ref(COURSES_TYPES[type.value] || 0);
watch(
  () => activeType.value,
  () => (type.value = COURSES_TYPES[activeType.value]),
);

onMounted(() => {
  editor.fetchEditorCourses({ onlyMyCourses: true });
});

const editCourse = courseId => {
  const { onSuccess } = impersonate(accountStore.user.id);
  onSuccess(({ data }) => {
    const { token } = data;
    if (token) {
      window.open(
        `${import.meta.env.VITE_BUILDER_BASE}/c/${courseId}?token=${token}`,
        '_blank',
      );
    }
  });
};
</script>

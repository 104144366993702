<template>
  <div
    class="flex items-center justify-between gap-10 border border-secondary-250 bg-blue-sea-75 rounded-md px-6 py-7 h-52">
    <div
      v-if="canEdit"
      class="flex-1 h-full flex flex-col items-start justify-between w-[70%]">
      <div class="overflow-x-auto flex justify-between gap-1 w-full">
        <div class="flex gap-1.5 w-1/2">
          <PVSkeleton
            v-if="loading"
            width="13rem"
            height="2rem"
            class="mb-2"></PVSkeleton>
          <PVInputText
            v-else
            v-model="model.caption"
            type="text"
            :invalid="showErrors && errors.caption"
            :placeholder="t('course_settings.course_name_placeholder')"
            class="grow text-secondary-900 !py-2.5"/>
          <PVSkeleton
            v-if="loading"
            width="10rem"
            height="2rem"
            class="mb-2"></PVSkeleton>
          <PVDropdown
            v-else
            v-model="selectedDirection"
            :options="courseDirections"
            option-label="title"
            :pt="directionDropdownPT">
            <template #dropdownicon>
              <ChevronDown
                class="stroke-secondary-900"
                :size="16"
                :stroke="1.6"/>
            </template>
          </PVDropdown>
        </div>
        <div class="grid grid-cols-3 gap-1.5 items-start justify-between w-1/2">
          <PVSkeleton
            v-if="loading"
            width="6rem"
            height="1.8rem"
            border-radius="99px"
            class="mb-2"></PVSkeleton>
          <PVDropdown
            v-else
            v-model="selectedDiscipline"
            :options="courseDisciplines"
            :invalid="showErrors && errors.disciplines"
            option-label="title"
            :pt="roundedDropdownPT">
            <template #value="{ value }">
              <span v-if="value">{{ value.title }}</span>
              <p
                v-else
                class="flex items-center justify-center gap-1 leading-none">
                <Plus :size="14" />
                {{ $t('course_settings.discipline') }}
              </p>
            </template>
          </PVDropdown>
          <PVSkeleton
            v-if="loading"
            width="6rem"
            height="1.8rem"
            border-radius="99px"
            class="mb-2"></PVSkeleton>

          <PVDropdown
            v-else-if="!isTeacherTraining"
            v-model="selectedGrades"
            :options="courseGrades"
            :invalid="showErrors && errors.grades"
            option-label="title"
            :pt="roundedDropdownPT">
            <template #value="{ value }">
              <span v-if="value">{{ value.title }}</span>
              <p
                v-else
                class="flex items-center justify-center gap-1 leading-none">
                <Plus :size="14" />
                {{ $t('course_settings.grades') }}
              </p>
            </template>
          </PVDropdown>
          <PVSkeleton
            v-if="loading"
            width="6rem"
            height="1.8rem"
            border-radius="99px"
            class="mb-2"></PVSkeleton>
          <PVDropdown
            v-else-if="!isTeacherTraining"
            v-model="selectedGate"
            :options="courseGates"
            :invalid="showErrors && errors['settings.assessmentProfile']"
            option-label="title"
            :pt="roundedDropdownPT">
            <template #value="{ value }">
              <span v-if="value">{{ value.title }}</span>
              <p
                v-else
                class="flex items-center justify-center gap-1 leading-none">
                <Plus :size="14" />
                {{ $t('course_settings.gates') }}
              </p>
            </template>
          </PVDropdown>
        </div>
      </div>
      <PVSkeleton v-if="loading" height="7rem"></PVSkeleton>
      <TiptapText
        v-else
        :id="`preview-${model.id}`"
        v-model="model.description"
        class="flex justify-end !h-26 grow-0"
        :class="{ error: showErrors && errors.description }"
        :editable="true"
        :preview="false"
        :placeholder="t('course_settings.write_here')"
        @update:model-value="updateDescription"/>
    </div>
    <div v-else class="flex-1 h-full flex flex-col gap-2 w-[70%]">
      <div class="flex items-end justify-end gap-1 whitespace-nowrap">
        <div v-for="(discipline, index) in combinedCourseData" :key="index">
          <span
            class="h-5 rounded-full bg-secondary-75 px-5 py-1.5 text-sm text-secondary-900 border-1 border-secondary-250">
            {{ discipline }}
          </span>
        </div>
      </div>
      <div class="flex items-start flex-col gap-3">
        <h1>{{ course.caption }}</h1>
        <p
          ref="descriptionEl"
          v-sanitize-html="{ html: course?.description }"
          class="tiptap h-20 overflow-auto"></p>
      </div>
    </div>
    <PVSkeleton
      v-if="loading"
      width="15rem"
      height="9rem"
      class="mb-2"></PVSkeleton>
    <div
      v-else
      class="flex items-center justify-center bg-secondary-75 h-full w-60 border-secondary-250 rounded-md border overflow-hidden"
      :class="{
        'cursor-pointer bg-white': canEdit,
        '!border-red-500': showErrors && errors.cover,
      }"
      @click="canEdit && openWidget()">
      <img
        v-if="model.cover"
        class="min-h-full min-w-full contain hover:scale-[1.05] transition duration-200 rounded-md"
        :src="model.cover"
        alt="cover img"/>
      <div
        v-else
        class="rounded-md bg-green-100 w-10 h-10 flex items-center justify-center">
        <ImagePlus class="stroke-secondary-900" :size="16" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PVInputText from 'primevue/inputtext';
import PVDropdown from 'primevue/dropdown';
import PVSkeleton from 'primevue/skeleton';
import {
  PropType,
  ref,
  watch,
  computed,
  withDirectives,
  DirectiveArguments,
  inject,
} from 'vue';
import { ICourse } from '/@/app/types/interfaces';
import { reactiveUploadResult, updateAndOpenInstance } from '@amit/composables';
import { useVModel } from '@vueuse/core';
import {
  courseDirections,
  courseDisciplines,
  courseGates,
  courseGrades,
} from '/@/views/Lobby/utils';
import { useI18n } from 'vue-i18n';
import { ChevronDown, ImagePlus, Plus } from 'lucide-vue-next';
import { directionDropdownPT, roundedDropdownPT } from '/@/views/Lobby/styles';
import { TiptapText } from '@amit/tiptap';
import { vSanitizeHtml } from '@nanogiants/vue3-sanitize-html';
import { TEACHER_TRAINING } from '@/utils';
const { t } = useI18n();
const descriptionEl = ref();
withDirectives(descriptionEl.value, vSanitizeHtml as DirectiveArguments);
const props = defineProps({
  course: {
    type: Object as PropType<ICourse>,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const model = useVModel(props, 'course');
const isTeacherTraining = computed(() =>
  model.value.disciplines?.includes(TEACHER_TRAINING),
);
const combinedCourseData = computed(() => {
  const disciplines = props.course?.disciplines ?? [];
  const gates = props.course?.gates ?? [];
  const grades = props.course?.grades ?? [];
  const lessons = `${props.course?.outline?.length || 1} שיעורים`;

  return [...disciplines, ...gates, ...grades, lessons];
});

const selectedDirection = computed({
  get: () =>
    courseDirections.find(d => d.locale === model.value.locale) ||
    courseDirections[0],
  set: value => {
    model.value.locale = value.locale;
  },
});

const selectedDiscipline = computed({
  get: () =>
    courseDisciplines.find(d => d.title === model.value.disciplines?.[0]),
  set: value => {
    model.value.locale = value?.locale ?? model.value.locale;
    model.value.disciplines = [value.title];
    if (value.title == TEACHER_TRAINING && !selectedGate.value) {
      setGate(courseGates[1]);
      model.value.grades = [];
    }
  },
});

const setGate = value => {
  model.value.gates = [value.title];
  model.value.settings = model.value.settings || {};
  model.value.settings.assessmentProfile = value.id;
};
const selectedGrades = computed({
  get: () => courseGrades.find(d => d.title === model.value.grades?.[0]),
  set: value => {
    model.value.grades = [value?.title];
  },
});

const selectedGate = computed({
  get() {
    const assessmentProfile = model.value?.settings?.assessmentProfile;
    return (
      courseGates.find(
        g => g.id === assessmentProfile || g.id === assessmentProfile?.id,
      ) || null
    );
  },
  set(value) {
    setGate(value);
  },
});

const updateDescription = html => {
  model.value.description = html;
};

const openWidget = () => {
  updateAndOpenInstance({ clientAllowedFormats: ['image'] }, 'settings');
};

watch(
  () => reactiveUploadResult.value,
  () => {
    if (
      reactiveUploadResult.value.block === 'settings' &&
      reactiveUploadResult.value.type === 'success'
    ) {
      return (model.value.cover = reactiveUploadResult.value.result);
    }
  },
  { deep: true },
);

const errors = inject('errors');
const showErrors = inject('showErrors');
</script>

<style>
.editor-content {
  @apply min-h-26 h-26 bg-white border border-secondary-300 rounded-md w-full overflow-auto;
}

.error .editor-content {
  @apply !border-red-500;
}
.editor-content p {
  @apply !body;
}
.form-item {
  @apply h-auto;
}
.preview-wrapper {
  @apply p-0 border-0;
}

.preview-wrapper > div:first-child {
  @apply hidden;
}
</style>

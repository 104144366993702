<template>
  <BaseModal size="5xl" @close="closeModal">
    <template #header>
      <div class="font-simplerBold text-lg">הפצת התירגול לקבוצה</div>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="font-simplerBold">
          <div>במסך הפתיחה של הפרויקט תופיע לתלמידים הנחיה.</div>
          <div>באפשרותכם לבחור אם להפיץ את הטקסט הכתוב מטה או לערוך אותו:</div>
        </div>
        <div :dir="locale === 'en' ? 'ltr' : 'rtl'">
          <BaseTextArea v-model="introModel" :rows="15" :max-length="500" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <BaseButton color="none" @click="closeModal">ביטול</BaseButton>
        <BaseButton
          color="blue"
          :loading="loading"
          :disabled="loading"
          @click="distribute">
          <span class="text-white">הפצה</span>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { BaseModal, BaseButton, BaseTextArea } from '@nwire/amit-design-system';
import { ref, watch } from 'vue';

const props = defineProps({
  locale: {
    type: String,
    default: 'he',
  },
  introduction: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'distribute']);
const introModel = ref('');
const loading = ref(false);

watch(
  () => props.introduction,
  introduction => {
    introModel.value = introduction.replace(/\\n/g, '\n');
  },
);

const distribute = () => {
  loading.value = true;
  emit('distribute', introModel.value);
};

const closeModal = () => {
  emit('close');
};
</script>

export default {
  root: ({ props, state, parent }) => ({
    class: [
      // Display and Position
      'inline-flex',
      'relative',
      // Shape
      { 'rounded-4': parent.instance.$name !== 'InputGroup' },
      {
        'first:rounded-s-md rounded-none last:rounded-e-md':
          parent.instance.$name == 'InputGroup',
      },
      {
        'border-0 border-y border-s last:border-e':
          parent.instance.$name == 'InputGroup',
      },
      {
        'first:ms-0 ms-[-1px]':
          parent.instance.$name == 'InputGroup' && !props.showButtons,
      },

      // Color and Background
      { 'bg-white dark:bg-surface-950': !props.disabled },

      'border',
      { 'border-secondary-250': !props.invalid },

      // Invalid State
      'invalid:focus:ring-red-200',
      'invalid:hover:border-red-500',
      { 'border-red-500 dark:border-red-400': props.invalid },

      // Transitions
      'transition-all',
      'duration-200',

      // States
      {
        'hover:border-secondary-400': !props.invalid,
      },
      {
        'outline-none outline-offset-0 ring-1 ring-primary-500 dark:ring-primary-400 z-10':
          state.focused,
      },

      // Misc
      'cursor-pointer',
      'select-none',
      {
        'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-not-allowed':
          props.disabled,
      },

      'text-sm',
    ],
  }),
  input: ({ props, parent }) => ({
    class: [
      //Font
      'leading-[normal]',

      // Display
      'block',
      'flex-auto content-center',

      // Color and Background
      // 'bg-white',
      'border-0',
      {
        'text-surface-800 dark:text-white/80': props.modelValue != undefined,
        'text-surface-400 dark:text-surface-500': props.modelValue == undefined,
      },
      'placeholder:text-surface-400 dark:placeholder:text-surface-500',

      // Sizing and Spacing
      'w-[1%]',
      'py-2 pr-2',
      { 'pr-7': props.showClear },

      //Shape
      'rounded-none',

      // Transitions
      'transition',
      'duration-200',

      // States
      'focus:outline-none focus:shadow-none',

      // Filled State *for FloatLabel
      {
        filled:
          parent.instance?.$name == 'FloatLabel' && props.modelValue !== null,
      },

      // Misc
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'appearance-none',
    ],
  }),
  trigger: {
    class: [
      // Flexbox
      'flex items-center justify-center',
      'shrink-0',

      // Color and Background
      // 'bg-white',
      'text-secondary-900',

      // Size
      'w-10',

      // Shape
      'rounded-r-md',
    ],
  },
  panel: {
    class: [
      // Colors
      'bg-white dark:bg-surface-900',
      'text-surface-700 dark:text-white/80',

      // Shape
      'border border-secondary-300',
      'rounded-md',
      'shadow-md',
    ],
  },
  wrapper: {
    class: [
      // Sizing
      'max-h-[200px]',

      // Misc
      'overflow-auto',
    ],
  },
  list: {
    class: 'p-1 list-none m-0',
  },
  item: ({ context }) => ({
    class: [
      'relative',

      // Font
      'leading-none',
      'text-start',
      // Spacing
      'm-0 px-3 py-2',
      'first:mt-0 mt-[2px]',

      // Shape
      'border-0 rounded',

      // Colors
      {
        'text-surface-700 dark:text-white/80':
          !context.focused && !context.selected,
        'bg-white': context.focused && !context.selected,
        // 'text-surface-700 dark:text-white/80':
        //   context.focused && !context.selected,

        'text-primary-highlight-inverse': context.selected,
        'bg-primary-100': context.selected,
      },

      //States
      {
        'hover:bg-primary-100': !context.focused && !context.selected,
      },
      { 'hover:bg-primary-100': context.selected },
      {
        'hover:text-surface-700 hover:bg-primary-100':
          context.focused && !context.selected,
      },

      // Transition
      'transition-shadow duration-200',

      // Misc
      'cursor-pointer overflow-hidden whitespace-nowrap',

      'text-sm',
    ],
  }),
  itemgroup: {
    class: [
      'font-semibold',

      // Spacing
      'm-0 py-2 px-3',

      // Colors
      'text-secondary-900',

      'text-sm',

      // Misc
      'cursor-auto',
    ],
  },
  emptymessage: {
    class: [
      // Font
      'leading-none',

      // Spacing
      'py-2 px-3',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-transparent',
    ],
  },
  header: {
    class: [
      // Spacing
      'pt-2 px-2 pb-0',
      'm-0',

      //Shape
      'border-b-0',
      'rounded-t-md',

      // Color
      'text-surface-700 dark:text-white/80',
      'border-secondary-300',
    ],
  },
  filtercontainer: {
    class: 'relative',
  },
  filterinput: {
    class: [
      // Font
      'leading-[normal]',

      // Sizing
      'py-2 ps-3 pe-7',
      '-me-7',
      'w-full',

      //Color
      'text-surface-700 dark:text-white/80',
      'border-secondary-200',

      // Shape
      'border',
      'rounded-md',
      'appearance-none',

      // Transitions
      'transition',
      'duration-200',

      // States
      'hover:border-secondary-400',
      'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 focus:z-10',

      // Misc
      'appearance-none',
    ],
  },
  filtericon: {
    class: ['absolute', 'top-1/2 end-3 stroke-secondary-450', '-mt-2'],
  },
  clearicon: {
    class: [
      // Color
      'text-surface-400 dark:text-surface-500',

      // Position
      'absolute',
      'top-1/2',
      'end-12',

      // Spacing
      '-mt-2',
    ],
  },
  loadingicon: {
    class: 'text-surface-400 dark:text-surface-500 animate-spin',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};

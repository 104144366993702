import * as Sentry from '@sentry/vue';

import type { Router } from 'vue-router';
import type { App, Plugin } from 'vue';
import type { Pinia } from 'pinia';

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.MODE;

/**
 * Vue plugin for integrating Sentry error monitoring and performance tracing.
 */
export const SentryPlugin: Plugin = {
  /**
   * Install the Sentry plugin.
   * @param app - The Vue application instance.
   * @param options - Options for the Sentry plugin.
   * @param options.router - The Vue Router instance.
   */
  install(app: App, options: { router: Router; store?: Pinia }): void {
    const { router, store } = options;

    if (!sentryDsn) {
      console.warn(
        '[SentryPlugin] Sentry DSN is not configured. Sentry is disabled.',
      );
      return;
    }

    if (store) {
      store.use(Sentry.createSentryPiniaPlugin());
    }

    Sentry.init({
      app,
      dsn: sentryDsn,
      environment,
      release: app.config.globalProperties.$version,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.vueIntegration({
          tracingOptions: {
            trackComponents: true,
          },
        }),
        Sentry.captureConsoleIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.5,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  },
};

export default SentryPlugin;

<template>
  <nav
    class="bg-white border-b border-secondary-250 px-4 py-2.5 h-10 flex justify-between items-center">
    <div class="flex justify-start items-center gap-2 md:mr-2.5">
      <div class="md:hidden flex justify-start items-center gap-2">
        <button
          class="text-gray-600 rounded-lg cursor-pointer hover:text-primary-600"
          @click="$emit('toggleSidebar')">
          <svg
            v-if="!sidebarVisible"
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          </svg>
          <svg
            v-else
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
      <BaseDropdown v-if="user" placement="bottom" align="left">
        <template #trigger>
          <img
            v-if="user.avatar"
            class="h-7 w-7 cursor-pointer"
            :src="user.avatar"
            alt="avatar"/>
          <LetterAvatar v-else :user-name="user.fullName || ''" />
        </template>
        <ListGroup>
          <ListGroupItem
            v-for="(item, index) in menuItems.filter(
              item => item.enabled !== false,
            )"
            :key="index"
            :tabindex="index"
            role="button"
            @keyup.enter="handleAction(item)"
            @click="handleAction(item)">
            <div class="text-center">{{ item.text }}</div>
          </ListGroupItem>
          <div
            v-if="localStateFeaturesConfig"
            class="w-full flex flex-col gap-2 py-3 px-4"
            @click="e => e.stopPropagation()">
            <span class="font-simplerBold">
              {{ t('features.title') }}
            </span>
            <div class="flex justify-between items-center">
              <label>{{ t('features.move_enrollment') }}</label>
              <PVInputSwitch v-model="localStatePermissions.move_enrollment" />
            </div>
            <div class="flex justify-between items-center">
              <label>{{ t('features.cancel_distribution') }}</label>
              <PVInputSwitch
                v-model="localStatePermissions.cancel_distribution"/>
            </div>
          </div>
        </ListGroup>
      </BaseDropdown>
      <div class="text-sm flex items-center gap-3 text-secondary-900">
        <span>{{ user.fullName }}</span>
        <span class="hidden">{{ breakpoints.active() }}</span>
      </div>
    </div>
    <div class="flex lg:hidden items-center gap-2 lg:order-2">
      <button
        class="p-2 rounded-lg cursor-pointer hover:text-primary-600"
        @click="toggleWidgets">
        <LucideIcon :name="'Layout'" :size="22" :stroke-width="2" />
      </button>
    </div>
  </nav>
</template>

<script setup lang="ts">
import LetterAvatar from './LetterAvatar.vue';
import { LucideIcon, BaseDropdown, ListGroup, ListGroupItem } from '@/';
import { useBreakpoints, useWidgets, useUserLinks } from '../useLayout';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
import { useStorage } from '@vueuse/core';
import PVInputSwitch from 'primevue/inputswitch';
import { useI18n } from 'vue-i18n';

const { toggleWidgets } = useWidgets();
const { breakpoints } = useBreakpoints();
const router = useRouter();
const menuItems = useUserLinks();
const { t } = useI18n();
const localStateFeaturesConfig = useStorage('features_config', false);
const localStatePermissions = useStorage('permissions', {});

const emit = defineEmits(['toggleSidebar', 'logout']);
const emitter = inject('events');
defineProps({
  sidebarVisible: { type: Boolean },
  user: {
    type: Object,

    default: () => {},
  },
});

const handleAction = item => {
  if (item.event) {
    if (emitter) {
      emitter.emit(item.event);
    }
    emit(item.event);
  } else if (item.to) {
    router.push(item.to);
  } else {
    window.location.href = item.link;
  }
};
</script>

<style scoped></style>

import { useScriptTag } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
export interface UploadWidgetOptions {
  cloudName: string;
  uploadPreset: string;
  [key: string]: any;
}
export interface UploadWidgetInstance {
  open(): void;
  close(): void;
}
interface Cloudinary {
  createUploadWidget(
    options: UploadWidgetOptions,
    callback: (error: any, result: any) => void,
  ): UploadWidgetInstance;
}
declare const cloudinary: Cloudinary;

export let cloudinaryInstance: any = {};
const currentUploadResult = ref();
const resultype = ref('');
export const cloudinariyUploadError = ref(false);
const currentBlockId = ref('');
const resourceType = ref('');

export const reactiveUploadResult = computed(() => {
  return {
    result: currentUploadResult.value,
    type: resultype.value,
    block: currentBlockId.value,
    resourceType: resourceType.value,
  };
});

const widjetDestroyed = ref(false);

const cloudName = 'dcodkxpej';
const uploadPreset = 'r6mwkrzq';

const defaultOptions = {
  cloudName: cloudName,
  uploadPreset: uploadPreset,
  cropping: true, //add a cropping step
  folder: 'user_images', //upload files to the specified folder
  tags: ['users', 'profile'], //add the given tags to the uploaded files
  context: { alt: 'user_uploaded' }, //add the given context data to the uploaded files
  maxImageFileSize: 20000000, //restrict file size to less than 2MB
  maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
  theme: 'blue', //change to a purple theme
  // maxFileSize: 20 * 1024 * 1024, // 20 MB
};

const createWidget = options => {
  cloudinaryInstance = cloudinary.createUploadWidget(
    {
      ...defaultOptions,
      ...options,
    },
    (error, result) => {
      console.log('error', 'result', error, result);
      if (!error && result) {
        switch (result.event) {
          case 'success':
            if (result?.info?.secure_url) {
              currentUploadResult.value = result?.info?.secure_url;
              resourceType.value = result?.info?.resource_type;
            }
            destroy(result.event);
            break;
          case 'abort':
            destroy(result.event);
            break;
        }
      } else if (error) {
        cloudinariyUploadError.value = true;
      }
    },
  );
};

export const defaultSources = [
  'local',
  'url',
  'camera',
  'dropbox',
  'image_search',
  'shutterstock',
  'gettyimages',
  'istock',
  'google_drive',
  'unsplash',
];

useScriptTag(
  'https://upload-widget.cloudinary.com/global/all.js',
  // on script tag loaded.
  () =>
    createWidget({
      clientAllowedFormats: null,
      resourceType: 'auto',
    }),
);

watch(
  () => widjetDestroyed.value,
  () => {
    if (widjetDestroyed.value) {
      createWidget({
        clientAllowedFormats: null,
        resourceType: 'auto',
      });
      widjetDestroyed.value = false;
    }
  },
);

const destroy = event => {
  resultype.value = event;
  cloudinaryInstance.destroy();
  widjetDestroyed.value = true;

  setTimeout(() => {
    currentUploadResult.value = '';
    resultype.value = '';
  }, 100);
};

export const updateAndOpenInstance = (options, id, files = []) => {
  currentBlockId.value = id;
  cloudinaryInstance.update({
    ...defaultOptions,
    ...options,
  });
  cloudinaryInstance.open(null, { files });
};

export async function uploadImageToCloudinary(base64Image) {
  const response = await fetch(base64Image);
  const blob = await response.blob();

  const formData = new FormData();
  formData.append('file', blob);
  formData.append('upload_preset', uploadPreset);

  const uploadResponse = await fetch(
    `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    {
      method: 'POST',
      body: formData,
    },
  );

  const data = await uploadResponse.json();
  return data.secure_url;
}

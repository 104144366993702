export const selectButtonPT = {
  button: ({ context }) => ({
    class: [
      'relative',
      'leading-none',
      'inline-flex items-center align-bottom text-center',

      'px-4 py-2 border border-secondary-250 first:rounded-s-md last:rounded-e-md',

      {
        'bg-transparent': !context.active,
        'bg-white': context.active,
      },

      {
        'opacity-60 select-none pointer-events-none cursor-default':
          context.disabled,
      },

      // Transition
      'transition duration-200',

      // Misc
      'cursor-pointer select-none overflow-hidden',
    ],
  }),
};

export const coursePanelPT = {
  navContainer: 'w-[80%]',
  tabpanel: {
    // header: 'bg-green-500',
    headerAction: ({ parent, context }) => ({
      class: [
        'font-simplerRegular relative flex items-center py-1.5 px-4',
        '-mb-px',
        {
          'text-secondary-450 hover:text-secondary-500':
            parent.state.d_activeIndex !== context.index,

          'border-b border-secondary-900 text-secondary-900':
            parent.state.d_activeIndex === context.index,
        },
        'focus:outline-none focus:outline-offset-0',
        'transition-all duration-200',
        'cursor-pointer select-none text-decoration-none',
        'overflow-hidden',
        'user-select-none',
      ],
    }),
  },
  panelcontainer: {
    class: 'py-5',
  },
};

export const directionDropdownPT = {
  root: { style: { 'min-width': '128px' } },
  itemgroup: {
    class: [
      'font-bold m-0 py-2 px-2.5 text-secondary-900 text-sm cursor-auto border-b',
    ],
  },
  trigger: {
    class: ['w-6 flex justify-center items-center'],
  },
  panel: {
    class: [
      'rounded-md relative bg-white',
      '!mt-1 border border-secondary-250',
    ],
  },
  list: {
    class: 'p-2 list-none m-0',
  },
  item: {
    class: [
      'relative cursor-pointer',
      'leading-none text-secondary-900 text-sm',
      'm-0 px-3 py-2',
      'first:mt-0 my-1',
      'hover:bg-primary-100',
    ],
  },
};

export const roundedDropdownPT = {
  root: ({ props }) => ({
    class: [
      'rounded-full border bg-white w-full p-2 flex cursor-pointer',
      {
        'border-red-500': props.invalid,
        'border-secondary-250': !props.invalid,
      },
    ],
  }),
  itemgroup: {
    class: [
      'font-bold m-0 py-1 px-2.5 text-secondary-900 text-sm cursor-auto border-b',
    ],
  },
  trigger: {
    class: ['hidden w-6 flex justify-center items-center'],
  },
  input: {
    class: [
      'px-1.5 text-sm leading-none',
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'transition duration-200',
      'w-full',
    ],
  },
  label: {
    class: [
      'px-2 text-sm',
      'leading-none',
      'w-full',
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'transition duration-200',
    ],
  },
  panel: {
    class: [
      'rounded-md relative bg-white',
      '!mt-1 border border-secondary-250',
    ],
  },
  list: {
    class: 'p-2 list-none m-0',
  },
  item: ({ context }) => ({
    class: [
      'relative flex items-center rounded-md cursor-pointer',
      'leading-none text-secondary-900 text-sm',
      'm-0 px-2 py-2',
      'first:mt-0 my-1',
      'hover:bg-primary-100',
      { 'bg-primary-100': context.selected },
    ],
  }),
  closeButton: {
    class: ['hidden'],
  },
};

// export const iconDropdownPT = {
//   root: { class: ['rounded-full border border-secondary-250'] },
//   itemgroup: {
//     class: [
//       'font-bold m-0 py-1 px-2.5 text-secondary-900 text-sm cursor-auto border-b',
//     ],
//   },
//   trigger: {
//     class: ['hidden w-6 flex justify-center items-center'],
//   },
//   input: {
//     class: ['px-1.5 text-sm leading-none',
//       'relative',
//       'cursor-pointer',
//       'overflow-hidden overflow-ellipsis',
//       'whitespace-nowrap',
//       'transition duration-200',
//       'w-full'
//     ]
//   },
//   label: {
//     class: ['hidden']
//   },
//   panel: {
//     class: [
//       'rounded-md relative bg-white',
//       '!mt-1 border border-secondary-250',
//
//     ],
//   },
//   list: {
//     class: 'p-2 list-none m-0',
//   },
//   item: {
//     class: [
//       'relative',
//       'leading-none text-secondary-900 text-sm',
//       'm-0 px-3 py-2',
//       'first:mt-0 my-1',
//       'hover:bg-primary-100'
//     ],
//   },
// };

export const iconDropdownPT = {
  button: {
    root: { class: ['hidden'] },
  },
  menubutton: {
    root: {
      class: [
        'relative w-5 h-5 bg-transparent hover:bg-secondary-300',

        'items-center inline-flex text-center align-bottom justify-center',
        'rounded-full',
        'transition duration-200 ease-in-out',

        'cursor-pointer overflow-hidden select-none',
      ],
    },
    label: {
      class: ['hidden'],
    },
  },
  menu: {
    root: {
      class: [
        'menu-button bg-white p-1 rounded-md border border-secondary-250',
      ],
    },
    content: { class: ['hover:bg-red-50 text-red-600'] },
  },
};

import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

interface IModuleData {
  summary?: Array<{
    lesson: string;
    weight: number;
  }>;
  criteria?: Array<{
    id?: string;
    name: string;
    weight: number;
  }>;
  assessments?: Array<{
    id: string;
    weight: number;
  }>;
}

interface IPublishData {
  course: Boolean;
  lessons: string[];
}

export const setModule = (moduleId: string, data: IModuleData) => {
  return useApi(http.put(`/modules/${moduleId}`, data));
};

export const publishScores = (moduleId: string, data: IPublishData) => {
  return useApi(http.post(`/modules/${moduleId}/scores`, data));
};

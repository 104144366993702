<template>
  <div class="flex items-center gap-3 p-3 rounded-md bg-white cursor-pointer">
    <div class="flex w-40 sm:w-80 md:w-40 xl:w-80">
      <p
        v-tooltip.top="lesson.title"
        class="font-simplerRegular text-secondary-900 text-sm truncate">
        {{ index ?? lesson.lesson }}. {{ lesson.title }}
      </p>
    </div>
    <div class="flex flex-1 justify-center">
      <div v-if="!locked" class="flex gap-1 items-center">
        <UnlockIcon class="stroke-secondary-800" size="14" />
        <span class="font-simplerRegular text-secondary-800 text-sm h-4">
          פתוח ללמידה
        </span>
      </div>
      <div
        v-else-if="futureUnlock"
        class="flex gap-1 items-center bg-orange-mango-100 hover:bg-orange-mango-200 rounded-full px-2 py-1.5"
        @click.stop="showModal">
        <ClockIcon class="stroke-secondary-800" size="14" />
        <span class="font-simplerRegular text-secondary-800 text-sm h-4">
          ייפתח ב {{ formattedDate }}
        </span>
      </div>
      <div
        v-else
        class="flex gap-1 items-center bg-secondary-100 rounded-full px-2 py-1.5 hover:bg-secondary-200"
        @click.stop="showModal">
        <LockIcon class="stroke-secondary-800" size="14" />
        <span class="font-simplerRegular text-secondary-800 text-sm h-4">
          פתיחה ללמידה
        </span>
      </div>
    </div>
    <div class="flex flex-1 justify-center">
      <div v-if="teamsEnabled" class="flex gap-1 items-center">
        <UsersIcon class="stroke-secondary-800" size="14" />
        <span class="text-secondary-800 text-xs">למידה בקבוצות</span>
      </div>
    </div>
    <div class="flex flex-[0.5] justify-end">
      <div
        class="flex items-center justify-center p-1 bg-secondary-100 rounded-full w-5 h-5">
        <ArrowIcon class="stroke-secondary-900" :size="12" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  ArrowLeft as ArrowIcon,
  Users as UsersIcon,
  Unlock as UnlockIcon,
  Clock as ClockIcon,
  Lock as LockIcon,
} from 'lucide-vue-next';
import { isToday } from 'date-fns';

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  lesson: {
    type: Object,
    required: true,
  },
  teamsEnabled: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: true,
  },
  unlockDate: {
    type: Date,
    default: null,
  },
});

const emit = defineEmits(['show-modal']);
const formattedDate = computed(() => {
  if (!futureUnlock.value) return '';
  const date = new Date(props.unlockDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day}.${month}`;
});
const futureUnlock = computed(() => {
  const date = new Date(props.unlockDate);
  if (!props.unlockDate || isToday(date)) return false;
  return true;
});
const showModal = () => emit('show-modal', props.lesson);
</script>

<template>
  <div
    :class="[
      'pt-3 pb-4 px-4 z-[999999] w-80 rounded-lg bg-white image-replacer-shadow',
      {
        fixed: isModal,
        absolute: !isModal,
        'top-[-220%]': !isModal && isLast,
        'top-[-90%]': !isModal && !isLast && !isFirst,
        'top-[-20%]': !isModal && isFirst && !isLast,
        'left-[120%]': !isModal && false,
        'right-[120%]': !isModal && true,
      },
    ]">
    <div class="font-bold flex justify-start text-sm leading-5">
      {{ t('blocksSettings.pairing.replace_image') }}
    </div>
    <UPLASHImageSearch @update-photos="setPhotos" />
    <div class="columns-3 gap-4 space-y-4 p-4">
      <div
        v-for="photo in photos"
        :key="photo.id"
        class="cursor-pointer"
        @click="setImage(photo)">
        <img class="rounded" :src="photo.urls.small" alt="Unsplash" />
      </div>
    </div>
    <PollBaseFileInput
      :label="t('blocksSettings.image.upload_image')"
      :block-id="props.id"
      type="image"
      @upload="uploadCallback"/>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PollBaseFileInput from './PollBaseFileInput.vue'; //'@/components/BaseFileInput.vue';
import { useI18n } from 'vue-i18n';
import UPLASHImageSearch from './UPLASHImageSearch.vue';

// const isRtl = ref(content.lesson?.display?.rtl);

// const modifiedIsRtl = computed(() => {
//   return (
//     (props.placement === 'start' && isRtl.value) ||
//     (props.placement === 'end' && !isRtl.value)
//   );
// });

const { t } = useI18n();

const photos = ref();

const setPhotos = newPhotos => {
  photos.value = newPhotos;
};

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  isFirst: {
    type: Boolean,
    required: true,
  },
  isLast: {
    type: Boolean,
    required: true,
  },
  placement: {
    type: String,
    required: true,
  },
  isModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['replace-image']);

const uploadCallback = (result: string) => {
  emit('replace-image', props.id, result);
};

const setImage = image => {
  emit('replace-image', props.id, image.urls.regular);
};
</script>

<style scoped>
.image-replacer-shadow {
  box-shadow: 0px 1px 6px 2px #0c4a6e0d;
}
</style>

<template>
  <div class="flex flex-1 flex-col gap-5">
    <div class="flex justify-end gap-1">
      <div
        v-for="(discipline, index) in course.disciplines"
        :key="index"
        class="flex">
        <span
          class="rounded-full bg-secondary-75 px-2 py-1 text-xs text-secondary-900 border-1 border-secondary-250">
          {{ discipline }}
        </span>
      </div>
      <div v-if="!hasTeacherTraining" class="flex gap-1">
        <div
          v-for="(discipline, index) in course.gates"
          :key="index"
          class="flex gap-2 items-center justify-center h-full">
          <span
            class="rounded-full bg-secondary-75 px-2 py-1 text-xs text-secondary-900 border-1 border-secondary-250">
            {{ discipline }}
          </span>
        </div>
        <div
          v-for="(discipline, index) in course.grades"
          :key="index"
          class="flex gap-2 h-full">
          <span
            class="rounded-full bg-secondary-75 px-2 py-1 text-xs text-secondary-900 border-1 border-secondary-250">
            {{ discipline }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <p class="font-simplerBold text-sm text-secondary-900">
        {{ course.caption ?? '---' }}
      </p>
      <p class="font-simplerRegular text-sm text-secondary-800">
        {{
          course.type === 'survey'
            ? `${course.total_questionnaires} שאלונים`
            : `${courseUnits(course)} יחידות, ${course.total_lessons_published || 1} שיעורים`
        }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type ICourse } from '../../../types/interfaces';
import { PropType } from 'vue';
import { get } from 'lodash';
import { TEACHER_TRAINING } from '@/utils';
const props = defineProps({
  course: {
    type: Object as PropType<ICourse>,
    default: () => {},
  },
});

const courseUnits = course => get(course, 'units', []).length || 1;
const hasTeacherTraining = props.course.disciplines.includes(TEACHER_TRAINING);
</script>

import swal from 'sweetalert';
import { ref } from 'vue';

const translationsMap = {
  converted_survey_results: 'שאלוני_תצאמ',
  converted_survey_fill_rate: 'אחוז_מילוי_שאלוני_תצאמ',
};

const translateToHebrew = name => {
  return translationsMap[name] || name;
};

export const downloadExcel = (response, courseName = '') => {
  // Create a blob from the response data
  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });

  let fileName = courseName;

  if (!fileName) {
    const contentDisposition = response.headers['content-disposition'];

    if (contentDisposition) {
      const match = contentDisposition.match(
        /filename[^;=\n]*=["']?([^"';\n]*)["']?/,
      );
      if (match && match[1]) {
        fileName = match[1];
      }
    }
  }

  if (!fileName) {
    fileName = 'default_filename.xlsx';
  }

  fileName = translateToHebrew(fileName);

  // Create a temporary link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName || 'download.xlsx';
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export const handleDownloadError = (error = null) => {
  const message = ref('');
  message.value =
    error.value.status === 422
      ? 'לא נמצאו תלמידים רשומים לקורס'
      : 'ארעה שגיאה בהפקת הקובץ, נא לנסות שוב מאוחר יותר';

  swal({
    title: 'שגיאה',
    text: message.value,
    icon: 'error',
  });
};

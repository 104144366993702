import { createAuthService, Origin, Scope } from '@amit/auth';
import VersionPlugin from './version';
import SentryPlugin from './sentry';
import i18nPlugin from './locale';
import PrimeVuePlugin from './prime';

import type { App, Plugin } from 'vue';
import type { Router } from 'vue-router';
import type { Pinia } from 'pinia';

type PluginOptions = {
  origin: Origin;
  scope: Scope;
  router: Router;
  store: Pinia;
};

const appPlugins: Plugin = {
  /**
   *
   * @param app
   * @param origin
   * @param scope
   * @param router
   * @param store
   *
   * @see
   * import appPlugins from '@amit/composables/plugins'
   * import { Origin, Scope } from '@amit/auth';
   * app.use(appPlugins, {
   *   origin: Origin.Students,
   *   scope: [Scope.Student, Scope.Teacher],
   *   router,
   *   store
   * })
   */
  install(app: App, { origin, scope, router, store }: PluginOptions): void {
    app.use(createAuthService(router), { origin, scope });
    app.use(store);
    app.use(VersionPlugin);
    app.use(SentryPlugin, { router, store });
    app.use(i18nPlugin);
    app.use(PrimeVuePlugin);
    app.use(router);
  },
};

export default appPlugins;

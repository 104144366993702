<template>
  <PVDialog
    :visible="visible"
    :modal="true"
    :dismissable-mask="true"
    class="transition-all duration-500"
    :style="{ width: '400px' }"
    :closable="false"
    :draggable="false">
    <template #header>
      <div class="w-full flex items-center justify-between">
        <p class="text-secondary-800 font-semibold">
          {{ $t('course_settings.confirmation.title') }}
        </p>
        <X
          class="stroke-secondary-900 cursor-pointer"
          :size="16"
          @click="close"/>
      </div>
    </template>

    <div class="flex flex-col gap-7">
      <p class="body">
        {{ $t('course_settings.confirmation.question_word') }}
        <span class="text-orange-500 font-simplerBold">
          {{ $t('course_settings.confirmation.question_text') }}
        </span>
        {{ lesson }} ?
      </p>
      <p v-if="isCoursePublished">
        {{ $t('course_settings.confirmation.description') }}
      </p>
    </div>

    <template #footer>
      <PVButton
        outlined
        class="border-primary text-primary"
        :label="$t('course_settings.confirmation.regret')"
        @click="close"/>
      <PVButton
        outlined
        :label="$t('course_settings.confirmation.agree')"
        severity="neutral"
        class="border-secondary-900 hover:bg-secondary-50"
        @click="$emit('confirm')"/>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import { X } from 'lucide-vue-next';
import { inject } from 'vue';
defineEmits(['confirm']);
const visible = defineModel<boolean>('visible', { default: false });
const isCoursePublished = inject('isCoursePublished');
defineProps({
  lesson: {
    type: String,
    default: '',
  },
});

const close = () => {
  visible.value = false;
};
</script>

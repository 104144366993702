<template>
  <EditPostModal
    v-if="noteDialogVisable"
    :id="id"
    :open="noteDialogVisable"
    :post-to-edit="postToEdit"
    :is-rtl="isRtl"
    @close="closeEditPostModal"
    @publish-new-post="sendNewPost"
    @publish-update-post="editPost"
  />

  <ShowPostModal
    v-if="postToEdit"
    :open="showPost"
    :post-to-edit="postToEdit"
    :current-user="currentUser"
    :is-operator="isOperator"
    :is-rtl="isRtl"
    @close="closePostModal"
    @comment="addComment"
    @delete-comment="deleteComment"
    @edit-comment="editComment"
    @add-like="addLikeToPost"
  />

  <div
    class="collab-board-block p-5 rounded-xl"
    :style="{ direction: isRtl ? 'rtl' : 'ltr' }"
  >
    <template v-if="true">
      <button
        v-if="!posts.length"
        :disabled="!demo && !connected"
        class="flex items-center justify-start px-3 bg-[#ffff] bg-opacity-50 border-2 border-secondary-250 py-2.5 rounded-lg w-[200px] create-note-button"
        :class="{ 'hover:opacity-100': !readOnly }"
        @click="
          postToEdit = null;
          noteDialogVisable = true;
        "
      >
        <Plus class="h-4 stroke-secondary-975" />
        <span class="text-sm !text-secondary-975">{{ newNoteText }}</span>
      </button>
      <MasonryWall
        :items="sortedPosts"
        :ssr-columns="4"
        :column-width="200"
        :gap="16"
      >
        <template #default="{ item, index }">
          <div class="flex gap-4 flex-col">
            <button
              v-if="index === 0"
              :disabled="!demo && !connected"
              class="flex items-center justify-start px-3 w-full bg-opacity-50 border-2 border-secondary-250 py-2.5 rounded-lg create-note-button hover:opacity-100"
              @click="
                postToEdit = null;
                noteDialogVisable = true;
              "
            >
              <Plus class="h-4 stroke-secondary-975" />
              <span class="text-sm !text-secondary-975">{{ newNoteText }}</span>
            </button>
            <CollaborativePost
              :post="item"
              :current-user="currentUser"
              :editable="postEditable(item?.author.userId)"
              :post-background="postBackground"
              :is-operator="isOperator"
              @delete="deletePost"
              @edit="handleEditPostclick"
              @click-post="handlePostClick"
              @add-like="addLikeToPost"
            />
          </div>
        </template>
      </MasonryWall>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed, PropType } from 'vue';

import EditPostModal from './EditPostModal.vue';
import ShowPostModal from './ShowPostModal.vue';
import CollaborativePost from './CollaborativePost.vue';
import { Plus } from 'lucide-vue-next';
import { useChannel } from '../composables/useChannel.ts';
import { Post, PostData } from '../types/post.ts';
import MasonryWall from '@yeger/vue-masonry-wall';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  currentUser: {
    type: Object as PropType<{ id: string; name: string }>,
    default: '',
  },
  channeUrl: {
    type: String,
    default: '',
  },
  hideContent: {
    type: Boolean,
    default: false,
  },
  demo: {
    type: Boolean,
    default: false,
  },
  locale: {
    type: String,
    default: 'he',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const appIdTemp = import.meta.env.VITE_SENDBIRD_APP_ID;
const tokenTemp = import.meta.env.VITE_SENDBIRD_API_TOKEN;
const showPost = ref(false);
const postBackground = ref('#FDF4D8');
const isRtl = computed(() => props.locale === 'he');
const newNoteText = computed(() => (isRtl.value ? 'פתק חדש' : 'New Note'));

const {
  posts,
  sendMessage,
  removeMessage,
  updateMessage,
  reactToMessage,
  setChannel,
  login,
  setIsTest,
  connected,
  isOperator,
} = useChannel(
  props.channeUrl,
  appIdTemp,
  tokenTemp,
  props.currentUser.id,
  props.readOnly,
);

const sortedPosts = computed(() => {
  return posts.value.slice().sort((postA, postB) => {
    const isOperatorA = postA.author.role === 'operator';
    const isOperatorB = postB.author.role === 'operator';
    const editableA = postEditable(postA.author.userId);
    const editableB = postEditable(postB.author.userId);

    if (isOperatorA !== isOperatorB) {
      return isOperatorA ? -1 : 1; // Operators first
    }
    if (editableA !== editableB) {
      return editableA ? -1 : 1; // Current user next
    }
    return 0; // Other posts last
  });
});

const noteDialogVisable = ref(false);
const postToEdit = ref<Post | null>(null);

const sendNewPost = (postData: PostData) => {
  sendMessage(postData);
};

const handlePostClick = (clicked, post: Post) => {
  showPost.value = clicked;
  postToEdit.value = post;
};

const deletePost = (post: PostData) => {
  removeMessage(post);
};
const editPost = (post: PostData) => {
  updateMessage(post);
};
const handleEditPostclick = (post: PostData | null) => {
  noteDialogVisable.value = true;
  postToEdit.value = posts.value.find(
    p => p.message_id === post?.message_id,
  ) as Post;
};

const addComment = (comment: { post: PostData; parentId: number }) => {
  sendMessage(comment.post, comment.parentId);
};
const deleteComment = (comment: PostData) => {
  removeMessage(comment);
};
const editComment = (comment: PostData) => {
  updateMessage(comment);
};

const addLikeToPost = (messageId, cancelReaction) => {
  reactToMessage(messageId, cancelReaction, 'like', props.currentUser.id);
};

const closeEditPostModal = () => {
  noteDialogVisable.value = false;
  postToEdit.value = null;
};

const closePostModal = () => {
  showPost.value = false;
  postToEdit.value = null;
};

const postEditable = (authorId: string): boolean => {
  return authorId === props.currentUser.id;
};

onMounted(() => {
  if (props.currentUser.id) {
    login(props.currentUser.id);
    setChannel(props.channeUrl);
  }
});

watch(
  posts,
  () => {
    if (postToEdit.value) {
      postToEdit.value = posts.value.find(
        post => post.message_id === postToEdit.value?.message_id,
      ) as Post;
    }
  },
  {
    deep: true,
  },
);
watch(
  () => props.channeUrl,
  () => {
    setChannel(props.channeUrl);
  },
);
watch(
  () => props.currentUser.id,
  () => {
    login(props.currentUser.id);
  },
);
watch(
  () => props.demo,
  () => {
    setIsTest(props.demo);
  },
);
</script>

<style scoped>
.create-note-button {
  background-color: #ffff;
  opacity: 0.6;
}

.collab-board-block {
  background-color: #d2bea8;
  height: auto;
  min-height: 500px;
  /* direction: rtl; */
}
</style>

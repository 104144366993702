<template>
  <div class="flex flex-1 flex-col justify-between h-full">
    <div class="flex justify-end gap-1 h-5">
      <div>
        <span
          class="h-5 rounded-full bg-secondary-75 px-2 py-1.5 text-xs text-secondary-900 border-1 border-secondary-250">
          {{ group?.name }}
        </span>
      </div>
      <div v-if="!hasTeacherTraining">
        <div
          v-for="(discipline, index) in [...(course.gates ?? [])]"
          :key="index">
          <span
            class="h-5 rounded-full bg-secondary-75 px-2 py-1.5 text-xs text-secondary-900 border-1 border-secondary-250">
            {{ discipline }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <p class="font-simplerBold text-sm text-secondary-900">
        {{ course.caption ?? '---' }}
      </p>
      <p class="font-simplerRegular text-sm text-secondary-800">
        {{ course.disciplines.join(', ') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type ICourse } from '../../../types/interfaces';
import { PropType } from 'vue';
import { TEACHER_TRAINING } from '@/utils';
const props = defineProps({
  course: {
    type: Object as PropType<ICourse>,
    default: () => {},
  },
  group: {
    type: Object,
    default: () => {},
  },
});

const hasTeacherTraining = props.course.disciplines.includes(TEACHER_TRAINING);
</script>

import { ref, isRef, watchEffect, reactive } from 'vue';
import type { MaybeRef } from 'vue';
import { syncRef } from '@vueuse/core';
import {
  useBreakpoints as coreBreakpoints,
  createEventHook,
} from '@vueuse/core';

export interface SubLink {
  to: string;
  text: string;
}

export interface ParentLink {
  text: string;
  subLinks?: SubLink[];
}

const onDrawerAction = createEventHook();
const onDrawerOpen = createEventHook();
const onDrawerClose = createEventHook();

const secondaryNavigationLinks = ref<ParentLink[]>([]);
const primaryNavigationLinks = ref([]);
const userMenuLinks = ref([]);

const showDrawer = ref(false);
const title = ref('');
const showClose = ref(true);
const isDrawerActionDisabled = ref(false);

const widgetsVisible = ref(false);
const widgetsEmpty = ref(true);

const drawers = reactive({});

const { smallerThanLg } = useBreakpoints();
const { openWidgets, closeWidgets } = useWidgets();
watchEffect(() => {
  smallerThanLg.value ? closeWidgets() : openWidgets();
});

export function useBreakpoints() {
  const breakpoints = coreBreakpoints({
    xs: '640px',
    sm: '768px',
    md: '1024px',
    lg: '1281px',
    xl: '1440px',
    '2xl': '1512px',
    '3xl': '1920px',
  });

  const smallerThanLg = breakpoints.smaller('lg');

  return {
    breakpoints,
    smallerThanLg,
  };
}

let unsync = null;
export function usePrimaryLinks(data) {
  if (data) {
    if (isRef(data)) {
      unsync = syncRef(primaryNavigationLinks, data);
    } else {
      unsync?.();
      primaryNavigationLinks.value = data;
    }
  }

  return primaryNavigationLinks;
}

let unsync3 = null;
export function useUserLinks(data) {
  if (data) {
    if (isRef(data)) {
      unsync3 = syncRef(userMenuLinks, data);
    } else {
      unsync3?.();
      userMenuLinks.value = data;
    }
  }

  return userMenuLinks;
}

let unsync2 = () => {};
export function useNavigation(data: MaybeRef<ParentLink[]> | null = null) {
  if (data) {
    if (isRef(data)) {
      unsync2 = syncRef(secondaryNavigationLinks, data);
    } else {
      unsync2();
      secondaryNavigationLinks.value = data;
    }
  }

  return secondaryNavigationLinks;
}

export function useDrawer() {
  const openDrawer = (options = {}) => {
    if (options?.showClose) {
      showClose.value = options.showClose;
    }

    if (options?.title) {
      title.value = options.title;
    }
    if (options?.isDrawerActionDisabled !== undefined) {
      setActionDisable(options.isDrawerActionDisabled);
    }

    drawers[options.name] = true;

    onDrawerOpen.trigger({});
    showDrawer.value = true;
  };

  const closeDrawer = () => {
    Object.keys(drawers).forEach(key => {
      drawers[key] = false;
    });
    onDrawerClose.trigger({});
    showDrawer.value = false;
  };

  const registerDrawer = name => {
    drawers[name] = false;
  };

  const setActionDisable = (isActionDisabled = false) => {
    isDrawerActionDisabled.value = isActionDisabled;
  };

  return {
    showDrawer,
    title,
    showClose,
    openDrawer,
    closeDrawer,
    onDrawerAction,
    registerDrawer,
    drawers,
    isDrawerActionDisabled,
    setActionDisable,
    onDrawerOpen: onDrawerClose.on,
    onDrawerClose: onDrawerClose.on,
  };
}

export function useWidgets(options = {}) {
  if (options.widgetsVisible) {
    widgetsVisible.value = options.widgetsVisible;
  }

  const openWidgets = () => {
    widgetsVisible.value = true;
  };

  const closeWidgets = () => {
    widgetsVisible.value = false;
  };

  const toggleWidgets = () => {
    setTimeout(() => {
      widgetsVisible.value = !widgetsVisible.value;
    }, 100);
  };

  return {
    widgetsVisible,
    widgetsEmpty,
    closeWidgets,
    openWidgets,
    toggleWidgets,
  };
}

<template>
  <ListGroup
    ref="target"
    class="w-fit max-w-80 border border-secondary-250 shadow-none"
  >
    <ListGroupItem class="!px-2 hover:bg-white" :hover="false">
      <div class="flex gap-2 items-center">
        <div class="flex gap-2">
          <Info />
          <input
            ref="tooltipInput"
            v-model="model"
            :placeholder="'כאן כותבים הסבר צף'"
            class="focus:ring-0 focus:outline-none"
            autofocus
            @keyup.enter="handleEnter"
          />
        </div>
        <div class="flex gap-1">
          <CircleCheck
            v-show="model"
            class="cursor-pointer stroke-primary"
            :size="16"
            @click="submitTooltip"
          />
          <CircleX
            v-show="model"
            class="cursor-pointer stroke-red-500"
            :size="16"
            @click="deleteTooltip"
          />
        </div>
      </div>
    </ListGroupItem>
  </ListGroup>
</template>
<script lang="ts" setup>
import { CircleCheck, CircleX, Info } from 'lucide-vue-next';
import { inject, nextTick, onMounted, ref } from 'vue';
//@ts-ignore
import { ListGroup, ListGroupItem } from '@';
import { useEditor } from '../composables/useEditor';
import { get } from 'lodash';
import { onClickOutside } from '@vueuse/core';

const model = ref('');
const { getCurrentMark } = useEditor();

const emit = defineEmits(['action', 'close']);
const submitTooltip = () => {
  emit('close');
  emit('action', model.value.trim());
};

const deleteTooltip = () => {
  emit('close');
  model.value = '';
  emit('action', model.value);
};

const handleEnter = () => {
  if (!model.value.trim()) {
    deleteTooltip();
  } else {
    submitTooltip();
  }
  return;
};

const tooltipInput = ref();

onMounted(() => {
  model.value = get(
    getCurrentMark(inject('blockId'), 'tooltip'),
    'mark.attrs.data_tooltip',
    '',
  );

  nextTick(() => {
    tooltipInput.value.focus();
    tooltipInput.value.select();
  });
});

const target = ref();
onClickOutside(target, () => {
  emit('close');
});
</script>

import { isTextEmpty } from '@amit/tiptap';
import * as yup from 'yup';
import { TEACHER_TRAINING } from '@/utils';
export const courseDirections = [
  {
    id: 'hebrew_course',
    title: 'קורס בעברית',
    locale: 'he',
  },
  {
    id: 'english_course',
    title: 'קורס באנגלית',
    locale: 'en',
  },
];
export const courseDisciplines = [
  { id: 1, title: 'כללי' },
  { id: 2, title: 'אנגלית', locale: 'en' },
  { id: 3, title: 'בית ספר היברידי' },
  { id: 4, title: 'הכשרת מורים' },
  { id: 5, title: 'הכשרת מטה' },
  { id: 6, title: 'היסטוריה חמ"ד' },
  { id: 7, title: 'היסטוריה ממלכתי' },
  { id: 8, title: 'השכלה כללית' },
  { id: 9, title: 'מיומנויות' },
  { id: 10, title: 'מדעים' },
  { id: 11, title: 'מתמטיקה' },
  { id: 12, title: 'רב-תחומי' },
  { id: 13, title: 'ספרות' },
  { id: 14, title: 'שפה' },
  { id: 15, title: 'תושב"ע' },
  { id: 16, title: 'תנ"ך חמ"ד' },
  { id: 17, title: 'תנ"ך ממלכתי' },
  { id: 18, title: 'תצא"מ' },
];

export const courseGrades = [
  { id: 'all', title: 'כל הכיתות' },
  { id: 7, title: 'כיתות ז' },
  { id: 8, title: 'כיתות ח' },
  { id: 9, title: 'כיתות ט' },
  { id: 10, title: 'כיתות י' },
  { id: 11, title: 'כיתות י"א' },
  { id: 12, title: 'כיתות י"ב' },
];

export const courseGates = [
  { id: '66e828001a207cb09b01a33a', title: 'שער 1 - מפגש' },
  { id: '66e8280a76c4859e897694cb', title: 'שער 2 - עצמאות' },
  { id: '66e8282da273325edd017e67', title: 'שער 3 - גילוי' },
];

function isEqualSpecificFields(
  value1,
  value2,
  fieldsToCheck = [],
  ignoreOrder = false,
) {
  // Helper to keep only specified fields
  function pickFields(obj, fields) {
    if (typeof obj !== 'object' || obj === null) return obj;
    if (Array.isArray(obj)) return obj.map(item => pickFields(item, fields));
    const filteredObj = {};
    for (const key of fields) {
      if (key in obj) {
        filteredObj[key] = pickFields(obj[key], fields);
      }
    }
    return filteredObj;
  }

  value1 = pickFields(value1, fieldsToCheck);
  value2 = pickFields(value2, fieldsToCheck);

  if (value1 === value2) {
    return true;
  }

  if (typeof value1 !== typeof value2) {
    return String(value1) === String(value2);
  }

  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;

    if (ignoreOrder) {
      const sorted1 = [...value1]
        .map(String)
        .sort((a, b) => a.localeCompare(b));
      const sorted2 = [...value2]
        .map(String)
        .sort((a, b) => a.localeCompare(b));
      return sorted1.every((val, index) => val === sorted2[index]);
    } else {
      return value1.every((val, index) =>
        isEqualSpecificFields(val, value2[index], fieldsToCheck, ignoreOrder),
      );
    }
  }

  // Compare objects
  if (
    value1 &&
    value2 &&
    typeof value1 === 'object' &&
    typeof value2 === 'object'
  ) {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (ignoreOrder) {
      if (
        !isEqualSpecificFields(
          keys1.sort((a, b) => a.localeCompare(b)),
          keys2.sort((a, b) => a.localeCompare(b)),
          fieldsToCheck,
          ignoreOrder,
        )
      ) {
        return false; // Keys do not match
      }
    } else {
      if (!isEqualSpecificFields(keys1, keys2, fieldsToCheck, ignoreOrder)) {
        return false; // Keys do not match
      }
    }

    return keys1.every(key =>
      isEqualSpecificFields(
        value1[key],
        value2[key],
        fieldsToCheck,
        ignoreOrder,
      ),
    );
  }

  return false; // Fallback for non-matching types or unsupported cases
}

export const compareRules = {
  caption: (value1, value2) => value1 === value2,
  description: (value1, value2) => {
    return isTextEmpty(value1) && isTextEmpty(value2)
      ? true
      : value1 === value2;
  },
  cover: (value1, value2) => value1 === value2,
  locale: (value1, value2) => value1 === value2,
  disciplines: (value1, value2) => value1?.[0] === value2?.[0],
  grades: (value1, value2) => value1?.[0] === value2?.[0],
  units: (value1, value2) =>
    isEqualSpecificFields(value1, value2, ['id', 'title']),
  outline: (value1, value2) =>
    isEqualSpecificFields(value1, value2, ['id', 'title', 'unit', 'lesson']),
  settings: {
    assessmentProfile: (value1, value2) => {
      if (value1 === value2) return true;
      if (value1?.id && value1.id === value2?.id) return true;
      if (value1 && value1 === value2?.id) return true;
      return false;
    },
  },
};

export const compareObjects = (obj1, obj2, rules) => {
  const evaluateRules = (currentRules, value1, value2) => {
    for (const key in currentRules) {
      const rule = currentRules[key];
      if (typeof rule === 'object') {
        // Recursively compare nested objects
        const hasChanges = evaluateRules(rule, value1?.[key], value2?.[key]);
        if (hasChanges) return true;
      } else if (typeof rule === 'function') {
        const hasChanges = !rule(value1?.[key], value2?.[key]);
        if (hasChanges) return true;
      }
    }
    return false;
  };

  // Start evaluation with top-level rules
  return evaluateRules(rules, obj1, obj2);
};

export const courseSchema = yup.object({
  caption: yup.string().trim().required('Caption is required'),
  cover: yup.string().trim().required('Cover is required'),
  // description: yup
  //   .string()
  //   .test(
  //     'is-not-empty',
  //     'Description is required',
  //     value => !isTextEmpty(value),
  //   ),
  disciplines: yup
    .array()
    .of(yup.string().trim().required('Discipline cannot be empty'))
    .min(1, 'At least one discipline is required')
    .required('Disciplines is required'),
  grades: yup
    .array()
    .of(yup.string().trim().required('grades cannot be empty'))
    .when('disciplines', {
      is: disciplines =>
        Array.isArray(disciplines) && disciplines.includes(TEACHER_TRAINING),
      then: schema => schema.notRequired(),
      otherwise: schema =>
        schema
          .min(1, 'At least one grade is required')
          .required('Grades is required'),
    }),
  gates: yup
    .array()
    .of(
      yup
        .string()
        .trim() // Ensure no leading/trailing spaces
        .required('gates cannot be empty'),
    )
    .min(1, 'At least one gates is required')
    .required('gates is required'),
  settings: yup.object({
    assessmentProfile: yup
      .mixed()
      .required('Assessment profile is required')
      .test(
        'is-valid',
        'Assessment profile must be a string or an object with a valid id in courseGates',
        value =>
          (value !== null && typeof value === 'string') ||
          (typeof value === 'object' &&
            value?.id &&
            courseGates.some(gate => gate.id === value.id)),
      ),
  }),
  units: yup
    .array()
    .of(
      yup.object({
        title: yup
          .string()
          .required('Title is required')
          .min(1, 'Title should not be empty'),
      }),
    )
    .min(1, 'At least one unit is required'),
  outline: yup
    .array()
    .of(
      yup.object({
        title: yup
          .string()
          .required('Title is required')
          .min(1, 'Title should not be empty'),
      }),
    )
    .min(1, 'At least one unit is required'),
});

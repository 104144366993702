<template>
  <PVDialog
    v-model:visible="isOpen"
    modal
    closable
    dismissable-mask
    class="transition-all duration-500"
    :style="{ width: '40vw', minWidth: '39rem' }"
    :pt="{
      content: {
        style: {
          height: '30vh',
          minHeight: '13rem',
        },
      },
    }">
    <template #header>
      <p class="text-secondary-800 font-semibold">
        {{ editMode ? 'עריכת מרכיב השיעור' : 'הוספת מרכיב השיעור' }}
      </p>
    </template>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-1">
        <label for="criteria">שם המרכיב</label>
        <PVInputText
          id="criteria"
          v-model="criteriaTitle"
          placeholder="התשובה שלי"
          autocomplete="off"
          :invalid="hasEmptyCriteriaTitles && !criteriaTitle"
          :class="[
            '!w-[35em]',
            'focus:ring-1 focus:ring-primary focus:border-primary focus:z-10 focus:me-[1px]',
          ]"
          :pt-options="{ mergeSections: false, mergeProps: true }"
          :pt="{
            root: ({ props }) => ({
              class: [
                '!w-[35em]',
                'focus:ring-1 focus:ring-primary focus:border-primary focus:z-10 focus:me-[1px]',
              ],
            }),
          }"/>
        <span
          v-if="hasEmptyCriteriaTitles && !criteriaTitle"
          class="text-red-500 text-xs">
          שדה זה לא יכול להיות ריק
        </span>
      </div>
      <div class="flex flex-col gap-1">
        <label for="criteria">אחוזים מהציון</label>
        <WeightField
          :weight="weightManagement._updatedCriteria.weight"
          @update-weight="
            newWeight => {
              weightManagement.updateCriteriaWeight(newWeight);
            }
          "/>
      </div>
    </div>
    <template #footer>
      <PVButton
        :label="`${editMode ? 'עריכת' : 'הוספת'} המרכיב`"
        @click="handleAddCriteria"/>
    </template>
  </PVDialog>
  <MessageDialog
    v-model="isEditedOpen"
    :icon="CriteriaAdded"
    :message="`המרכיב נשמר בהצלחה!`"/>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import PVInputText from 'primevue/inputtext';
import WeightField from './WeightField.vue';
import { useWeightManagement } from './store/WeightManagement';
import MessageDialog from '/@/components/MessageDialog.vue';
import CriteriaAdded from '/@/assets/criteriaAdded.svg?component';

const weightManagement = useWeightManagement();

const isEditedOpen = ref(false);

const isOpen = defineModel('isOpen', {
  type: Boolean,
  default: false,
});

// Use a ref to track if there are empty criteria titles
const hasEmptyCriteriaTitles = ref(false);

const handleAddCriteria = () => {
  // Update the ref based on the current state of the data
  hasEmptyCriteriaTitles.value = !criteriaTitle.value.trim();

  if (hasEmptyCriteriaTitles.value) {
    return; // Exit the function if there are validation errors
  }

  isOpen.value = false;

  if (editMode.value) {
    // Update existing item
    weightManagement._updatedData = weightManagement._updatedData.map(item => ({
      ...item,
      weight:
        item.id === weightManagement._originalCriteria?.id
          ? weightManagement._updatedCriteria.weight
          : item.weight,
      title:
        item.id === weightManagement._originalCriteria?.id
          ? weightManagement._updatedCriteria.title
          : item.title,
    }));
  } else {
    // Add new item
    weightManagement._updatedData = [
      ...weightManagement._updatedData,
      {
        id: '',
        title: weightManagement._updatedCriteria.title,
        weight: weightManagement._updatedCriteria.weight,
        type: 'criteria',
      },
    ];
  }

  if (!editMode.value && weightManagement._updatedCriteria?.weight === 0) {
    weightManagement.handleSaveChanges({
      updatedData: weightManagement._updatedData,
      onSave: () => {
        isEditedOpen.value = true;
      },
      weightUpdated: false,
    });
    return;
  }

  // In case we are editing a criteria and the weight is the same as the original weight, we need to save the changes
  // or
  // In case we are adding a new criteria and the weight is 0, we need to save the changes
  // or
  // In case we are editing a criteria and the weight hasn't changed, we need to save the changes
  if (
    (editMode.value &&
      weightManagement._updatedCriteria?.weight ===
        weightManagement._originalCriteria?.weight) ||
    (!editMode.value && !weightManagement._updatedCriteria?.weight) ||
    (editMode.value &&
      weightManagement._updatedCriteria?.weight ===
        weightManagement._originalCriteria?.weight)
  ) {
    weightManagement.handleSaveChanges({
      updatedData: weightManagement._updatedData,
      onSave: () => {
        isEditedOpen.value = true;
      },
      weightUpdated: false,
    });
  } else {
    weightManagement.isSelectCourseOfActionOpen = true;
  }
};

const editMode = computed(() => {
  return !!weightManagement._originalCriteria?.id;
});

const criteriaTitle = computed({
  get: () => weightManagement._updatedCriteria.title,
  set: newTitle => {
    weightManagement._updatedCriteria.title = newTitle;
  },
});
</script>

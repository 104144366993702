import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '/@/views/layouts/DefaultView.vue';
import { useSystemStore } from '/@/app/store/system';
import { showConfirmModal } from '/@/app/utils';

const routes = [
  {
    path: '/card-game',
    name: 'card-game',
    component: () => import('/@/views/CardGame.vue'),
  },
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('/@/views/playground/LobbyRoom.vue'),
        meta: {
          requiresAuth: true,
          title: '',
          disableBreadcrumbs: true,
        },
      },
      {
        path: 'course/:course',
        name: 'edit-course-settings',
        component: () => import('/@/views/Lobby/CourseSettings.vue'),
        meta: {
          requiresAuth: true,
          title: '',
        },
      },
      {
        path: 'course',
        name: 'create-course',
        component: () => import('/@/views/Lobby/CourseSettings.vue'),
        meta: {
          requiresAuth: true,
          title: '',
        },
      },
      {
        path: 'groups',
        name: 'groups-list',
        component: () => import('/@/views/playground/ListGroups.vue'),
        meta: {
          requiresAuth: true,
          title: '',
        },
      },
      {
        path: 'schools',
        name: 'school-list',
        component: () => import('/@/views/playground/ListSchools.vue'),
        meta: {
          requiresAuth: true,
          title: '',
          role: 'admin',
        },
      },
      {
        path: 'schools/:school',
        name: 'school-home',
        component: () => import('/@/views/playground/ManageSchool.vue'),
        meta: {
          requiresAuth: true,
          title: '',
          role: 'admin',
        },
      },
      {
        path: 'library/courses',
        name: 'library',
        component: () => import('/@/views/playground/LibraryCourses.vue'),
        meta: {
          requiresAuth: true,
          title: '',
        },
      },
      {
        path: '/library/courses/:course',
        name: 'course-library',
        component: () => import('/@/views/playground/CourseLibrary.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/library/surveys/:course',
        name: 'survey-library',
        component: () => import('/@/views/playground/CourseLibrary.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/groups/:group/surveys/:survey/status',
        name: 'survey-dashboard',
        component: () => import('/@/views/SurveyProgress.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/groups/:group/courses/:course',
        name: 'course-progress',
        component: () => import('/@/views/playground/CourseProgress.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/groups/:group/surveys/:course',
        name: 'survey-progress',
        component: () => import('/@/views/playground/CourseProgress.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/groups/:group/courses/:course/lessons/:lesson',
        name: 'lesson-progress',
        component: () => import('/@/views/playground/LessonProgress.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/groups/:group/courses/:course/lessons/:lesson/submissions',
        name: 'review-submissions',
        component: () => import('/@/views/LessonExercisesReview.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/accessibility',
        name: 'accessibility',
        component: () => import('/@/views/AccessibilityStatement.vue'),
      },
      {
        path: '/groups/:group/projects/:project',
        name: 'project-progress',
        component: () => import('/src/views/ProjectProgress.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'submissions/groups/:group/projects/:project/stages/:stage',
        name: 'review-project',
        component: () => import('/src/views/ProjectSubmission.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/groups/:group/surveys/:survey/visual',
    name: 'survey-progress-visual',
    component: () => import('/@/views/SurveyProgressVisual.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/preview/surveys/:survey/questionnaires/:questionnaire',
    name: 'survey-preview',
    component: () => import('/@/views/playground/SurveyPreview.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/preview/courses/:course/lessons/:lesson',
    name: 'preview',
    component: () => import('/src/views/LessonPreview.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/decisiongame',
    name: 'decision-game',
    component: () =>
      import('../../../src/apps/slideshowGame/DecisionMakingGame.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/preview/projects/:project/stages/:stage',
    name: 'project-preview',
    component: () => import('/src/views/ProjectPreview.vue'),
    meta: { requiresAuth: true },
  },

  // 404 - Keep this at the bottom of the list
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('/src/views/NotFound.vue'),
  },
];

const routerOptions: any = {
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { el: to.hash, top: 100, behavior: 'smooth' };
    } else {
      const layoutId = document.getElementById('app');
      if (layoutId) {
        return layoutId.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
};
export const router = createRouter(routerOptions);

router.beforeEach(async (to, from) => {
  if (from.meta?.confirmLeave) {
    const confirmLeave = await showConfirmModal(
      from.meta?.confirmLeaveTitle,
      from.meta?.confirmLeaveDescription,
    );
    if (!confirmLeave) {
      return false;
    }
  }
  const systemStore = useSystemStore();
  systemStore.isLoading = true;
});

router.afterEach(() => {
  const systemStore = useSystemStore();
  // Used for a better user experience only
  setTimeout(() => {
    systemStore.isLoading = false;
  }, 400);
});

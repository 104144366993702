<template>
  <PVDialog
    v-if="isOpen"
    :draggable="false"
    v-model:visible="isOpen"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    :style="{ width: '30rem' }"
    :pt="{
      mask: {
        class: ['!z-[1300]'],
      },
      root: {
        style: {
          direction: isRtl ? 'rtl' : 'ltr',
        },
      },
      header: {
        style: {
          border: '1px solid #D7DFEA',
        },
      },
      content: {
        class: [
          postToEdit ? 'post-editor-content' : '',
          localMedia.length > 0 ? 'min-h-[auto]' : '',
          selectedColor,
        ],
        style: {
          boxShadow: 'inset 0 -2px 4px 0 rgba(0, 0, 0, 0.05)',
          overflow: 'auto',
          background: selectedColor,
        },
      },
      closebuttonicon: {
        style: {
          width: '22px',
          height: '22px',
        },
      },
    }"
    @hide="emit('close')"
  >
    <template #header>
      <div class="text-secondary-975 font-simplerBold text-md">
        {{ headerText }}
      </div>
    </template>
    <div class="overflow-auto" style="">
      <div
        v-if="editor"
        class="flex flex-col w-full post-editor-content preview-wrapper"
        :dir="isRtl ? 'rtl' : 'ltr'"
        :class="selectedColor"
      >
        <BubbleMenu :editor="editor">
          <div class="bubble-menu">
            <TiptapMenu v-if="editor" :id="id" :editor="editor" />
          </div>
        </BubbleMenu>
        <TiptapContent v-if="editor" class="px-4 py-18" :editor="editor" />
      </div>

      <div>
        <div v-for="(media, index) of localMedia" class="relative px-5 py-1">
          <BaseImage
            v-if="media.type === 'image'"
            :src="media.url"
            height="auto"
            width="100%"
            fit="cover"
            class="!p-0 !pb-1.5 card-image max-[200px] overflow-auto rounded-xl"
          />

          <BaseVideo
            v-if="media.type === 'video'"
            :src="media.url"
            class=""
            :style="{ width: '100%' }"
          />
          <button class="absolute top-3 right-4" @click="removeMedia(index)">
            <CircleX />
          </button>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-between w-full items-center">
        <span class="flex gap-2">
          <button
            class="bg-secondary-100 py-2 px-3 flex justify-between gap-2 items-center hover:bg-secondary-250 rounded-lg"
            style="border: 1px solid #e3e9f2"
            @click="openWidget()"
          >
            <ImagePlus />
            <span>{{ addMediaText }}</span>
          </button>
          <button
            @click="toggle"
            class="bg-secondary-100 p-2 flex justify-between gap-8 items-center hover:bg-secondary-250 rounded-lg px-[10px]"
            style="border: 1px solid #e3e9f2"
          >
            <colorPattern />
          </button>
        </span>

        <span>
          <button
            class="bg-primary text-white px-4 py-2 rounded-lg font-simplerBold"
            @click="publishPost"
            :disabled="!publishButtonEnable"
            :class="{ 'bg-secondary-300 text-default': !publishButtonEnable }"
          >
            {{ publishButtonText }}
          </button>
        </span>

        <PVPopover
          ref="op"
          class="custom-popover"
          style="z-index: 9999"
          position="top"
        >
          <ColorPicker
            :colors="postColors"
            v-model:selectedColor="selectedColor"
          />
        </PVPopover>
      </div>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { TiptapMenu, useEditor, TiptapContent } from '@amit/tiptap';
import { BubbleMenu } from '@tiptap/vue-3';
import { useVModel } from '@vueuse/core';
import PVButton from 'primevue/button';
import PVDialog from 'primevue/dialog';
import PVPopover from 'primevue/overlaypanel';

import { containsRealText } from '../composables/utils';
import ColorPattern from '@/assets/color-pattern.svg?component';
import CircleX from '@/assets/circle-x.svg?component';

import ColorPicker from './ColorPicker.vue';
import {
  computed,
  onMounted,
  PropType,
  provide,
  ref,
  watch,
  watchEffect,
} from 'vue';
import { ImagePlus } from 'lucide-vue-next';
import { reactiveUploadResult, updateAndOpenInstance } from '@amit/composables';
import { BaseImage, BaseVideo } from '@nwire/amit-design-system';

import { Post, PostData, PostMedia, postColors } from '../types/post';
import { isEqual } from 'lodash';

const emit = defineEmits(['close', 'publish-new-post', 'publish-update-post']);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    default: false,
  },
  postToEdit: {
    type: Object as PropType<Post | null>,
    default: null,
  },
  isRtl: {
    type: Boolean,
    default: true,
  },
});

const { getEditor, createEditor } = useEditor();
const isOpen = useVModel(props, 'open', emit, { eventName: 'close' });
const postText = ref(props.postToEdit ? props.postToEdit.title : '');
const postData = ref<PostData>({ title: '', text: '' });
const publishButtonText = ref('פרסום פתק');
const headerText = ref(props.isRtl ? 'יצירת פתק' : 'Create post');
const placehoderText = props.isRtl ? 'כתוב פתק' : 'Write a post';
const addMediaText = props.isRtl ? 'הוספת מדיה' : 'Add media';

const op = ref();
const localMedia = ref<Array<PostMedia>>([]);

createEditor({
  id: props.id,
  editable: true,
  model: postText,
  direction: props.isRtl ? 'rtl' : 'ltr',
  onUpdate: () => {
    if (!editor.value) return;
    const html = editor.value?.getHTML();
    postText.value = html;
  },
  extensions: {
    placeholder: { placeholder: placehoderText },
    line_height: false,
    horizontal_rule: false,
    block_quote: false,
    image: false,
    image_resize: false,
    tooltip: false,
    link: false,
    background_color: false,
    color: false,
    table: false,
    indent: false,
    direction: false,
    text_align: false,
    text_type: false,
    font_size: false,
  },
});
const toggle = event => {
  op.value.toggle(event);
};

const selectedColor = ref(props.postToEdit?.backgroundColor);
onMounted(() => {
  if (props.open && props.postToEdit) {
    publishButtonText.value = props.isRtl ? 'עדכון' : 'Update';
    headerText.value = props.isRtl ? 'עריכת פתק' : 'Edit post';
    postText.value = props.postToEdit.title;
    editor.value?.commands.setContent(props.postToEdit.title);
  } else if (!props.postToEdit) {
    postText.value = '';
    editor.value?.commands.setContent(postText.value);
    publishButtonText.value = props.isRtl ? 'פרסום פתק' : 'Publish post';
    headerText.value = props.isRtl ? 'יצירת פתק' : 'Create post';
  }
  if (!props.open) {
    selectedColor.value = 'bg-white';
  }
  if (props.postToEdit?.media) {
    localMedia.value = JSON.parse(JSON.stringify(props.postToEdit.media));
  } else {
    localMedia.value = [];
  }
});

const editor = getEditor(props.id);
provide('blockId', props.id);
watch(
  () => props.open,
  () => {
    if (props.open && props.postToEdit) {
      postText.value = props.postToEdit.title;
      editor.value?.commands.setContent(postText.value);
    } else if (!props.postToEdit) {
      postText.value = '';
      editor.value?.commands.setContent(postText.value);
    }
  },
);

const publishPost = () => {
  props.postToEdit ? publishUpdatePost() : publishNewPost();
};

const publishNewPost = () => {
  postData.value.title = postText.value;
  postData.value.text = postText.value;
  postData.value.backgroundColor = selectedColor.value;
  postData.value.media = localMedia.value;
  emit('publish-new-post', postData.value);
  emit('close');
};

const publishUpdatePost = () => {
  postData.value.message_id = props.postToEdit?.message_id;
  postData.value.text = postText.value;
  postData.value.title = postText.value;
  postData.value.backgroundColor = selectedColor.value;
  postData.value.media = localMedia.value;
  emit('publish-update-post', postData.value);
  emit('close');
};

const publishButtonEnable = computed(() => {
  if (
    (containsRealText(postText.value) || localMedia.value.length) &&
    hasPostChanged()
  ) {
    return true;
  } else {
    return false;
  }
});

const hasPostChanged = (): boolean => {
  if (
    !isEqual(localMedia.value, props.postToEdit?.media) ||
    postText.value !== props.postToEdit?.title ||
    props.postToEdit?.backgroundColor !== selectedColor.value
  ) {
    return true;
  } else return false;
};

const openWidget = () => {
  updateAndOpenInstance(
    {
      clientAllowedFormats: ['image', 'video'],
      maxFileSize: 20 * 1024 * 1024,
      sources: ['local'],
    },
    'collabarative-board',
  );
};
watch(
  () => reactiveUploadResult.value,
  () => {
    if (
      reactiveUploadResult.value.block === 'collabarative-board' &&
      reactiveUploadResult.value.type === 'success'
    ) {
      localMedia.value.push({
        url: reactiveUploadResult.value.result,
        type: reactiveUploadResult.value.resourceType,
      });
    }
  },
  { deep: true },
);

const removeMedia = (index: number) => {
  localMedia.value.splice(index, 1);
};
</script>

<style scoped>
.border-footer {
  margin-left: -20px;
  margin-right: -20px;
}

.bubble-menu {
  display: flex;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 99999;
}
.bubble-menu > div {
  @apply border-0 py-2;
}
.post-editor-content {
  overflow: auto;
}
:deep(.editor-content) {
  min-height: auto;
}

:deep(.post-editor-conten) {
  max-height: max-content;
}
</style>

<style>
.custom-popover {
  z-index: 99999 !important;
}
</style>

<template>
  <FormControl :title="title" :sub-title="subTitle">
    <TiptapContent class="px-4 py-18" :editor="editor" />
    <BubbleMenu v-if="editor" :editor="editor">
      <div v-if="!editor.isActive('highlight')" class="flex flex-col">
        <div
          v-if="!hasAnswers || options.length === 0"
          class="flex flex-row items-center bg-white shadow-dropdown-light rounded-lg p-2 cursor-pointer"
          @click="
            editor
              .chain()
              .focus()
              .setHighlight({
                color: 'red',
              })
              .run()
          ">
          <Highlighter class="h-4" />
          <span class="text-dark text-xs font-simplerBold">סימון</span>
        </div>
        <div
          v-else
          class="flex flex-col gap-2 bg-white shadow-dropdown-light rounded-lg p-2 min-w-30 max-h-38 overflow-y-auto">
          <div
            v-for="(option, index) in options"
            :key="option.id"
            class="flex items-center gap-1 cursor-pointer"
            :class="{
              'border-b border-secondary-250 pb-2':
                index !== options.length - 1,
            }"
            @click="
              editor.chain().focus().setHighlight({ color: option.color }).run()
            ">
            <div
              class="rounded-full h-3 w-3"
              :style="{ backgroundColor: option.color }"></div>
            <span class="text-dark text-xs truncate flex-1">
              {{ option.title }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="editor.isActive('highlight')"
        class="flex flex-col bg-white shadow-dropdown-light rounded-lg min-w-30">
        <div
          class="flex flex-row items-center py-2 cursor-pointer"
          @click="removeHighlight">
          <Brush class="h-4" />
          <span class="text-dark text-xs font-simplerBold">ביטול</span>
        </div>
        <div
          v-if="options.length > 1 && hasAnswers"
          class="h-30 overflow-y-auto px-2">
          <div
            v-for="(option, index) in options"
            :key="option.id"
            class="flex items-center gap-1 cursor-pointer py-2"
            :class="{
              'border-b border-secondary-250 pb-2':
                index !== options.length - 1,
              'border-t border-secondary-250 pt-2': index === 0,
            }"
            @click="
              editor.chain().focus().setHighlight({ color: option.color }).run()
            ">
            <div
              class="rounded-full h-3 w-3 flex items-center justify-center"
              :class="{
                'border border-black': isActiveHighlightColor(option.color),
              }"
              :style="{ backgroundColor: option.color }">
              <Check v-if="isActiveHighlightColor(option.color)" class="h-2" />
            </div>
            <span class="text-dark text-xs truncate flex-1">
              {{ option.title }}
            </span>
          </div>
        </div>
      </div>
    </BubbleMenu>
  </FormControl>
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from 'vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { BubbleMenu } from '@tiptap/vue-3';
import { useEditor, TiptapContent } from '@amit/tiptap';
import isEmpty from 'lodash/isEmpty';
import { Highlighter, Brush, Check } from 'lucide-vue-next';

interface IOptions {
  id: string;
  title: string;
  color: string;
  matches: string[];
}

interface IProps {
  id: string;
  text: string;
  title?: string;
  subTitle?: string;
  options: IOptions[];
  modelValue: string;
}

const props = withDefaults(defineProps<IProps>(), {
  id: '',
  text: '<p></p>',
  title: '',
  subTitle: '',
  options: () => [],
  modelValue: '',
});
const emit = defineEmits(['update:modelValue']);
const { getEditor, createEditor } = useEditor();

createEditor({
  id: `preview-${props.id}`,
  editable: true,
  model: props.text,
  keyboard: false,
  onPaste: () => {},
  onUpdate: () => {
    if (!editor.value) return;
    const html = editor.value?.getHTML();
    emit('update:modelValue', html);
  },
  extensions: {
    placeholder: false,
    image: false,
    image_resize: false,
  },
});
const editor = getEditor(`preview-${props.id}`);

watch(
  () => [editor.value, props.modelValue],
  () => {
    const html = editor.value?.getHTML();
    if (!isEmpty(props.modelValue) && html !== props.modelValue) {
      editor.value?.commands.setContent(props.modelValue);
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (props.modelValue != '') return;
  const cleanedText = props.text.replace(/<mark[^>]*>|<\/mark>/g, '');
  editor.value?.commands.setContent(cleanedText);
  emit('update:modelValue', cleanedText);
});

const removeHighlight = () => {
  if (!editor.value) return;
  editor.value.chain().focus().unsetHighlight().run();
};

const isActiveHighlightColor = (color: string) => {
  return editor.value && editor.value.isActive('highlight', { color });
};

const hasAnswers = computed(() => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(props.text, 'text/html');
  return doc.querySelectorAll('mark').length > 0;
});
</script>

<style scoped>
:deep(.ProseMirror mark) {
  cursor: pointer !important;
}
</style>

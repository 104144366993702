<template>
  <div class="flex flex-col gap-1">
    <div class="flex flex-col p-5">
      <div class="flex justify-between mb-8">
        <div class="text-xl font-anomaliaBold text-secondary-900">
          {{ eventTitle() }} {{ user.fullName }}
        </div>
      </div>

      <div>
        <PVSelectButton
          v-if="isEditor"
          v-model="activeRole"
          :pt="selectButtonPT"
          :options="Object.keys(roles)"
          :allow-empty="false">
          <template #option="{ option }">
            <div class="flex gap-1.5 items-center">
              <component
                :is="roles[option].icon"
                :size="16"
                :class="{ 'stroke-secondary-500': option !== activeRole }"/>
              <p :class="{ 'text-secondary-500': option !== activeRole }">
                {{ roles[option].label }}
              </p>
            </div>
          </template>
        </PVSelectButton>

        <Transition name="slide-fade">
          <EditorLobby v-if="activeRole === 'editor'" />
          <TeacherLobby v-else />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import TeacherLobby from '/@/views/Lobby/TeacherLobby.vue';
import { computed, onMounted, ref } from 'vue';
import { useAccountStore } from '/@/app/store/account';

const { t } = useI18n();
const accountStore = useAccountStore();
const user = computed(() => accountStore.user);
const isEditor = ref<boolean>(false);
const d = new Date();

const eventTitle = () => {
  if (d.getHours() > 5 && d.getHours() <= 12) {
    return t('dashboard.goodMorning');
  } else if (d.getHours() > 12 && d.getHours() <= 15) {
    return t('dashboard.goodNoon');
  } else if (d.getHours() > 15 && d.getHours() <= 17) {
    return t('dashboard.goodAfterNoon');
  } else if (d.getHours() > 17 && d.getHours() <= 22) {
    return t('dashboard.erevTov');
  } else return t('dashboard.goodNight');
};

import EditorLobby from '/@/views/Lobby/EditorLobby.vue';
import { Laptop, Presentation } from 'lucide-vue-next';
import PVSelectButton from 'primevue/selectbutton';
import { useRouteQuery } from '@vueuse/router';
import { selectButtonPT } from '/@/views/Lobby/styles';

onMounted(() => {
  isEditor.value =
    user.value.role.includes('editor') || user.value.role.includes('admin');
});

const roles = {
  teacher: {
    label: t('lobby_room.teacher'),
    icon: Presentation,
    content: TeacherLobby,
  },
  editor: {
    label: computed(() =>
      t(`lobby_room.editor_${user.value?.gender || 'male'}`),
    ),
    icon: Laptop,
    content: EditorLobby,
  },
};

const activeRole = useRouteQuery<string>(
  'role',
  computed(() => {
    const defaultRole = 'teacher';
    const userRole = user.value.role[0];
    return roles[userRole] ? userRole : defaultRole;
  }),
);
</script>

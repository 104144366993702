<template>
  <MainLayout
    :flip="true"
    title="תירגול מעשי"
    :breadcrumb="true"
    :margin-bottom="true"
    :main-scrollbar="{
      'suppress-scroll-y': true,
      'suppress-scroll-x': true,
      'scrollbar-offset': '-8px',
    }">
    <template #main>
      <div :style="{ height: 'calc(100vh - 200px)' }">
        <StudentsProjectTable
          v-if="currentGroup"
          :students="currentGroup.students"
          :enrollments="enrollments"
          @unlock-stage="unlockGroupStage"/>
      </div>
    </template>
  </MainLayout>
</template>

<script setup lang="ts">
import StudentsProjectTable from '/@/views/components/StudentsProjectTable.vue';
import { ref, computed, watchEffect } from 'vue';
import { fetchEnrollments } from '/@/app/services/projects';
import { useAccountStore } from '/@/app/store/account';
import { RouteParams, useRoute } from 'vue-router';
import MainLayout from '/@/views/layouts/MainLayout.vue';
import { unlockStage } from '/@/app/services/projects';
import swal from 'sweetalert';

const accountStore = useAccountStore();
const route = useRoute();
const { group, project }: RouteParams | { group: any; project: any } =
  route.params;
const enrollments = ref();
const currentGroup = computed(() => {
  return accountStore.groups.find(({ id }) => id === group);
});

watchEffect(() => {
  const { onSuccess } = fetchEnrollments(project, group);
  onSuccess(({ data }) => {
    enrollments.value = data;
  });
});

const updateAllEnrollments = stage => {
  enrollments.value.forEach(({ summary }) => {
    const foundStage = summary.find(summary => summary.stage.id === stage);
    if (foundStage) {
      foundStage.locked = false;
    }
  });
};

const unlockGroupStage = async stage => {
  const confirmed = await swal({
    text: 'בלחיצה על אישור השלב יפתח לתלמידים.',
    buttons: ['ביטול', 'אישור'],
  });
  if (!confirmed) {
    return;
  }

  const { onSuccess } = unlockStage(project, stage, group);
  onSuccess(() => {
    updateAllEnrollments(stage);
  });
};
</script>

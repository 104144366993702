<template>
  <BaseModal v-show="isModalOpened" @close="closeModal">
    <template #body>
      <div class="flex flex-col space-y-4 text-center">
        <span class="text-xl font-anomaliaBold">תזמון פתיחת שיעור</span>
        <span class="text-base w-75 text-center mx-auto">
          {{
            `למתי לתזמן לפתוח את שיעור ${selectedLesson?.lesson} ${selectedLesson?.title} בקורס
          ${course.caption} ?`
          }}
        </span>
        <div class="w-50 mx-auto">
          <DatePicker
            v-model="selectedDate"
            :min-date="new Date()"
            :max-date="add(new Date(), { months: 6 })"
            :clearable="false"
            :prevent-min-max-navigation="true"
            @select-dates="updateUnlockDate"/>
        </div>
        <div class="flex justify-end items-center">
          <PVButton severity="neutral" text label="ביטול" @click="closeModal" />
          <PVButton
            label="אישור תזמון"
            :disabled="!selectedDate"
            :loading="loading"
            @click="unLockLesson"/>
        </div>
      </div>
    </template>
  </BaseModal>
  <CourseFilesLibrarySidebar
    v-model="filesSidebarVisible"
    :links="links"
    :introduction="course?.introduction"/>
  <div class="mt-2 flex flex-col gap-4">
    <div class="flex justify-end items-center gap-2">
      <PVButton
        class="h-8"
        :label="$t('course_page.files_library')"
        plain
        severity="neutral"
        @click="filesSidebarVisible = true">
        <template #icon>
          <img :src="BookOpenIcon" alt="book_icon" class="w-3 h-3" />
        </template>
      </PVButton>
      <PVButton
        class="self-end w-33 h-8"
        :label="$t('course_page.course_preview')"
        @click="navigateToPreview(summary[0]?.lesson.id)">
        <template #icon>
          <Playicon :size="12" />
        </template>
      </PVButton>
    </div>

    <div class="flex flex-col gap-6">
      <div v-for="unitLesson in unitsLessons" :key="unitLesson.id">
        <div class="flex flex-col gap-2">
          <label class="text-secondary-975 text-sm font-simplerRegular">
            {{ unitLesson.title || $t(`level.${unitLesson.level}`) }}
          </label>
          <LessonInfo
            v-for="item in unitLesson.lessons"
            :key="item.id"
            :index="item.lessonIndex"
            :lesson="item.lesson"
            :teams-enabled="item.teams_enabled"
            :locked="item.locked"
            :unlock-date="item.unlock_at"
            @click="navigateToPreview(item.lesson.id)"
            @show-modal="openModal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';
import { unlockLesson } from '/@/app/services/students';
import { listFiles } from '/@/app/services/courses';
import LessonInfo from '/@/views/playground/LessonInfo.vue';
import CourseFilesLibrarySidebar from '/@/views/playground/components/CourseFilesLibrarySidebar.vue';
import PVButton from 'primevue/button';
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { BaseModal, DatePicker } from '@nwire/amit-design-system';
import { add } from 'date-fns';
import { Play as Playicon } from 'lucide-vue-next';
import BookOpenIcon from '/@/assets/BookOpen.svg?url';

const router = useRouter();
const studentsStore = useStudentsStore();
const props = defineProps({
  courseModule: {
    type: Object,
    required: true,
  },
});
const isModalOpened = ref(false);
const filesSidebarVisible = ref(false);
const links = ref([]);
const selectedLesson = ref();
const selectedDate: any = ref(new Date());
const loading = ref(false);

const summary = computed(() => props.courseModule?.summary ?? []);
const course = computed(() => props.courseModule.course);
const group = computed(() => props.courseModule.group);

const unitsLessons = computed(() => {
  const units = course.value.units ?? [];
  return units.map(unit => {
    const { id, title, level } = unit;
    const lessons = summary.value
      .sort((a, b) => a.lesson.lesson - b.lesson.lesson)
      .map((lesson, index) => ({
        ...lesson,
        lessonIndex: index + 1, // Add the index to each lesson
      }))
      .filter(({ lesson }) => lesson.unit === id);

    return {
      id,
      title,
      level,
      lessons,
    };
  });
});

onMounted(() => {
  const listFilesResp = listFiles(course.value.id);
  listFilesResp.onSuccess(({ data }) => {
    links.value = data.sort((a, b) => a.order - b.order);
  });
});

const navigateToPreview = lesson => {
  const { href } = router.resolve({
    name: 'preview',
    params: {
      course: course.value.id,
      lesson,
    },
    query: { course_module_group: group.value },
  });
  window.open(href, '_blank');
};

const openModal = lesson => {
  selectedLesson.value = lesson;
  selectedDate.value =
    summary.value.find(item => item.lesson.id === lesson.id).unlock_at ??
    new Date();
  isModalOpened.value = true;
};

const unLockLesson = async () => {
  loading.value = true;
  const { onSuccess } = unlockLesson(
    group.value,
    course.value.id,
    selectedLesson.value.id,
    selectedDate.value,
  );
  onSuccess(() => {
    loading.value = false;
    studentsStore.setCourseModule(group.value, course.value.id);
    closeModal();
  });
};

const updateUnlockDate = e => (selectedDate.value = e.dates);
const closeModal = () => {
  isModalOpened.value = false;
  selectedDate.value = new Date();
  selectedLesson.value = null;
};
</script>

import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchSurveys = () => useApi(http.get('/surveys'));

export const getSurvey = id => useApi(http.get(`/surveys/${id}`));

export const getSurveyEnrollments = ({ survey, group = null }) =>
  useApi(http.get(`/surveys/${survey}/enrollments`, { params: { group } }));

export const createEnrollments = ({ group, survey }) =>
  useApi(http.post(`/surveys/${survey}/enrollments`, { group }));

export const cancelDistribution = ({ group, survey }) =>
  useApi(http.post(`/surveys/${survey}/enrollments/cancel`, { group }));

export const unlockQuestionnaire = ({
  survey,
  questionnaire,
  group,
  unlock_at,
}) =>
  useApi(
    http.post(`/surveys/${survey}/questionnaires/${questionnaire}/unlock`, {
      group,
      unlock_at,
    }),
  );

export const setEnrollmentGroup = ({ survey, user, group }) =>
  useApi(http.post(`/surveys/${survey}/enrollments/move`, { user, group }));

export const exportSurveyFillRate = () =>
  useApi(http.get('surveys/export/fillRate', { responseType: 'blob' }));
export const exportSurveyEnrollments = () =>
  useApi(http.get('surveys/export/summary', { responseType: 'blob' }));

<template>
  <PVInputNumber
    v-model="updatedWeight"
    :min="0"
    :max="100"
    :class="size === 'small' ? 'w-[5.5em]' : 'w-[8em]'"
    show-buttons
    suffix="%"
    @blur="handleBlurEvent"
    @keydown.enter="handleEnterKey">
    <template #incrementbuttonicon>
      <ArrowUpIcon />
    </template>
    <template #decrementbuttonicon>
      <ArrowDownIcon />
    </template>
  </PVInputNumber>
</template>

<script setup lang="ts">
import PVInputNumber from 'primevue/inputnumber';
import ArrowDownIcon from '/@/assets/arrowDown2.svg?component';
import ArrowUpIcon from '/@/assets/arrowUp2.svg?component';
import { computed, PropType } from 'vue';

const emit = defineEmits<{
  onBlur: [];
  updateWeight: [newWeight: number];
}>();

const props = defineProps({
  weight: {
    type: Number,
    default: 0,
  },
  size: {
    type: String as PropType<'small' | 'large'>,
    default: 'large',
  },
});

const updatedWeight = computed({
  get: () => props.weight,
  set: value => {
    let weight = value;
    if (value > 100) weight = 100;
    if (value < 0) weight = 0;
    emit('updateWeight', weight);
  },
});

let isEnterPressed = false;

const handleBlurEvent = ({ value }) => {
  if (!isEnterPressed) {
    handleBlur(value);
  }
  isEnterPressed = false; // Reset the flag
};

const handleEnterKey = event => {
  event.preventDefault(); // Prevent default form submission behavior
  isEnterPressed = true;
  handleBlur(event.target.value);
};

const handleBlur = (value: string) => {
  let weight = Number(value.replace('%', ''));
  if (weight > 100) weight = 100;
  if (weight < 0) weight = 0;
  emit('updateWeight', weight);
  emit('onBlur');
};
</script>

<template>
  <div
    class="inplace"
    :class="{ '!border !border-red-500 min-w-20': empty }"
    @click="startEditing">
    <template v-if="isEditing">
      <slot
        name="content"
        :on-close-callback="close"
        :is-editing="isEditing"></slot>
    </template>
    <template v-else>
      <slot name="display"></slot>
    </template>
  </div>
</template>

<script setup>
import { ref, nextTick, watch } from 'vue';
import { useEditingTracker } from '/@/views/Lobby/CourseSettings/useEditingTracker';

const { setEditing } = useEditingTracker();
const isEditing = ref(false);

defineProps({
  empty: {
    type: Boolean,
    default: false,
  },
});
const close = () => {
  isEditing.value = false;
};

const startEditing = () => {
  isEditing.value = true;
  nextTick(() => {
    const input = document.querySelector('.inplace [autofocus]');
    if (input) input.focus();
  });
};

watch(isEditing, newVal => {
  setEditing(newVal);
});
</script>

<style scoped>
.inplace {
  @apply cursor-pointer rounded-md border border-transparent;
}
.inplace:hover {
  @apply border-secondary-300;
}
.inplace:has(.content) {
  @apply bg-white border-secondary-300;
}
</style>

<template>
  <Transition
    enter-active-class="transition ease-in-out duration-700 opacity"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in-out duration-100 opacity"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0">
    <div v-if="selectedItem">
      <div class="mt-5">
        <VideoMenuSelection
          v-model="selectedItem"
          :selection="selection"
          :videos="videos"
          @video-next-enabled="$emit('video-next-enabled')"/>
      </div>
    </div>

    <div
      v-else
      class="h-full w-full flex flex-col justify-between lg:justify-start">
      <div
        class="flex flex-col lg:flex-row justify-center flex-grow py-3 lg:py-5 space-y-3 lg:space-y-0 lg:gap-x-3">
        <label
          v-for="{ id, name, description, avatar } in selection"
          :key="id"
          class="p-3 lg:p-5 flex flex-col space-y-2 rounded-3xl shadow-solid w-full lg:w-33/100 max-w-100 cursor-pointer justify-between">
          <div class="flex items-center justify-start w-full gap-2">
            <span
              class="font-simplerBold text-lg text-dark gap-3"
              :class="{ 'text-primary': selectedItem === id }">
              {{ name }}
            </span>
          </div>
          <div class="flex flex-col justify-between grow">
            <span class="flex font-simplerRegular text-secondary-900 leading-6">
              {{ description }}
            </span>
            <div class="relative">
              <img
                class="rounded-lg w-full overflow-hidden"
                :src="avatar"
                alt="Coach image"/>
              <div
                class="absolute top-0 flex items-center justify-center w-full h-full bg-imgFound rounded-lg"
                @click="() => setSelectedItem(id)">
                <Play
                  class="stroke-white border-2 border-white rounded-full h-10 w-10 p-2"/>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import { ref, PropType } from 'vue';
import { Play } from 'lucide-vue-next';
import VideoMenuSelection from '@/components/VideoGallery/VideoMenuSelection.vue';
import { ISelectionItem, IGalleryVideo } from '@/types/interfaces';

defineProps({
  selection: {
    type: Array as PropType<ISelectionItem[]>,
    default: () => [],
  },
  videos: {
    type: Array as PropType<IGalleryVideo[]>,
    default: () => [],
  },
});

defineEmits(['video-next-enabled']);

const selectedItem = ref();

const setSelectedItem = id => {
  selectedItem.value = id;
};
</script>

import { collect } from 'collect.js';
import { get } from 'lodash';
import { format, formatDistanceToNow } from 'date-fns';
import { he as heLocale } from 'date-fns/locale';
import { inject, InjectionKey } from 'vue';
import { he } from 'date-fns/locale';
import { Message } from '../types/message';
import { Post } from '../types/post';

const cloudName = 'dcodkxpej';
const cloudinaryParams = {
  cloudinaryUrl: `https://api.cloudinary.com/v1_1/${cloudName}/`,
  unsignedUploadPreset: 'r6mwkrzq',
};

const formatPostMessage = (
  message: Message,
  comments: any[] = [],
  groupName = '',
) => {
  let post: any = { likes: [] };
  const metadata = get(message, 'data', null) ? JSON.parse(message.data) : null;

  return {
    ...post,
    group_name: groupName,
    comments: comments || [],
    post_id: message.channelUrl,
    message_id: message.messageId,
    reactions: message.reactions,
    title: message.message,
    metadata: metadata,
    created_at: new Date(message.createdAt),
    updated_at: new Date(message.updatedAt || message.createdAt),
    cover_img: get(metadata, 'media_url', null),
    author: message.sender,
    status: 'ACTIVE', // TBDc
    comment_status: message?.threadInfo?.replyCount ? true : false, // TBD
    backgroundColor: JSON.parse(message?.data)?.backgroundColor || 'bg-white',
    media: JSON.parse(message?.data)?.media,
  } as Post;
};

const sortedByDate = (elements: any, key = 'created_at') => {
  return collect(elements).sortBy(key).reverse().all();
};

export const scrollToBottomOfMessages = async (id = '#content_area') => {
  const container = document.querySelector(id);
  await new Promise(r => setTimeout(r, 100));
  if (!container) return;
  container.scrollTop = container.scrollHeight + 100;
};

export const formatToTime: any = (date: Date) => {
  return format(date, 'HH:mm', { locale: heLocale });
};

export const timeAgo = date => {
  return formatDistanceToNow(new Date(date), { addSuffix: false, locale: he });
};

export const requireInjection = <T>(key: InjectionKey<T>, defaultValue?: T) => {
  const resolved = inject(key, defaultValue);
  if (!resolved) {
    throw new Error(`${key} was not provided.`);
  }
  return resolved;
};

export const sendbirdUtils = {
  formatPostMessage,
  sortedByDate,
  cloudinaryParams,
  scrollToBottomOfMessages,
};

export const getPostOwnerName = (nickName: string): string => {
  const names = nickName.trim().split(' ');
  const firstWord = names[0];
  if (names.length < 2) return firstWord;
  const lastWordInitial = names[names.length - 1][0];
  return firstWord + ' ' + lastWordInitial;
};

export const getPostAvatarName = (nickName: string): string => {
  if (!nickName) return '';
  let avatarName = '';
  const names = nickName.trim().split(' ');
  avatarName = names[0][0];
  if (names.length < 2) return avatarName;
  avatarName += names[names.length - 1][0];
  return avatarName;
};

export const containsRealText = (htmlString): boolean => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  const textContent = tempElement.textContent || tempElement.innerText || '';
  return textContent.trim().length > 0;
};

<template>
  <PVDialog
    v-model:visible="isOpen"
    :modal="true"
    :closable="true"
    :dismissable-mask="true"
    :style="{ width: '20rem' }"
    :pt="{
      mask: {
        class: ['!z-[1301]'],
      },
      content: {
        style: {},
      },
    }"
    @hide="emit('close')">
    <template #header>
      <div v-if="header">{{ header }}</div>
    </template>
    <div v-if="text" class="text-sm font-semibold text-secondary-900">
      {{ text }}
    </div>
    <template #footer>
      <PVButton
        :label="cancelButtonText"
        severity="primary"
        class=""
        :loading="false"
        :class="buttonClass"
        :disabled="false"
        @click="emit('close')"/>
      <PVButton
        :label="approveButtonText"
        severity="danger"
        class="bg-primary text-white"
        :loading="false"
        :disabled="false"
        @click="approve"/>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';

import { computed, PropType } from 'vue';

enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}

const emit = defineEmits(['close', 'approve']);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  header: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  cancelButtonText: {
    type: String,
    default: 'ביטול',
  },
  approveButtonText: {
    type: String,
    default: 'אישור',
  },
  type: {
    type: String as PropType<ButtonType>,
    required: true,
  },
});

const isOpen = useVModel(props, 'open', emit, { eventName: 'close' });

const buttonClass = computed(() => {
  switch (props.type) {
    case ButtonType.Primary:
      return 'bg-primary text-white';
    case ButtonType.Secondary:
      return 'bg-secondary text-black';
    case ButtonType.Danger:
      return 'bg-danger text-white';
    default:
      return '';
  }
});

const approve = () => {
  emit('approve');
  emit('close');
};
</script>

<style scoped>
.border-footer {
  margin-left: -20px;
  margin-right: -20px;
}
</style>

<template>
  <div class="flex flex-col gap-2 w-full items-center sort-content">
    <VueDraggable
      class="flex flex-col gap-4 z-[2] bg-transparent relative w-full"
      :list="model"
      :group="{ name: `choices-${blockId}`, pull: 'clone', put: false }"
      item-key="name"
      :animation="200"
      :force-fallback="true"
      :scroll-sensitivity="isPreview ? 200 : 0"
      @change="handleDrag($event)"
      @start="onDragStart"
      @end="onDragEnd">
      >
      <template #item="{ element, index }">
        <div class="flex w-full gap-2 items-center group/edit">
          <div
            class="flex min-h-12 gap-2 w-full cursor-grab active:cursor-grabbing rounded-md border"
            :class="{
              'border-primary rotate-3 opacity-0': draggedIndex === index,
              'border-secondary-300': draggedIndex !== index,
            }">
            <div
              class="flex min-h-12 items-center bg-[#F4F7FB] px-3"
              :class="{
                '!bg-primary': draggedIndex === index,
                'rounded-r-md': isRtl,
                'rounded-l-md': !isRtl,
              }">
              <Ellipsis
                :class="{
                  'stroke-white': draggedIndex === index,
                }"/>
            </div>
            <div v-if="!isPreview" class="flex w-full gap-2 items-center">
              <textarea
                :ref="el => (textareaRefs[index] = el)"
                v-model="element.title"
                :placeholder="
                  t('blocksSettings.choice.default_title_field_placeholder')
                "
                class="resize-none caret-primary caret-5 p-1 rounded-sm w-full bg-transparent outline-none border-none focus:block-outline overflow-y-hidden placeholder-secondary-500 text-secondary-900"
                @input="onInput(index)"/>
            </div>
            <div v-else class="whitespace-pre-line py-2 flex items-center">
              {{ element.title }}
            </div>
          </div>
          <div
            v-if="model.length > 2 && !isPreview"
            class="flex items-center justify-center cursor-pointer rounded-full p-1 bg-secondary-200 hover:bg-secondary-300 opacity-0 group-hover/edit:opacity-100 transition-opacity duration-200"
            @click="emit('delete-option', index)">
            <X class="w-4 h-4 stroke-[1.2px]" />
          </div>
        </div>
      </template>
    </VueDraggable>
    <div v-if="!isPreview" class="flex items-center w-full relative h-10">
      <div
        v-if="model.length < 10"
        class="flex items-center w-full justify-center">
        <BaseTooltip popper-class="z-[9999]" placement="top">
          <template #trigger>
            <div
              class="w-3 h-3 rounded-full bg-primary group/add hover:h-6 hover:w-6 flex items-center justify-center cursor-pointer"
              @click="addOption">
              <Plus
                class="stroke-white w-5 invisible group-hover/add:visible h-5"/>
            </div>
          </template>
          <template #content>
            <span>{{ t('editor.add_answer') }}</span>
          </template>
        </BaseTooltip>
      </div>
      <div class="absolute left-8 flex text-secondary-500">
        {{ `(${model.length}/ 10)` }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, nextTick, onMounted, type PropType } from 'vue';
import type { SortOption } from './sort';
import VueDraggable from 'vuedraggable';
import Ellipsis from '@/assets/ellipsis.svg?component';
import { Plus, X } from 'lucide-vue-next';
import { BaseTooltip } from '@amit/foundation';

const props = defineProps({
  isPreview: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Array as PropType<SortOption[]>,
    default: () => [],
  },
  blockId: {
    type: String,
    required: true,
  },
  locale: {
    type: String,
    default: 'he',
  },
});

const { t } = useI18n();
const model = computed(() => [...props.modelValue]);

const draggedIndex = ref(null);
const textareaRefs = ref<Array<HTMLTextAreaElement | null>>([]);
const isRtl = computed(() => props.locale === 'he');

const adjustHeight = index => {
  const textarea = textareaRefs.value[index];
  if (textarea) {
    textarea.style.height = 'auto';
    const title = model.value[index]?.title;
    if (!title) {
      textarea.style.height = '32px';
    } else {
      const lines = title.split('\n');
      if (lines.length === 1) {
        textarea.style.height = '32px';
      }
      const scrollHeight = textarea.scrollHeight;
      const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
      const maxAllowedHeight = lineHeight * Infinity;
      textarea.style.height = `${Math.min(scrollHeight, maxAllowedHeight)}px`;
    }
  }
};

const onDragStart = evt => {
  draggedIndex.value = evt.oldIndex;
};

const adjustAllHeights = () => {
  textareaRefs.value.forEach((_, index) => {
    adjustHeight(index);
  });
};

onMounted(() => {
  adjustAllHeights();
});

const onDragEnd = () => {
  draggedIndex.value = null;
  requestAnimationFrame(() => {
    adjustAllHeights();
  });
};

const onInput = (index: number) => {
  adjustHeight(index);
};

const addOption = async () => {
  emit('add-option');
  await nextTick();
  adjustAllHeights();
};

const emit = defineEmits([
  'update:modelValue',
  'add-option',
  'delete-option',
  'update-options',
]);

const handleDrag = event => {
  emit('update:modelValue', event);
  emit('update-options', model.value);
};
</script>

<style scoped>
textarea:focus::placeholder {
  color: transparent;
}
</style>

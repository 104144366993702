<template>
  <MainLayout :flip="true" :breadcrumb="true" :title="stageTitle">
    <template #main>
      <div class="flex flex-col space-y-2">
        <div
          class="bg-white px-3 h-20 shadow-box-light flex items-center justify-between">
          <button
            class="text-xs"
            :disabled="studentIndex === 0"
            @click="goToStudent(Direction.PREV)">
            {{ '< מעבר לתלמיד הקודם' }}
          </button>
          <div class="font-anomaliaBold">
            התשובות של {{ filteredAndSortedStudents[studentIndex]?.fullName }}
          </div>
          <button
            class="text-xs"
            :disabled="studentIndex === filteredAndSortedStudents.length - 1"
            @click="goToStudent(Direction.NEXT)">
            {{ 'מעבר לתלמיד הבא >' }}
          </button>
        </div>
        <BaseWidget v-if="stageData" :key="studentQuery">
          <template #title>
            {{ stageTitle }}
          </template>
          <template #default>
            <PageContent />
          </template>
        </BaseWidget>
      </div>
    </template>
    <template #right>
      <BaseWidget v-if="stageData?.agenda">
        <template #default>
          <div class="text-lg">{{ stageData.agenda.header }}</div>
          <ul class="list-disc pr-5">
            <li v-for="(item, index) in stageData.agenda.list" :key="index">
              {{ item }}
            </li>
          </ul>
        </template>
      </BaseWidget>
      <BaseWidget>
        <template #title>
          <div class="text-lg">נקודות שעלו במפגש</div>
        </template>
        <template #default>
          <div class="flex flex-col space-y-3">
            <textarea
              v-model="message"
              rows="10"
              maxlength="1000"
              class="overflow-hidden rounded-sm border-b h-100 text-dark w-full focus:ring-0 pr-5 pt-3 resize-none h-40 p-2 caret-primary"
              placeholder="המסקנות שלך כאן"></textarea>
            <base-button
              class="rounded-sm shadow-drop w-full h-10 bg-primary text-white font-simplerBold"
              :disabled="!message || loading"
              :loading="loading"
              @click="sendFeedback">
              <span v-if="!lastStudent" class="text-white">
                שליחה ומעבר לתלמיד הבא >
              </span>
              <span v-else class="text-white">שליחה</span>
            </base-button>
          </div>
        </template>
      </BaseWidget>
    </template>
  </MainLayout>
</template>

<script setup lang="ts">
import { computed, ref, h, watchEffect, ComputedRef } from 'vue';
import { useRoute, useRouter, RouteParams } from 'vue-router';
import { useArrayMap } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import swal from 'sweetalert';
import { fetchEnrollments, reviewSubmission } from '/@/app/services/projects';
import { fetchPages } from '/@/app/services/courses';
import { useAccountStore } from '/@/app/store/account';
import type { IStudent, IProjectEnrollment } from '/@/app/models/interfaces';
import { BaseWidget, BaseButton, renderBlock } from '@nwire/amit-design-system';
import MainLayout from '/@/views/layouts/MainLayout.vue';
import { sortStudents } from '/@/utils';

const accountStore = useAccountStore();
const route = useRoute();
const router = useRouter();

const {
  group,
  project,
  stage,
}: RouteParams | { group: any; project: any; stage: any } = route.params;
const studentQuery = useRouteQuery<any>('student');

enum Direction {
  PREV = -1,
  NEXT = 1,
}

const enrollments = ref<IProjectEnrollment[]>([]);
const stageData = ref();
const loading = ref(false);

const message = ref('');

const stageTitle = computed(() => {
  if (!stageData.value) {
    return '';
  }
  return `שלב ${stageData.value.lesson} - ${stageData.value.title}`;
});
const hasSubmission = (student, stage) => {
  const studentEnrollment = enrollments.value?.find(
    enrollment => enrollment.student === student,
  );
  const enrollmentSummaryStage = studentEnrollment?.summary?.find(
    item => item.stage.id === stage,
  );
  return enrollmentSummaryStage?.status !== 'in_progress';
};

const studentIndex = computed(() =>
  filteredAndSortedStudents.value.findIndex(
    student => student.id === studentQuery.value,
  ),
);

const lastStudent = computed(() => {
  return studentIndex.value === filteredAndSortedStudents.value.length - 1;
});

const filteredAndSortedStudents: ComputedRef<IStudent[]> = computed(() => {
  const students =
    accountStore.groups.find(group => group.id === route.params.group)
      ?.students || [];
  const filteredStudents = students.filter(student =>
    hasSubmission(student.id, route.params.stage),
  );
  return sortStudents(filteredStudents);
});

watchEffect(() => {
  const { onSuccess } = fetchEnrollments(project, group);
  onSuccess(({ data }) => {
    enrollments.value = data;
  });

  const { onSuccess: onStageFetched } = fetchPages(project, stage);
  onStageFetched(({ data }) => {
    stageData.value = data;
  });
});

const goToStudent = (direction: number) => {
  router.push({
    name: 'review-project',
    params: { group, project, stage },
    query: {
      student:
        filteredAndSortedStudents.value[studentIndex.value + direction].id,
    },
  });
};

const studentEnrollment: ComputedRef<IProjectEnrollment | undefined> = computed(
  () =>
    enrollments.value?.find(
      enrollment => enrollment.student === studentQuery.value,
    ),
);

const submissionRecords = computed(() => {
  return (
    studentEnrollment.value?.summary?.find(item => item.stage.id === stage)
      ?.records || []
  );
});

const getSubmissionAnswer = id => {
  return submissionRecords.value.find(record => record.question === id)?.answer;
};

const questionBlocks = computed(() => {
  return (
    stageData.value?.sections.flatMap(section =>
      section.pages.flatMap(page => {
        const pageHasQuestion = page.blocks.some(
          block => block.type === 'question',
        );
        return pageHasQuestion ? page.blocks : [];
      }),
    ) || []
  );
});

const PageContent = () => {
  const PageBlocks = useArrayMap(questionBlocks.value, (block: any) =>
    renderBlock({
      editable: false,
      block,
      state: getSubmissionAnswer(block.id) || [],
    }),
  );
  return h(
    'div',
    {
      class:
        'flex flex-col space-y-3 text-right px-5 pb-20 overflow-y-auto h-full w-full page-content',
    },
    PageBlocks.value,
  );
};

const sendFeedback = () => {
  let data: any = {
    stage,
    title: 'תגובה חדשה ממורה',
    content: message.value,
    reviewed_by: accountStore.user.id,
    student: studentQuery.value,
  };

  loading.value = true;
  const { onSuccess } = reviewSubmission(
    project,
    studentEnrollment.value?.id,
    data,
  );
  onSuccess(async () => {
    if (!lastStudent.value) {
      goToStudent(Direction.NEXT);
    }
    message.value = '';
    loading.value = false;
    swal({
      text: 'פידבק נשלח לתלמיד בהצלחה!',
      icon: 'success',
    });
  });
};
</script>

<style>
.page-content .am-block {
  @apply border-b p-5;
}
</style>

export default {
  root: ({ props, parent }) => ({
    class: [
      // Flex
      'inline-flex',
      'relative',
      { 'flex-col': props.showButtons && props.buttonLayout == 'vertical' },
      { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

      // Shape
      {
        'first:rounded-s-md rounded-none last:rounded-e-md':
          parent.instance.$name == 'InputGroup' && !props.showButtons,
      },
      {
        'border-0 border-y border-s last:border-e border-surface-300 dark:border-surface-700':
          parent.instance.$name == 'InputGroup' && !props.showButtons,
      },
      {
        'first:ms-0 -ms-px':
          parent.instance.$name == 'InputGroup' && !props.showButtons,
      },

      //Sizing
      { '!w-16': props.showButtons && props.buttonLayout == 'vertical' },
    ],
  }),
  input: {
    root: ({ parent, context }) => ({
      class: [
        'w-full',

        // Font
        'leading-none',

        // Display
        'flex flex-auto',

        //Text
        {
          'text-center':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        // Spacing
        'py-2.5 px-3',
        {
          'pl-6':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        'm-0',

        // Shape
        'rounded-4',
        {
          'rounded-s-none rounded-r-none':
            parent.props.showButtons &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'rounded-none':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        {
          'border-0':
            parent.instance.$parentInstance?.$name == 'InputGroup' &&
            !parent.props.showButtons,
        },

        // Colors
        'text-surface-800 dark:text-white/80',
        'placeholder:text-secondary-450',
        'border',
        // { 'border-surface-300 dark:border-surface-700': !parent.props.invalid },

        {
          'focus:ring-1 focus:ring-primary focus:border-primary focus:z-10 focus:me-[1px]':
            !parent.props.invalid,
        },

        // Invalid State
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500 dark:border-red-400': parent.props.invalid },

        {
          'bg-surface-200 dark:bg-surface-700 select-none pointer-events-none cursor-default':
            context.disabled,
        },

        // Filled State *for FloatLabel
        {
          filled:
            parent.instance?.$parentInstance?.$name == 'FloatLabel' &&
            parent.state.d_modelValue !== null,
        },

        //Position
        {
          'order-2':
            parent.props.buttonLayout == 'horizontal' ||
            parent.props.buttonLayout == 'vertical',
        },
      ],
    }),
  },
  buttongroup: ({ props }) => ({
    class: [
      // Flex
      'flex',
      'flex-col',
      {
        'absolute top-px left-px h-[calc(100%-10px)] z-20':
          props.showButtons && props.buttonLayout == 'stacked',
      },
    ],
  }),

  incrementbutton: {
    root: ({ parent }) => ({
      class: [
        // Display
        'flex flex-auto',

        // Alignment
        'items-center',
        'justify-center',
        'text-center align-bottom',

        //Position
        'relative',
        {
          'order-3':
            parent.props.showButtons &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'order-1':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        //Color
        'text-secondary-800',
        'bg-transparent',
        'border border-secondary-300',
        {
          'border-0':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'border-s-0':
            parent.props.showButtons &&
            parent.props.buttonLayout !== 'stacked' &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'border-b-0':
            parent.props.showButtons &&
            parent.props.buttonLayout !== 'stacked' &&
            parent.props.buttonLayout == 'vertical',
        },

        // Sizing
        'w-[1rem]',
        {
          'px-4 py-3':
            parent.props.showButtons && parent.props.buttonLayout !== 'stacked',
        },
        {
          'p-0 ml-2 mt-1.5':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'w-full':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        // Shape
        'rounded-4',
        {
          'rounded-md':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'rounded-bs-none rounded-ts-none':
            parent.props.showButtons &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'rounded-bl-none rounded-br-none':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        //States
        'hover:bg-surface-100 dark:hover:bg-[rgba(255,255,255,0.03)]',

        //Misc
        'cursor-pointer overflow-hidden select-none',
      ],
    }),
    label: {
      class: 'h-0 w-0',
    },
  },
  decrementbutton: {
    root: ({ parent }) => ({
      class: [
        // Display
        'flex flex-auto',

        // Alignment
        'items-center',
        'justify-center',
        'text-center align-bottom',

        //Position
        'relative',
        {
          'order-1':
            parent.props.showButtons &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'order-3':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        //Color
        'text-surface-800 dark:text-surface-0',
        'bg-transparent',
        {
          'dark:bg-surface-900':
            parent.props.showButtons && parent.props.buttonLayout !== 'stacked',
        },
        'border border-surface-300 dark:border-surface-700',
        {
          'border-0':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'border-e-0':
            parent.props.showButtons &&
            parent.props.buttonLayout !== 'stacked' &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'border-t-0':
            parent.props.showButtons &&
            parent.props.buttonLayout !== 'stacked' &&
            parent.props.buttonLayout == 'vertical',
        },

        // Sizing
        'w-[1rem]',
        {
          'px-4 py-3':
            parent.props.showButtons && parent.props.buttonLayout !== 'stacked',
        },
        {
          'p-0 ml-2':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'w-full':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        // Shape
        'rounded-4',
        {
          'rounded-te-none rounded-ts-none rounded-bs-none':
            parent.props.showButtons && parent.props.buttonLayout == 'stacked',
        },
        {
          'rounded-te-none rounded-be-none ':
            parent.props.showButtons &&
            parent.props.buttonLayout == 'horizontal',
        },
        {
          'rounded-te-none rounded-ts-none ':
            parent.props.showButtons && parent.props.buttonLayout == 'vertical',
        },

        //States
        'hover:bg-surface-100 dark:hover:bg-[rgba(255,255,255,0.03)]',

        //Misc
        'cursor-pointer overflow-hidden select-none',
      ],
    }),
    label: {
      class: 'h-0 w-0',
    },
  },
};

export default {
  root: ({ props, context, parent }) => ({
    class: [
      // Font
      'leading-none',

      // Flex
      { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },

      // Spacing
      'm-0',
      {
        'py-3 px-3.5': props.size == 'large',
        'py-1.5 px-2': props.size == 'small',
        'py-3 px-4': props.size == null,
      },

      // Shape
      { 'rounded-4': parent.instance.$name !== 'InputGroup' },
      {
        'first:rounded-s-md rounded-none last:rounded-e-md':
          parent.instance.$name == 'InputGroup',
      },
      {
        'border-0 border-y border-s last:border-e':
          parent.instance.$name == 'InputGroup',
      },
      {
        'first:ml-0 -ml-px':
          parent.instance.$name == 'InputGroup' && !props.showButtons,
      },

      // Colors
      'text-secondary text-sm',
      'placeholder:text-placeholder',
      { 'bg-white': !context.disabled },
      'border',
      { 'border-secondary-250': !props.invalid },

      // Invalid State
      'invalid:focus:ring-error',
      'invalid:hover:border-error',
      { 'border-red-500 text-red-600': props.invalid },

      // States
      {
        // 'hover:border-surface-400': !context.disabled && !props.invalid,
        'focus:outline-none focus:outline-offset-0  focus:z-10 focus:me-[1px]':
          !context.disabled,
        '!text-disabled bg-neutral-level-1 select-none pointer-events-none cursor-default':
          context.disabled,
      },

      // Filled State *for FloatLabel
      { filled: parent.instance?.$name == 'FloatLabel' && context.filled },

      // Misc
      'appearance-none',
      'transition-colors duration-200',
    ],
  }),
};

<template>
  <BaseModal
    v-show="isSetGroupModalOpened"
    @close="isSetGroupModalOpened = false">
    <template #body>
      <div class="flex flex-col gap-4 text-center">
        <span class="text-secondary-900 text-lg text-center mx-auto">
          {{
            $t('survey_page.set_student_group', {
              name: studentToUpdate.student,
            })
          }}
        </span>
        <div class="flex justify-end items-center">
          <PVButton
            severity="neutral"
            text
            label="ביטול"
            @click="isSetGroupModalOpened = false"/>
          <PVButton label="אישור" @click="handleSetEnrollmentGroup" />
        </div>
      </div>
    </template>
  </BaseModal>
  <BaseModal
    v-show="isSelectPublishScoresOpened"
    size="lg"
    header-padding="py-2 px-4"
    body-padding="p-4"
    padding="py-3 px-4"
    @close="isSelectPublishScoresOpened = false">
    <template #header>
      <span class="text-secondary-800 font-simplerBold text-sm">
        פרסום ציונים לתלמידים שלך
      </span>
    </template>
    <template #body>
      <div class="flex flex-col gap-4 h-85">
        <div class="flex flex-col gap-1">
          <span class="text-secondary-900 text-sm">
            יש לבחור את הציונים שברצונך לפרסם.
          </span>
          <span class="text-orange-500 text-sm">
            שימו לב! תלמידים שלא השלימו את השיעור לא יקבלו עבורו ציון
          </span>
        </div>
        <MultiSelect
          v-model="selectedPublisScores"
          display="chip"
          variant="filled"
          :options="publishOptions"
          option-label="name"
          placeholder="בחירת ציונים"/>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end items-center">
        <PVButton
          severity="neutral"
          text
          label="ביטול"
          @click="isSelectPublishScoresOpened = false"/>
        <PVButton
          label="פרסום ציונים"
          class="h-8"
          :loading="publishScoresLoading"
          :disabled="!selectedPublisScores.length"
          @click="handlePublishScores(true)"/>
      </div>
    </template>
  </BaseModal>
  <BaseModal
    v-show="isPublishScoresOpened"
    size="md"
    header-padding="py-2 px-4"
    body-padding="p-4"
    padding="py-3 px-4"
    @close="isPublishScoresOpened = false">
    <template #header>
      <span class="text-secondary-800 font-simplerBold text-sm">
        פרסום ציונים
      </span>
    </template>
    <template #body>
      <div class="flex flex-col gap-1 text-secondary-900 text-sm">
        <span>יש תרגילים בקורס זה שטרם נבדקו</span>
        <span>
          האם האם אתה בטוח שברצונך לפרסם ציון לתלמידים לפני סיום בדיקת התרגילים?
        </span>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <PVButton
          label="פרסום ציונים"
          class="h-8"
          :loading="publishScoresLoading"
          @click="handlePublishScores(false)"/>
      </div>
    </template>
  </BaseModal>
  <MessageDialog
    v-model="isPublishSuccessOpened"
    :icon="ThumbUpIcon"
    message="הציונים נשלחו לתלמידים"/>

  <div ref="tableWrapper">
    <BaseTable
      v-if="mappedStudents.length"
      :style="{ height: tableHeight }"
      :headers="tableHeaders"
      :items="mappedStudents"
      :allow-download="true"
      :course-name="courseName"
      :paginator="false"
      :show-index="false"
      include-search
      label="תלמידים"
      :show-gridlines="true">
      <template #additionalActions>
        <PVButton
          label="הוספת מרכיבי הקורס"
          severity="neutral"
          plain
          class="!py-2 !m-0"
          @click="
            () => {
              weightManagement._originalCriteria = null;
              weightManagement._updatedCriteria = {
                id: '',
                title: '',
                weight: 0,
              };
              isCriteriaModalOpened = true;
            }
          ">
          <template #icon>
            <Plus :size="14" />
          </template>
        </PVButton>
        <PVButton
          class="h-8"
          label="פרסום ציונים"
          :disabled="noScoresToPublish"
          @click="openSelectPublishScoresModal">
          <template #icon>
            <SendIcon :size="12" />
          </template>
        </PVButton>
      </template>
      <template #columnheader="{ header }">
        <div
          class="flex flex-col items-start p-3 pb-1.5 w-full h-full cursor-pointer"
          @click="
            () =>
              header.lesson?.id &&
              $router.push({
                name: 'review-submissions',
                params: { group, course, lesson: header.lesson.id },
                query: { review_per: 'exercise' },
              })
          ">
          <div
            class="flex justify-between gap-1 w-full"
            :class="[
              header.locked ? 'text-secondary-450' : 'text-secondary-900',
            ]">
            <div class="flex items-center gap-1 truncate">
              <span v-if="header.position" class="font-simplerLight text-sm">
                {{ header.position }}
              </span>
              <span
                v-tooltip.top="header.title"
                class="font-simplerLight text-sm truncate"
                :dir="ltr ? 'ltr' : 'rtl'">
                {{ header.title }}
              </span>
            </div>
            <div
              v-if="header.hasScoresToPublish"
              v-tooltip.top="'קיימים ציונים שטרם פורסמו'"
              class="flex justify-center items-center rounded-full bg-primary-200 p-1.5">
              <AlertCircleIcon class="stroke-primary-600" :size="14" />
            </div>
            <div
              v-if="header.key.includes('criterion')"
              v-tooltip.top="'פעולות נוספות'"
              class="flex p-0.5 rounded-4 hover:bg-[#EAEEF6] cursor-pointer"
              @click.stop="e => weightManagement.toggleMenu(e, header)">
              <EllipsisVertical :size="13" />
            </div>
          </div>
          <div
            v-if="header.key === 'student'"
            class="text-secondary-800 text-xs font-simplerLight">
            ({{ mappedStudents.length }})
          </div>
          <div
            v-else-if="header.weight >= 0"
            v-tooltip.top="`${$t('course_page.component_weight')}`"
            class="flex items-center w-fit gap-1.5 font-simplerLight text-secondary-900 text-xs hover:bg-secondary-100 rounded-2 py-0.5 px-1.5 -mr-1.5"
            @click.stop="
              event => weightManagement.toggleWeightPanel(event, header)
            ">
            {{ header.weight }}%
            <ArrowDownIcon />
          </div>
        </div>
      </template>

      <template #columnbody="{ header, item }">
        <div
          v-if="header.key === 'student'"
          class="flex items-center gap-1 px-2"
          :class="{ 'opacity-50': !item.enrollment }"
          @click="openSetEnrollmentGroupModal(item)">
          <img
            class="rounded-full w-4 h-4"
            :src="item.avatar || defaultAvatarImage"
            :alt="item.fullName"/>
          <span v-tooltip.top="item.student" class="truncate">
            {{ item.student }}
          </span>
        </div>
        <div v-else-if="!item.enrollment" class="w-full h-full bg-white"></div>
        <div v-else-if="header.key === 'progress'">
          <ProgressInfo
            :progress="Math.round(item.totalProgress)"
            :items-progress="getLessonsProgress(item.enrollment)"
            :border="false"/>
        </div>
        <div
          v-else-if="header.locked"
          class="w-full h-full flex items-center justify-center">
          <span class="text-secondary-450">-</span>
        </div>

        <ScoreInfo
          v-else
          :id="`${header.id ?? 'course-score'}-${item.projectEnrollment?.project ? item.projectEnrollment?.id : item.enrollment.id}`"
          :allow-check-click="['lesson'].includes(header.key.split('-')[0])"
          :allow-edit-score="
            ['assessment', 'criterion'].includes(header.key.split('-')[0])
          "
          :score="item.scores[header.key]?.score"
          :alert="item.scores[header.key]?.toBePublished"
          @update-score="
            ({ score }) => updateScore(score, item, header.key, header.id)
          "
          @on-check-click="
            () =>
              $router.push({
                name: 'review-submissions',
                params: { group, course, lesson: header.lesson.id },
                query: { review_per: 'student', student: item.id },
              })
          "/>
      </template>
    </BaseTable>
    <div
      v-else
      class="flex items-center justify-center font-anomaliaLight text-lg pt-5">
      {{ $t('course_page.no_mentorship_teachers') }}
    </div>
  </div>
  <CriteriaActions @edit="isCriteriaModalOpened = true" />
  <CriteriaModal v-model:is-open="isCriteriaModalOpened" />
  <WeightManagementCourseAction
    :is-loading="weightManagement.isLoading"
    @on-manual="() => (weightManagement.isWeightManagementDialogOpen = true)"
    @on-automatic="() => weightManagement.handleSaveAutomaticCalculation()"/>
  <WeightsManagmentModal
    v-if="weightManagement.isWeightManagementDialogOpen"
    @on-save="isWeightUpdateSuccessOpen = true"
    @on-cancel="() => initWeightManagement()"/>
  <MessageDialog
    v-model="isWeightUpdateSuccessOpen"
    :icon="CriteriaAdded"
    message="מרכיבי הקורס עודכנו בהצלחה!"/>
  <OverlayPanel :ref="el => (weightManagement.weightPanel = el)">
    <div class="p-3">
      <WeightField
        :weight="weightManagement._updatedCriteria.weight"
        @update-weight="
          newWeight => {
            weightManagement.updateCriteriaWeight(newWeight);
            weightManagement._updatedCriteria.weight = newWeight;
          }
        "
        @on-blur="
          () => {
            if (
              weightManagement._originalCriteria?.weight !==
                weightManagement._updatedCriteria?.weight &&
              !weightManagement.isWeightManagementDialogOpen
            ) {
              weightManagement.isSelectCourseOfActionOpen = true;
            }
          }
        "/>
    </div>
  </OverlayPanel>
  <PVToast
    position="bottom-center"
    group="headless"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      root: ({ props }) => ({
        class: ['!p-0'],
      }),
      container: ({ props }) => ({
        class: ['bg-red-500', 'text-white', '!px-3 !py-2'],
      }),
      content: ({ props }) => ({
        class: ['!gap-0', '!p-0'],
      }),
      icon: ({ props }) => ({
        class: ['w-0', '!ml-0'],
      }),
      detail: ({ props }) => ({
        class: ['!mt-0'],
      }),
      summary: ({ props }) => ({
        class: ['!text-white', '!text-[16px]'],
      }),
    }"/>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  PropType,
  onMounted,
  nextTick,
  watch,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import type { IStudent } from '/@/app/models/interfaces';
import ScoreInfo from '/@/views/playground/partials/ScoreInfo.vue';
import { useStudentsStore } from '/@/app/store/students';
import { setEnrollmentGroup } from '/@/app/services/groups';
import { defaultAvatarImage } from '@/utils/helpers';
import { ProgressInfo } from '@amit/components';
import { BaseModal } from '@nwire/amit-design-system';
import PVButton from 'primevue/button';
import { useStorage } from '@vueuse/core';
import ArrowDownIcon from '/@/assets/arrowDown2.svg?component';
import {
  Plus,
  EllipsisVertical,
  Send as SendIcon,
  AlertCircleIcon,
} from 'lucide-vue-next';
import CriteriaModal from './CriteriaModal.vue';
import WeightsManagmentModal from './WeightsManagmentModal.vue';
import { useWeightManagement } from './store/WeightManagement';
import CriteriaAdded from '/@/assets/criteriaAdded.svg?component';
import ThumbUpIcon from '/@/assets/thumbUp.svg?component';
import WeightManagementCourseAction from './WeightManagementCourseAction.vue';
import CriteriaActions from './CriteriaActions.vue';
import MessageDialog from '/@/components/MessageDialog.vue';
import OverlayPanel from 'primevue/overlaypanel';
import WeightField from './WeightField.vue';
import PVToast from 'primevue/toast';
import { setCriteriaScore } from '/@/app/services/students';
import { setProjectScore } from '/@/app/services/projects';
import { publishScores } from '/@/app/services/modules';
import MultiSelect from 'primevue/multiselect';

const weightManagement = useWeightManagement();

const props = defineProps({
  courseModule: {
    type: Object,
    required: true,
  },
  students: {
    type: Array as PropType<IStudent[]>,
    default: () => [],
  },
});

const studentsStore = useStudentsStore();
const localStatePermissions = useStorage('permissions', {});

const isWeightUpdateSuccessOpen = ref(false);

const studentToUpdate = ref({});
const isSetGroupModalOpened = ref(false);
const isCriteriaModalOpened = ref(false);
const publishScoresLoading = ref(false);
const isSelectPublishScoresOpened = ref(false);
const isPublishScoresOpened = ref(false);
const isPublishSuccessOpened = ref(false);
const selectedPublisScores = ref([]);

const group = computed(() => props.courseModule.group);
const course = computed(() => props.courseModule.course.id);
const courseName = computed(() => props.courseModule.course.caption);
const ltr = computed(() => props.courseModule.course.locale === 'en');
const summary = computed(() => props.courseModule.summary ?? []);
const criteria = computed(() => props.courseModule.criteria ?? []);
const assessments = computed(() => props.courseModule.assessments ?? []);

const noScoresToPublish = computed(() =>
  tableHeaders.value.every(item => !item.hasScoresToPublish),
);

const publishOptions = computed(() => {
  const items = summary.value
    .filter(({ locked, lesson }) => {
      const { hasScoresToPublish } =
        tableHeaders.value.find(item => item.id === lesson.id) ?? {};
      return !locked && hasScoresToPublish;
    })
    .map(item => ({
      name: item.lesson.title,
      id: item.lesson.id,
      type: 'lesson',
    }));

  const { hasScoresToPublish } =
    tableHeaders.value.find(item => item.key === 'score') ?? {};
  if (hasScoresToPublish) {
    items.push({ name: 'ציון קורס', id: course.value, type: 'course' });
  }

  return items;
});

const tableHeaders = computed(() => [
  {
    title: 'תלמידים',
    key: 'student',
    sortable: true,
    frozen: true,
    style: 'min-width: 8em; max-width: 8em; height: 45px;',
  },
  {
    title: 'התקדמות בקורס',
    key: 'progress',
    style: 'min-width: 8.5em; max-width: 8.5em',
  },
  ...summary.value.map((item, index) => ({
    id: item.lesson.id,
    title: item.lesson.title,
    position: `${index + 1}.`,
    key: `lesson-${item.lesson.id}`,
    lesson: item.lesson,
    locked: item.locked,
    weight: item.weight,
    hasScoresToPublish:
      !item.locked && getScoresToPublishExist(`lesson-${item.lesson.id}`),
    style: 'min-width: 9em; max-width: 9em',
  })),
  ...assessments.value.map(item => ({
    id: item.reference,
    reference: item.reference,
    title: 'פרויקט מעשי',
    key: `assessment-${item.reference}`,
    type: 'project',
    weight: item.weight,
    style: 'min-width: 9em; max-width: 9em',
  })),
  ...criteria.value.map(item => ({
    id: item.id,
    title: item.name,
    key: `criterion-${item.id}`,
    weight: item.weight,
    style: 'min-width: 9em; max-width: 9em',
  })),
  {
    title: 'ציון הקורס',
    key: 'score',
    style: 'width: 120px',
    frozen: true,
    hasScoresToPublish: getScoresToPublishExist('score'),
  },
]);

const getStudentScores = (enrollment, projectEnrollment) => {
  if (!enrollment) {
    return {};
  }

  const scores = {};

  enrollment.summary?.forEach(item => {
    const score = item.progress !== 100 ? null : item.score;
    const toBePublished =
      typeof score === 'number' && score !== item.published_score;
    scores[`lesson-${item.lesson.id}`] = { score, toBePublished };
  });

  enrollment.criteria?.forEach(item => {
    const { score } = item;
    scores[`criterion-${item.reference}`] = { score };
  });

  if (projectEnrollment) {
    const { project, total_score } = projectEnrollment;
    scores[`assessment-${project}`] = { score: total_score };
  }

  const { total_score, published_score } = enrollment;
  scores['score'] = {
    score: total_score,
    toBePublished:
      typeof total_score === 'number' && total_score !== published_score,
  };

  return scores;
};

const mappedStudents = computed(() => {
  return props.students.map(student => {
    const enrollment = studentsStore.enrollments.find(
      (enrollment: any) => enrollment.student.id === student.id,
    );
    const projectEnrollment = studentsStore.projectEnrollments.find(
      (enrollment: any) => enrollment.student === student.id,
    );

    return {
      id: student.id,
      student: student.fullName,
      avatar: student.avatar,
      totalProgress: enrollment?.total_progress,
      scores: getStudentScores(enrollment, projectEnrollment),
      enrollment,
      projectEnrollment,
    };
  });
});

const updateScore = (score, item, key, id) => {
  const type = key.split('-')[0];

  const enrollmentId = item.enrollment.id;
  const projectEnrollmentId = item.projectEnrollment?.id;
  const courseId = course.value;
  const groupId = group.value;

  const updateScoreByType = {
    criterion: () => {
      const { onSuccess } = setCriteriaScore(enrollmentId, {
        criterion: id,
        score: Number(score),
      });
      onSuccess(() => {
        studentsStore.fetchEnrollments({
          group: groupId,
          course: courseId,
        });
      });
    },
    assessment: () => {
      const { onSuccess } = setProjectScore({
        project: id,
        enrollment: projectEnrollmentId,
        course: courseId,
        score: Number(score),
      });
      onSuccess(() => {
        studentsStore.fetchProjectEnrollments({
          group: groupId,
          project: id,
        });
        studentsStore.fetchEnrollments({
          group: groupId,
          course: courseId,
        });
      });
    },
  };

  updateScoreByType[type]?.();
};

const getScoresToPublishExist = key => {
  return mappedStudents.value.some(student => {
    return student.scores[key]?.toBePublished;
  });
};

const getLessonsProgress = enrollment => {
  return enrollment.summary.map(item => item.progress);
};

const openSetEnrollmentGroupModal = student => {
  if (student.enrollment || !localStatePermissions.value.move_enrollment) {
    return;
  }
  studentToUpdate.value = student;
  isSetGroupModalOpened.value = true;
};

const handleSetEnrollmentGroup = () => {
  const { onSuccess } = setEnrollmentGroup({
    course: course.value,
    group: group.value,
    user: studentToUpdate.value.id,
  });
  onSuccess(() => {
    isSetGroupModalOpened.value = false;
  });
};

const initWeightManagement = () => {
  weightManagement.init({
    courseModuleId: props.courseModule.id,
    course: course.value,
    group: group.value,
    data: [
      ...summary.value.map(item => ({
        id: item.lesson.id,
        weight: item.weight,
        title: item.lesson.title,
        type: 'lesson',
      })),
      ...criteria.value.map(item => ({
        id: item.id,
        weight: item.weight,
        title: item.name,
        type: 'criteria',
      })),
      ...assessments.value.map(item => ({
        id: item.id ?? item.reference,
        reference: item.reference,
        weight: item.weight,
        title: 'פרויקט מעשי',
        type: 'assessment',
      })),
    ],
  });
};

const tableHeight = ref('0px');
const tableWrapper = ref<HTMLElement | null>(null);

const calculateTableHeight = () => {
  if (!tableWrapper.value) return;

  const viewportHeight = window.innerHeight;
  const tableTop = tableWrapper.value.getBoundingClientRect().top;
  const maxHeight = viewportHeight - tableTop;
  const rowHeight = 45;
  const headerHeight = 150;
  const calculatedHeight =
    mappedStudents.value.length * rowHeight + headerHeight;
  tableHeight.value = `${Math.min(maxHeight, calculatedHeight)}px`;
};

const openSelectPublishScoresModal = () => {
  selectedPublisScores.value = [];
  isSelectPublishScoresOpened.value = true;
};

const handlePublishScores = (dry_run = true) => {
  publishScoresLoading.value = true;

  const data = {
    dry_run,
    course: selectedPublisScores.value.some(item => item.type === 'course'),
    lessons: selectedPublisScores.value
      .filter(item => item.type === 'lesson')
      .map(({ id }) => id),
  };

  const { onSuccess, onError } = publishScores(props.courseModule.id, data);
  onSuccess(() => {
    studentsStore.fetchEnrollments({
      group: group.value,
      course: course.value,
    });

    publishScoresLoading.value = false;
    isSelectPublishScoresOpened.value = false;
    isPublishScoresOpened.value = false;

    isPublishSuccessOpened.value = true;
  });
  onError(error => {
    publishScoresLoading.value = false;
    isSelectPublishScoresOpened.value = false;

    if (error.status === 409) {
      isPublishScoresOpened.value = true;
    }
  });
};

onBeforeMount(() => {
  window.addEventListener('resize', calculateTableHeight);
});

onMounted(() => {
  nextTick(() => {
    initWeightManagement();
    calculateTableHeight();
  });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', calculateTableHeight);
});

watch(
  assessments,
  () => {
    assessments.value.forEach(assessment => {
      studentsStore.fetchProjectEnrollments({
        group: group.value,
        project: assessment.reference,
      });
    });
  },
  { immediate: true },
);

watch(mappedStudents, () => {
  nextTick(calculateTableHeight);
});

watch([criteria, assessments, summary], () => {
  nextTick(() => {
    initWeightManagement();
  });
});
</script>

<template>
  <div class="flex items-center justify-between">
    <PVSkeleton
      v-if="loading"
      height="1.5rem"
      width="10rem"
      class="mb-2"></PVSkeleton>
    <h1 v-else class="font-simplerBold">
      {{ t('course_settings.course_structure') }}
    </h1>

    <div class="flex gap-2">
      <PVSkeleton v-if="loading" width="10rem" height="3rem"></PVSkeleton>
      <div
        v-else
        class="flex items-center gap-2 px-2.5 py-2 bg-white w-40 rounded-md relative"
        @mouseleave="isIntroBtnHovered = false"
        @mouseover="isIntroBtnHovered = true">
        <div
          class="h-8 w-8 flex items-center justify-center rounded-md bg-green-spring-100 opacity-50">
          <Play class="stroke-secondary-900" :size="14" />
        </div>
        <p class="opacity-50">{{ $t('course_settings.upload_files') }}</p>
        <span
          class="-rotate-12 absolute -top-2 left-0 shake-badge font-simplerBold text-xs text-pink-500"
          :class="{ 'animate-shake': isIntroBtnHovered }">
          בקרוב
        </span>
      </div>
    </div>
  </div>

  <div v-if="loading">
    <PVSkeleton height="2rem" class="mb-2"></PVSkeleton>
    <PVSkeleton height="4rem"></PVSkeleton>
  </div>

  <div v-else>
    <AddUnit
      v-if="canEdit"
      class="hover:scale-[1.05] transition duration-100"
      @click="addElementAtIndex(0, generateUnit())"/>
    <VueDraggable
      id="list-items"
      class="w-full flex flex-col gap-2"
      :list="outline"
      :group="{ name: `lessons` }"
      item-key="id"
      :animation="500"
      :delay="0"
      :disabled="!canEdit"
      :force-fallback="true"
      :scroll="true"
      :scroll-sensitivity="45"
      :scroll-speed="5"
      handle=".drag-handler"
      :move="checkMove"
      @mouseover.self="hoverOver"
      @mouseleave="hoveredIndex = null"
      @start="onDragStart"
      @end="onDragEnd">
      >

      <template #item="{ element, index }">
        <div
          :class="{
            'non-drag-element transition duration-100 opacity-100 visible':
              element.type == 'new_lesson',
          }">
          <div v-if="element.type === 'new_lesson'">
            <AddLesson
              @click="
                addElementAtIndex(
                  index,
                  generateLesson(
                    getPreviousElementOfType(outline, index, 'unit'),
                  ),
                )
              "/>
          </div>

          <UnitItem
            v-else-if="element.type == 'unit'"
            :element="element"
            :is-hovered="currentIndex === index"
            :is-dragged="draggedIndex == index + 1"
            :can-remove-unit="canRemoveUnit(index)"
            :can-edit="canEdit"
            @update-models="updateModels"
            @remove-unit="removeElementAtIndex(index)"
            @mouseleave="currentIndex = null"
            @mouseover="currentIndex = index"/>

          <LessonItem
            v-else
            :element="element"
            :can-remove-lesson="canRemoveLesson()"
            :show-add-unit="
              canEdit &&
              hoveredIndex === index &&
              getNextElement(outline, index)?.type !== 'unit'
            "
            :can-edit="canEdit"
            @leave="hoveredIndex = null"
            @remove-lesson="removeElementAtIndex(index)"
            @add-unit="addElementAtIndex(index + 1, generateUnit())"
            @update-models="updateModels"/>
        </div>
      </template>
    </VueDraggable>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { generateObjectId } from '/@/utils';
import _ from 'lodash';
import VueDraggable from 'vuedraggable';
import { PropType } from 'vue/dist/vue';
import { OutlineItem } from '/@/app/types/interfaces';
import { useVModel } from '@vueuse/core';
import PVSkeleton from 'primevue/skeleton';
import { useI18n } from 'vue-i18n';
import UnitItem from '/@/views/Lobby/CourseSettings/UnitItem.vue';
import LessonItem from '/@/views/Lobby/CourseSettings/LessonItem.vue';
import AddLesson from '/@/views/Lobby/CourseSettings/partials/AddLesson.vue';
import AddUnit from '/@/views/Lobby/CourseSettings/partials/AddUnit.vue';
import { Play } from 'lucide-vue-next';

const { t } = useI18n();

const isIntroBtnHovered = ref(false);
const hoveredIndex = ref(null);
const currentIndex = ref(null);
const draggedIndex = ref(null);
const props = defineProps({
  units: {
    type: Array as PropType<OutlineItem[]>,
    default: () => [],
  },
  lessons: {
    type: Array as PropType<OutlineItem[]>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const outline = ref<Partial<OutlineItem>[]>([]);
const units = useVModel(props, 'units');
const lessons = useVModel(props, 'lessons');
watch(
  () => [props.units, props.lessons],
  () => {
    outline.value = combineUnitsAndLessons(props.units, props.lessons);
  },
);

const combineUnitsAndLessons = (units, lessons) => {
  const groupedLessons = _.groupBy(lessons, 'unit');

  const combined = _.flatMap(units, unit => {
    const unitLessons = (groupedLessons[unit._id] || []).map(lesson => ({
      ...lesson,
      type: 'lesson',
    }));

    const unitEntry = {
      ...unit,
      type: 'unit',
      lessonsOnUnit: unitLessons.length,
    };

    const result = [unitEntry, ...unitLessons];

    if (props.canEdit) {
      result.push({ type: 'new_lesson' });
    }

    return result;
  });

  return combined;
};

const onDragStart = event => {
  const lessons = document.getElementsByClassName('non-drag-element');

  Array.from(lessons).forEach(lesson => {
    lesson.classList.add('transition', 'duration-100', 'opacity-50');
  });

  draggedIndex.value = event.oldIndex;
  hoveredIndex.value = null;
};
const onDragEnd = () => {
  const lessons = document.getElementsByClassName('non-drag-element');

  // Convert HTMLCollection to an array and add the 'hidden' class
  Array.from(lessons).forEach(lesson => {
    lesson.classList.remove('invisible');
    lesson.classList.remove('opacity-50');

    setTimeout(() => {
      lesson.classList.remove('invisible');
    }, 150);
  });
  draggedIndex.value = null;
  hoveredIndex.value = null;
  checkFirstIndex();
  updateModels();
};

const updateModels = () => {
  units.value = outline.value.filter(o => o.type === 'unit');
  lessons.value = outline.value
    .filter(o => o.type === 'lesson')
    .map((l, i) => ({ ...l, lesson: i + 1 }));
};

const checkFirstIndex = () => {
  if (outline.value[0].type == 'lesson') {
    addElementAtIndex(0, generateUnit());
  }
  outline.value = updateLessonsWithUnit(outline.value);
};

const addElementAtIndex = (index, element) => {
  if (index === null || index === undefined) {
    outline.value.push(element);
  } else if (index >= 0 && index <= outline.value.length) {
    outline.value.splice(index, 0, element);
  }
  checkFirstIndex();
  updateModels();
};

const removeElementAtIndex = index => {
  if (index >= 0 && index < outline.value.length) {
    outline.value.splice(index, 1); // Remove at specified index
  }
  checkFirstIndex();
  updateModels();
};

const canRemoveUnit = index => {
  if (index == 0) {
    return !outline.value.filter(n => n.unit == outline.value[index]._id)
      .length;
  }
  return outline.value.filter(n => n.type == 'unit').length > 1;
};
const canRemoveLesson = () => {
  return outline.value.filter(n => n.type == 'lesson').length > 1;
};

const updateLessonsWithUnit = data => {
  let currentUnitId = null;

  return _.map(data, item => {
    if (item.type === 'unit') {
      currentUnitId = item._id;
    } else if (item.type === 'lesson') {
      item.unit = currentUnitId;
    }
    return item;
  });
};

const getPreviousElementOfType = (data, index, type) => {
  return (
    data
      .slice(0, index)
      .reverse()
      .find(item => item.type === type) || null
  );
};

const getNextElement = (data, index) => {
  return data[index + 1];
};
const generateUnit = () => ({
  _id: generateObjectId(),
  title: `${t('course_settings.new_unit')}`,
  type: 'unit',
});

const generateLesson = unit => ({
  id: generateObjectId(),
  title: `${t('course_settings.new_lesson')}`,
  type: 'lesson',
  unit: unit.id,
});

const hoverOver = event => {
  if (draggedIndex.value !== null || hoveredIndex.value !== null) return;
  const list = document.getElementById('list-items');
  const listItems = list?.children;

  const hoverY = event.clientY; // Y position of the mouse
  let closestIndex = null;

  listItems.forEach((element, index) => {
    const rect = element.getBoundingClientRect();
    const distance = hoverY - Math.floor(rect.bottom);
    if (distance >= 0) {
      closestIndex = index;
      if (outline.value[closestIndex].type === 'lesson') {
        hoveredIndex.value = index;
      } else {
        hoveredIndex.value = null;
      }
    }
  });
};

onMounted(() => {
  outline.value = combineUnitsAndLessons(props.units, props.lessons);
});

const findIndexes = (list, type = 'new_lesson') => {
  return list
    .map((element, index) => (element.type === type ? index : -1))
    .filter(index => index !== -1);
};

const checkMove = evt => {
  const dropIndex = evt.related
    ? Array.from(evt.to.children).indexOf(evt.related)
    : evt.to.children.length;

  const draggedIndex = evt.draggedContext.index;
  const isMovingDown = dropIndex > draggedIndex;

  let disallowedIndexes = findIndexes(outline.value);

  if (!isMovingDown) {
    disallowedIndexes = disallowedIndexes.map(n => n + 1);

    if (evt.draggedContext.element.type === 'unit') {
      const unitIndexes = findIndexes(outline.value); // Fetch specific indexes for 'unit'
      disallowedIndexes = [...disallowedIndexes, ...unitIndexes];
    }
  }

  const uniqueDisallowedIndexes = new Set(disallowedIndexes);

  return !uniqueDisallowedIndexes.has(dropIndex);
};
</script>

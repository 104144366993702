<template>
  <div class="flex items-center relative">
    <PVIconField icon-position="right" :class="{ 'w-full': full }">
      <PVInputIcon>
        <Search :size="16" />
      </PVInputIcon>
      <PVInputText
        v-bind="$attrs"
        class="pl-7 !py-2"
        :class="{
          '!rounded-full': rounded,
          '!w-full': full,
          '!w-[13rem]': !full,
        }"
        :pt="preset(style)"
        :pt-options="{ mergeSections: false, mergeProps: true }"/>
    </PVIconField>
    <PVButton
      v-if="$attrs.modelValue"
      text
      rounded
      :pt="presetXButton"
      :pt-options="{ mergeSections: false, mergeProps: true }"
      @click="$emit('resetSearch')">
      <template #icon>
        <X :size="14" />
      </template>
    </PVButton>
  </div>
</template>

<script setup lang="ts">
import { Search } from 'lucide-vue-next';
import { preset } from './InputPreset';
import type { InputStyle } from './InputPreset';
import type { PropType } from 'vue';
import { X } from 'lucide-vue-next';

import PVButton from 'primevue/button';
import PVIconField from 'primevue/iconfield';
import PVInputIcon from 'primevue/inputicon';
import PVInputText from 'primevue/inputtext';

defineEmits(['resetSearch']);
const props = defineProps({
  rounded: Boolean,
  full: {
    type: Boolean,
    default: false,
  },
  style: {
    type: String as PropType<InputStyle>,
    default: 'white',
  },
});

const presetXButton = {
  root: () => ({
    class: [
      '!absolute flex items-center',
      'left-2',
      'text-secondary',

      {
        'bg-neutral-level-2':
          props.style === 'white' ||
          props.style == null ||
          props.style === 'offwhite',
        'bg-neutral-level-4': props.style === 'neutral',
      },

      '!h-4 !w-4 !p-0',
    ],
  }),
};
</script>

<template>
  <PVSidebar
    v-model:visible="visible"
    class="!w-[30%]"
    :header="
      $t(
        isSurvey
          ? 'course_page.distribute_survey_to_your_students'
          : 'course_page.distribute_course_to_your_students',
      )
    ">
    <div class="flex flex-col h-full gap-3 px-5 py-4">
      <div class="flex gap-2">
        <SearchInput
          v-model="search"
          class="flex flex-1"
          :placeholder="$t('course_page.search_groups')"
          :style="'neutral'"
          full
          @reset-search="search = ''"/>
        <PVButton
          :label="$t('course_page.new_group')"
          outlined
          class="h-full !m-0"
          @click="
            () => {
              groupManager?.open({
                ownerId: accountStore.user.id,
                schoolId: accountStore.user.school.id,
              });
            }
          "/>
      </div>
      <label v-if="search" class="!text-secondary-975 text-xs">
        {{ `${$t('course_page.results_for')} "${search}"` }}
      </label>
      <div v-if="filteredGroups.length" class="flex flex-1 h-full">
        <ScrollableContainer>
          <PVAccordion
            v-model:active-index="active"
            expand-icon="undefined"
            collapse-icon="undefined"
            class="gap-2"
            :pt="preset"
            :pt-options="{ mergeSections: false, mergeProps: true }">
            <PVAccordionTab
              v-for="(group, index) in filteredGroups"
              :key="group.id"
              class="p-[4px]">
              <template #header>
                <div
                  class="flex justify-between items-center shadow-xs py-3 px-4 flex-1 rounded-md">
                  <div class="flex items-center gap-2">
                    <div
                      class="flex p-3 bg-[#F4F7FB] rounded-full items-center justify-cente cursor-pointer">
                      <ChevronUp v-if="active === index" :size="16" />
                      <ChevronDown v-else :size="16" />
                    </div>
                    <p class="text-secondary-900 text-sm font-simplerBold">
                      {{ group.name }}
                    </p>
                  </div>
                  <div class="flex items-center gap-2">
                    <div
                      v-if="
                        localStatePermissions.cancel_distribution &&
                        group.distributed &&
                        !props.isSurvey
                      "
                      class="flex p-1.5 rounded-full items-center justify-center bg-black hover:bg-red-500 cursor-pointer"
                      @click.stop="
                        () => {
                          selectedGroup = group;
                          openCancelDistributionModal(true);
                        }
                      ">
                      <Skull class="stroke-white stroke-2" :size="16" />
                    </div>
                    <PVProgressSpinner
                      v-if="loading && group.id === selectedGroup?.id"
                      class="w-6 h-6"
                      stroke-width="4"/>
                    <div
                      v-else
                      class="flex p-1.5 rounded-full items-center justify-center"
                      :class="{
                        'hover:!bg-[#EAEEF6] cursor-pointer active:!bg-[#E0F2FE]':
                          !group.distributed,
                      }"
                      :style="{
                        backgroundColor: group.distributed
                          ? '#E2F3E3'
                          : '#F4F7FB',
                      }"
                      @click.stop="
                        () => {
                          if (!group.distributed && !loading) {
                            selectedGroup = group;
                            props.isSurvey
                              ? distributeSurvey(group.id)
                              : distributeCourse(group.id);
                          }
                        }
                      ">
                      <Check v-if="group.distributed" :size="13" />
                      <Send v-else :size="13" />
                    </div>
                    <div
                      class="flex p-1.5 rounded-full items-center justify-center hover:!bg-[#EAEEF6] cursor-pointer active:!bg-[#E0F2FE] bg-[#F4F7FB]"
                      @click.stop="
                        e => {
                          selectedGroup = group;
                          toggle(e);
                        }
                      ">
                      <EllipsisVertical :size="13" />
                    </div>
                  </div>
                </div>
              </template>
              <div
                v-if="active === index"
                class="p-2 flex flex-1 h-full flex-col overflow-auto max-h-[400px]">
                <div
                  v-for="(student, studentIndex) in group.students"
                  :key="student.id"
                  class="flex flex-1 items-center gap-2 py-3 px-4">
                  <span
                    class="flex h-8 w-8 justify-center items-center text-secondary-900 text-[17px] font-simplerRegular rounded-full"
                    :style="{
                      backgroundColor: getRandomAvatarBGColor(studentIndex),
                    }">
                    {{ student.fullName?.charAt(0) }}
                  </span>
                  <label
                    class="text-secondary-900 font-simplerRegular font-semibold text-sm">
                    {{ student.fullName }}
                  </label>
                </div>
              </div>
            </PVAccordionTab>
          </PVAccordion>
        </ScrollableContainer>
      </div>
      <div
        v-else
        class="w-full flex flex-1 flex-col justify-center items-center gap-2.5">
        <ConstructionCone />
        <text_xs>{{ $t('course_page.no_results') }}</text_xs>
      </div>
    </div>
  </PVSidebar>
  <BaseToast />
  <PVMenu ref="menu" :model="items" popup>
    <template #item="{ item, props: menuProps }">
      <div
        v-ripple
        class="flex items-center gap-1"
        :class="item.class ?? []"
        v-bind="menuProps.action">
        <component :is="item.icon" :size="12" />
        {{ item.label }}
      </div>
    </template>
  </PVMenu>
  <PVDialog
    v-model:visible="cancelDialogVisible"
    modal
    :draggable="false"
    :style="{ width: '25rem' }">
    <template #header>
      <label
        class="text-secondary-800 text-sm font-simplerRegular font-semibold">
        {{ $t('course_page.cancel_course_distribution') }}
      </label>
    </template>
    <div class="flex flex-col gap-2">
      <div class="text-secondary-975 text-sm">
        האם
        <span class="text-orange-500">לבטל את ההפצה</span>
        ל{{ selectedGroup?.name }}?
      </div>
      <div v-if="permanentCancelation" class="font-simplerBold text-red-500">
        בלחיצה על אישור ימחקו כל נתוני ההפצה!!
      </div>
    </div>
    <template #footer>
      <PVButton
        :label="$t('course_page.cancel_distribution')"
        outlined
        :loading="loading"
        @click="() => cancelDistribution(selectedGroup?.id)"/>
    </template>
  </PVDialog>
  <GroupManager ref="groupManager" />
</template>

<script setup lang="ts">
import PVSidebar from 'primevue/sidebar';
import PVAccordion from 'primevue/accordion';
import PVAccordionTab from 'primevue/accordiontab';
import PVProgressSpinner from 'primevue/progressspinner';
import PVButton from 'primevue/button';
import PVMenu from 'primevue/menu';
import PVDialog from 'primevue/dialog';
import { SearchInput } from '@amit/foundation';
import { ref, computed, watchEffect } from 'vue';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import ConstructionCone from '/@/assets/construction-cone.svg?component';
import GroupManager from '/@/views/playground/drawers/GroupManager.vue';
import { useAccountStore } from '/@/app/store/account';
import {
  Check,
  Send,
  ChevronUp,
  ChevronDown,
  EllipsisVertical,
  CircleX,
  PenBox,
  Skull,
} from 'lucide-vue-next';
import { colors } from '@amit/themes/amit-theme';
import {
  createEnrollments,
  cancelDistribution as cancelDistributionApi,
} from '/@/app/services/groups';
import { createEnrollments as createSurveyEnrollmentsApi } from '/@/app/services/surveys';
import BaseToast from './BaseToast.vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useSchoolStore } from '/@/views/playground/store/school-store';
import { useStorage } from '@vueuse/core';

const toast = useToast();

const { t } = useI18n();
const localStatePermissions = useStorage('permissions', {});

const groupManager = ref(null);

const props = defineProps({
  course: {
    type: Object,
    required: true,
  },
  isSurvey: {
    type: Boolean,
    default: false,
  },
});

const schoolStore = useSchoolStore();
const accountStore = useAccountStore();

watchEffect(() => {
  schoolStore.fetch(accountStore.user?.school?.id);
});

const visible = defineModel<boolean | undefined>();
const search = ref('');
const cancelDialogVisible = ref(false);
const active = ref(null);
const loading = ref(false);
const selectedGroup = ref(null);
const permanentCancelation = ref(false);

const menu = ref();
const items = computed(() => [
  ...(selectedGroup.value?.distributed && !props.isSurvey
    ? [
        {
          icon: CircleX,
          label: t('course_page.cancel_distribution'),
          command: () => {
            openCancelDistributionModal();
          },
          class: ['!text-red-500'],
        },
      ]
    : []),
  {
    icon: PenBox,
    label: t('course_page.edit_group'),
    command: () =>
      groupManager.value?.open({
        schoolId: accountStore.user.school.id,
        groupId: selectedGroup.value?.id,
        readonlyMode: selectedGroup.value?.sis_id,
      }),
  },
]);

const toggle = event => {
  menu.value.toggle(event);
};

const filteredGroups = computed(() =>
  accountStore.groups
    .filter(
      group =>
        group.name.toLowerCase().includes(search.value.toLowerCase()) ||
        search.value === '',
    )
    .map(group => ({
      ...group,
      distributed: !props.isSurvey
        ? (group?.courses?.some(course => course.id === props.course?.id) ??
          false)
        : (group?.surveys?.some(course => course.id === props.course?.id) ??
          false),
    })),
);

const avatarBGColors = [
  colors['cyan-gentle'][100],
  '#E5FAEF',
  '#FDF4D8',
  colors.pink[100],
  colors.purple[100],
];
const getRandomAvatarBGColor = index => {
  return avatarBGColors[index % avatarBGColors.length];
};

const preset = {
  accordiontab: {
    root: {
      class: ['!border-none !mb-2'],
    },
    headerAction: {
      class: ['!p-0.5'],
    },
    headerIcon: {
      class: ['!ml-0'],
    },
    content: {
      class: ['!p-0'],
    },
  },
};

const distributeCourse = groupId => {
  loading.value = true;
  const { onSuccess, onError } = createEnrollments({
    group: groupId,
    course: props.course.id,
  });
  onSuccess(() => {
    loading.value = false;
    const group = accountStore.groups.find(group => group.id === groupId);
    group.courses.push(props.course);
    toast.add({
      severity: 'success',
      summary: 'הקורס הופץ בהצלחה!',
      life: 4000,
    });
    selectedGroup.value = null;
  });
  onError(() => {
    loading.value = false;
    toast.add({
      severity: 'error',
      summary: 'הפצת הקורס נכשלה',
      life: 7000,
    });
    selectedGroup.value = null;
  });
};

const distributeSurvey = groupId => {
  loading.value = true;
  const { onSuccess, onError } = createSurveyEnrollmentsApi({
    group: groupId,
    survey: props.course.id,
  });
  onSuccess(() => {
    loading.value = false;
    const group = accountStore.groups.find(group => group.id === groupId);
    group.surveys.push(props.course);
    toast.add({
      severity: 'success',
      summary: 'השאלונים הופצו בהצלחה!',
      life: 4000,
    });
    selectedGroup.value = null;
  });
  onError(() => {
    loading.value = false;
    toast.add({
      severity: 'error',
      summary: 'הפצת השאלונים נכשלה',
      life: 7000,
    });
    selectedGroup.value = null;
  });
};

const openCancelDistributionModal = (permanent = false) => {
  permanentCancelation.value = permanent;
  cancelDialogVisible.value = true;
};

const cancelDistribution = groupId => {
  loading.value = true;
  const { onSuccess, onError } = cancelDistributionApi({
    group: groupId,
    course: props.course.id,
    permanent: permanentCancelation.value,
  });
  onSuccess(() => {
    loading.value = false;
    let group = accountStore.groups.find(group => group.id === groupId);
    group.courses = group.courses.filter(({ id }) => props.course.id !== id);
    toast.add({
      severity: 'info',
      summary: `ההפצה בוטלה ל${selectedGroup.value?.name}`,
      life: 4000,
    });
    cancelDialogVisible.value = false;
    selectedGroup.value = null;
  });
  onError(() => {
    loading.value = false;
    toast.add({
      severity: 'error',
      summary: 'ביטול הפצת הקורס נכשלה',
      life: 7000,
    });
  });
};
</script>

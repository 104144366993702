<template>
  <PreviewLesson
    v-if="slideshow.id"
    :id="slideshow.id"
    :title="menuTitle"
    class="h-screen"/>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from 'vue';
import { PreviewLesson, createPresentation } from '@nwire/amit-design-system';
import { fetchProject } from '/@/app/services/projects';
import { fetchPages } from '/@/app/services/courses';
import { useRoute, useRouter } from 'vue-router';
import { useState } from '/@/app/composables/useState';

const { getDisabled, getPageState } = useState();

const menuTitle = computed(
  () => `שלב ${slideshow.value.lesson} - ${slideshow.value.title}`,
);

const route = useRoute();
const router = useRouter();
const slideshow = ref({
  id: '',
  project: '',
  lesson: 2,
  title: '',
  display: {
    rtl: true,
  },
  sections: [],
});

const outline = ref([]);
const locale = ref();
const currentTracks = ref<string[]>([]);

const { onOutlineChange, onAction, onReady, onSlide, onNext } =
  createPresentation({
    slideshow,
    outline,
    locale,
    tracks: currentTracks,
  });

onOutlineChange(stage =>
  router.push({
    name: 'project-preview',
    params: {
      stage: stage.id,
      project: route.params.project,
    },
  }),
);

onAction(({ context, event }) => {
  if (!context) return;

  const contextState = context.state();
  const contextBlocks = context.flatBlocks();
  if (event.value) {
    context.updateState(event.block.id, event.value);
  }
  context.setDisabled(getDisabled(contextBlocks, contextState));
});

const handleSlide = context => {
  const contextBlocks = context.blocks();
  const state = getPageState(contextBlocks);
  context.setState(state);

  const personaSelectionBlock = contextBlocks.find(
    block => block.name === 'persona_selection',
  );
  if (personaSelectionBlock) {
    const track = state[personaSelectionBlock.id];
    if (!currentTracks.value.includes(track)) {
      currentTracks.value.push(track);
    }
  }

  context.setDisabled(getDisabled(contextBlocks, state));
};

onReady(({ context }) => {
  handleSlide(context);
});

onSlide(({ context }) => {
  handleSlide(context);
});

onNext(async context => {
  let nextSlide = context.sync({ instructions: null });
  if (!nextSlide) {
    return;
  }

  let disabled = false;
  let state = nextSlide.state;

  context.next({
    state,
    disabled,
  });
});

const fetchContent = (projectId, stageId) => {
  const { onSuccess: onProjectFetched } = fetchProject(projectId);

  onProjectFetched(({ data: project }) => {
    outline.value = project.outline.map(({ id, lesson }) => ({
      id,
      title: lesson,
      selected: id === stageId,
    }));
    locale.value = project?.locale;
  });

  const { onSuccess: onStageFetched } = fetchPages(projectId, stageId);
  onStageFetched(({ data: stage }) => {
    slideshow.value = stage;
  });
};

watch(
  () => route.params.stage,
  () => window.location.reload(),
);
watchEffect(() => fetchContent(route.params.project, route.params.stage));
</script>

<template>
  <PVDialog
    v-model:visible="isOpen"
    class="transition-all duration-500"
    modal
    :style="{ width: '30rem' }">
    <template #header>
      <slot name="header" />
    </template>
    <div class="flex">
      <slot />
    </div>
    <template #footer>
      <PVButton label="לא, אני רוצה להישאר" @click="emit('close')" />
      <PVButton
        :severity="'neutral'"
        label="כן, אני רוצה לצאת"
        @click="emit('confirm', true)"/>
    </template>
  </PVDialog>
</template>
<script setup lang="ts">
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['confirm', 'close']);

const isOpen = useVModel(props, 'open', emit, { eventName: 'close' });
</script>

import { h } from 'vue';
import { type BuildingBlock } from './blocks';
import { getAttributes, resolveComponent } from './mappers';
// import VueJsoneditor from 'vue3-ts-jsoneditor';
import QuestionWrapper from '@/apps/slideshow/QuestionWrapper.vue';
import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';
import LoadingOverlay from 'vue-loading-overlay';

export const renderBlock: any = ({
  block,
  state,
  // editable = false,
  locale = 'he',
  display = 'default',
  preview = false,
  blocksMap = {},
  children,
}: {
  block: BuildingBlock;
  state: Record<string, any>;
  editable?: boolean;
  locale?: string;
  display?: string;
  preview?: boolean;
  blocksMap: Record<string, any>;
  children: Record<string, any>;
}) => {
  const BlockComponent = resolveComponent(block.name, blocksMap);

  if (!BlockComponent) {
    return h('div', { dir: 'ltr', class: 'h-fit text-left' }, [
      h('pre', {
        innerHTML: JSON.stringify(block, null, 2),
        class: 'overflow-auto p-10',
      }),
      // h(VueJsoneditor, {
      //   height: '400',
      //   json: block,
      //   readOnly: true,
      //   mode: 'text',
      // }),
    ]);
  }

  const { props = {}, emits } = BlockComponent;
  const attributes = getAttributes({
    block,
    props,
    // editable,
    locale,
    display,
    state,
    emits,
  });

  let rendered = h(BlockComponent, attributes, children);
  if (
    preview &&
    (block.settings?.submissionStyle === 'inline' ||
      block.settings?.display === 'modal')
  ) {
    const { onSubmit, onEmit } = useInteractions();
    const { context } = useReveal();
    rendered = h(
      QuestionWrapper,
      {
        block,
        onSubmit: () => {
          block.state.submitting = true;
          onSubmit.trigger({
            block,
            context: context.value,
          });
        },
        onOpenHint: block => {
          const event = {
            hints: block.settings.hints,
            name: 'show-hint',
            source: block.id,
          };
          onEmit.trigger({ event, context: context.value });
        },
      },
      { body: () => h(BlockComponent, attributes, children) },
    );
  }

  const loader = h(LoadingOverlay, {
    active: block.state?.loading,
    isFullPage: false,
    canCancel: false,
    backgroundColor: '#E9EEF7',
    color: '#0FA9FF',
  });

  return h('div', { class: 'w-full flex justify-center am-block relative' }, [
    rendered,
    loader,
  ]);
};

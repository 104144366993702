<template>
  <div>
    <div
      class="unit flex items-center justify-between hover:bg-secondary-200 pe-2 py-0.5 rounded-md"
      :class="{ 'bg-secondary-200': isSettingsOpen, 'mt-4': canEdit }">
      <div class="flex items-center">
        <Transition name="slide-fade">
          <div
            v-if="isHovered || isSettingsOpen"
            class="flex items-center justify-center w-6 h-6 drag-handler cursor-grab">
            <GripVertical :size="16" />
          </div>
        </Transition>
        <EditableField
          :empty="!el.title.length"
          class="h-6 flex items-center p-1"
          :class="{ 'pointer-events-none': !canEdit }">
          <template #display>
            <p class="text-sm select-none">{{ el.title }}</p>
          </template>
          <template #content="{ onCloseCallback, isEditing }">
            <input
              v-model="el.title"
              class="content body !m-0 rounded-md text-sm"
              autofocus
              @blur="applyChanges(onCloseCallback)"
              @keyup.enter="applyChanges(onCloseCallback)"/>
          </template>
        </EditableField>
      </div>
      <PVSplitButton
        v-if="(canEdit && isHovered && canRemoveUnit) || isSettingsOpen"
        ref="settings"
        :model="actions"
        :pt="iconDropdownPT">
        <template #menubuttonicon>
          <div @click="isSettingsOpen = !isSettingsOpen">
            <EllipsisVertical :size="16" />
          </div>
        </template>
      </PVSplitButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import PVSplitButton from 'primevue/splitbutton';
import EditableField from '/@/views/Lobby/CourseSettings/EditableField.vue';
import { onClickOutside, useVModel } from '@vueuse/core';
import { GripVertical, EllipsisVertical } from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import { iconDropdownPT } from '/@/views/Lobby/styles';
import { ref } from 'vue';
const emit = defineEmits(['removeUnit', 'updateModels', 'addLesson']);

const props = defineProps({
  element: {
    type: Object,
    default: () => ({
      title: '',
    }),
  },
  isHovered: {
    type: Boolean,
    default: false,
  },
  isDragged: {
    type: Boolean,
    default: false,
  },
  canRemoveUnit: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
});

const el = useVModel(props, 'element');
const { t } = useI18n();
const settings = ref();
const isSettingsOpen = ref(false);
const applyChanges = callback => {
  callback();
  emit('updateModels');
};

const actions = [
  {
    label: t('course_settings.remove_unit'),
    command: () => {
      emit('removeUnit');
    },
  },
];

onClickOutside(settings, () => {
  isSettingsOpen.value = false;
});
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-in;
  width: 32px;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
  width: 32px;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  width: 0;
}
</style>

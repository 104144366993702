<template>
  <div class="flex gap-10">
    <div v-if="currentVideo" class="w-4/5">
      <Transition
        enter-active-class="transition ease-in-out duration-700 opacity"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in-out duration-100 opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div :key="currentVideo.id">
          <CustomVideo
            class="flex w-full items-center justify-between pb-10"
            size="full"
            :persistent="false"
            :src="currentVideo?.src"
            :caption="currentVideo?.caption"
            :transcription="currentVideo?.transcription"
            :auto-play="true"
            @video-next-enabled="$emit('video-next-enabled')"/>
        </div>
      </Transition>
    </div>

    <div class="w-1/5 flex flex-col gap-5 mt-10 pb-20">
      <div
        v-for="{ id, name, avatar } in selection"
        :key="id"
        class="cursor-pointer max-w-100"
        @click="model = id">
        <div class="flex items-center justify-between w-full gap-2">
          <span
            class="font-simplerBold text-lg text-dark gap-3"
            :class="{ 'text-primary': model === id }">
            {{ name }}
          </span>
        </div>
        <div class="flex flex-col justify-between grow">
          <div class="relative">
            <img
              class="rounded-lg w-full overflow-hidden"
              :src="avatar"
              alt="Coach image"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, PropType } from 'vue';
import { CustomVideo } from '@/components';
import { ISelectionItem, IGalleryVideo } from '@/types/interfaces';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  selection: {
    type: Array as PropType<ISelectionItem[]>,
    default: () => [],
  },
  videos: {
    type: Array as PropType<IGalleryVideo[]>,
    default: () => [],
  },
});
defineEmits(['video-next-enabled']);

const model = ref(props.modelValue);

const currentVideo = computed(() =>
  props.videos.find(video => video.reference === model.value),
);
</script>

<template>
  <div class="max-w-full overflow-auto flex flex-col gap-5 h-full p-5">
    <div class="flex justify-between items-center">
      <label
        class="flex gap-2 items-center text-secondary-900 font-simplerBold font-black text-xl">
        {{ t('groups') }}
      </label>

      <div
        class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
        <PVButton
          :label="`${t('users.add_new_group')}`"
          @click="
            groupManager?.open({
              ownerId: accountStore.user.id,
              schoolId: accountStore.user.school.id,
            })
          "/>
      </div>
    </div>

    <div class="flex flex-1 flex-col gap-5 pt-2">
      <div class="flex items-center gap-2">
        <SearchInput
          v-model="searchGroups"
          :placeholder="t('search')"
          :style="'neutral'"
          @reset-search="searchGroups = ''"/>
      </div>
      <ScrollableContainer :mirrored="false">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <GroupListItem
            v-for="group in groups"
            :key="group.id"
            :item="group"
            class="cursor-pointer"
            @edit-group="
              () =>
                groupManager?.open({
                  groupId: group.id,
                  readonlyMode: group.sis_id,
                  ownerId: group.owner_id.id,
                  schoolId: accountStore.user.school.id,
                })
            "
            @sync="() => schoolStore.syncGroup(group)"/>
        </div>
        <div v-if="!groups.length">
          <label class="text-secondary-800 text-sm">לא נמצאו קבוצות</label>
        </div>
      </ScrollableContainer>
    </div>
  </div>

  <GroupManager ref="groupManager" />
</template>

<script setup lang="ts">
import GroupListItem from '/@/views/playground/partials/GroupListItem.vue';
import PVButton from 'primevue/button';
import { ref, watchEffect, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSchoolStore } from '/@/views/playground/store/school-store';
import GroupManager from '/@/views/playground/drawers/GroupManager.vue';
import { SearchInput } from '@amit/foundation';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import { useAccountStore } from '/@/app/store/account';

const schoolStore = useSchoolStore();
const accountStore = useAccountStore();

const groupManager = ref(null);
const searchGroups = ref('');

const messages = {
  he: {
    groups: 'ניהול הקבוצות שלי',
    search: 'חיפוש קבוצה לפי שם',
  },
};
const { t } = useI18n({ messages });

watchEffect(() => {
  const schoolId = accountStore.user?.school?.id;
  if (schoolId) {
    schoolStore.fetch(schoolId);
  }
});

const filter = (array, keys, value) => {
  return array?.filter(item => {
    return keys.some(key => {
      return item[key].includes(value);
    });
  });
};

const groups = computed(() => {
  if (!accountStore.user?.groups) return [];
  return filter(
    accountStore.user.groups.map(item => {
      return {
        ...item,
        teacher: item.owner_id?.fullName ?? '----',
      };
    }),
    ['name', 'teacher'],
    searchGroups.value,
  );
});
</script>

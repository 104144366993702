<template>
  <div class="relative">
    <div
      :class="
        accountStore.flattenedSurveys.length ||
        accountStore.flattenedCourses.length
          ? 'absolute end-0 top-2'
          : 'flex justify-end mt-2'
      ">
      <PVButton class="h-[33px]" @click="openMyMentorship">
        <div class="flex items-center gap-1">
          <span class="text-sm font-simplerBold">
            {{ $t('course_page.my_mentorship') }}
          </span>
          <ArrowLeft :size="14" />
        </div>
      </PVButton>
    </div>
    <div v-if="accountStore.flattenedSurveys.length">
      <div class="text-sm font-simplerBold text-secondary-900 pt-5">
        השאלונים שלך
      </div>
      <div class="py-6 grid grid-cols-1 xs:grid-cols-2 gap-3">
        <CourseCard
          v-for="{ course, group } in accountStore.flattenedSurveys"
          :key="course.id"
          type="teacher"
          :course="course"
          :group="group"/>
      </div>
    </div>
    <div v-if="accountStore.flattenedCourses.length">
      <div class="text-sm font-simplerBold text-secondary-900 pt-5">
        הקורסים שלך
      </div>
      <div class="py-6 grid grid-cols-1 xs:grid-cols-2 gap-3">
        <CourseCard
          v-for="{ course, group } in accountStore.flattenedCourses"
          :key="course.id"
          type="teacher"
          :course="course"
          :group="group"/>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-center font-anomaliaLight md:text-xl mt-20">
      <div class="flex flex-col text-center">
        <span>עדיין אין לך קורסים,</span>
        <span>כדאי לראות מה יש לנו להציע לך</span>
        <RouterLink
          :to="{ name: 'library' }"
          class="mt-2 bg-primary-200 rounded-xl py-2">
          <span class="text-primary md:text-2xl">בספריה!</span>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAccountStore } from '/@/app/store/account';
import { impersonate } from '/@/app/services/accounts';
import { ArrowLeft } from 'lucide-vue-next';
import { CourseCard } from '@amit/components';
import PVButton from 'primevue/button';
const accountStore = useAccountStore();

const openMyMentorship = () => {
  const { onSuccess } = impersonate(accountStore.user.id);
  onSuccess(({ data }) => {
    const { token } = data;
    if (token) {
      window.open(
        `${import.meta.env.VITE_STUDENTS_BASE}?token=${token}`,
        '_blank',
      );
    }
  });
};
</script>

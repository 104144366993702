import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { setModule } from '/@/app/services/modules';
import { useStudentsStore } from '/@/app/store/students';
import { keyBy } from 'lodash';
import { useToast } from 'primevue/usetoast';

export type WeightItem = {
  id: string;
  title: string;
  weight: number;
  type?: string;
};

interface InitParams {
  courseModuleId: string;
  course: string;
  group: string;
  data: WeightItem[];
}

export const useWeightManagement = defineStore('weightManagement', () => {
  const studentsStore = useStudentsStore();
  const toast = useToast();

  const _courseModuleId = ref<string>('');
  const _course = ref<string>('');
  const _group = ref<string>('');
  const _data = ref<WeightItem[]>([]);
  const _updatedData = ref<WeightItem[]>([]);

  const init = ({ courseModuleId, course, group, data }: InitParams) => {
    _courseModuleId.value = courseModuleId;
    _course.value = course;
    _group.value = group;
    _data.value = data;
    _updatedData.value = [...data];
    errorMessage.value = '';
    isLoading.value = false;
    isWeightManagementDialogOpen.value = false;
    isSelectCourseOfActionOpen.value = false;
  };

  const menu = ref();
  const weightPanel = ref();

  const toggleWeightPanel = (event, header) => {
    _originalCriteria.value = header;
    _updatedCriteria.value = JSON.parse(JSON.stringify(header));
    weightPanel.value.toggle(event);
  };

  const toggleMenu = (event: Event, criteria: WeightItem) => {
    _originalCriteria.value = criteria;
    _updatedCriteria.value = JSON.parse(JSON.stringify(criteria));
    menu.value.toggle(event);
  };

  const _originalCriteria = ref<WeightItem | null>(null);
  const _updatedCriteria = ref<WeightItem>({
    id: '',
    title: '',
    weight: 0,
  });

  const isCriteriaUpdated = computed(() => {
    return (
      _originalCriteria.value?.weight !== _updatedCriteria.value?.weight ||
      _originalCriteria.value?.title !== _updatedCriteria.value?.title
    );
  });

  const isLoading = ref<boolean>(false);
  const errorMessage = ref<string>('');

  const isWeightManagementDialogOpen = ref<boolean>(false);
  const isSelectCourseOfActionOpen = ref<boolean>(false);

  const handleSaveChanges = ({
    updatedData,
    weightUpdated,
    onSave,
  }: {
    updatedData: WeightItem[];
    weightUpdated?: boolean;
    onSave?: () => void;
  }) => {
    isLoading.value = true;
    errorMessage.value = '';
    const { onSuccess, onError } = setModule(_courseModuleId.value, {
      summary: updatedData
        .filter(item => item.type === 'lesson')
        .map(({ type, ...item }) => ({
          lesson: item.id,
          weight: item.weight,
        })),
      criteria: updatedData
        .filter(item => item.type === 'criteria')
        .map(({ type, title, ...item }) => ({
          ...item,
          name: title,
          weight: item.weight,
          id: item.id === '' ? undefined : item.id,
        })),
      assessments: updatedData
        .filter(item => item.type === 'assessment')
        .map(({ type, title, ...item }) => ({
          ...item,
          weight: item.weight,
        })),
    });
    onSuccess(() => {
      isLoading.value = false;
      isWeightManagementDialogOpen.value = false;
      studentsStore.setCourseModule(_group.value, _course.value, true);
      onSave?.();
      weightUpdated &&
        toast.add({
          summary:
            'שימו לב! בעקבות שינוי אחוזי המרכיבים, ציוני הקורס לא מעודכנים ויתעדכנו בהקדם',
          life: undefined,
          group: 'headless',
        });
    });
    onError(error => {
      isLoading.value = false;
      errorMessage.value = 'אירעה שגיאה בשמירת השינויים';
      console.error(error);
    });
  };

  type ItemsMap = Record<string, WeightItem>;

  const updateWeights = (
    items: ItemsMap,
    selectedId: string,
    newWeight: number,
  ): ItemsMap => {
    // Validate the newWeight
    if (newWeight < 0 || newWeight > 100) {
      throw new Error(
        `newWeight must be between 0 and 100. Received: ${newWeight}`,
      );
    }

    // Calculate the total weight available for the other items
    const remainingWeight = 100 - newWeight;

    // Extract other items
    const otherItems = Object.values(items).filter(
      item => item.id !== selectedId,
    );

    // Calculate the total weight of other items
    const totalOtherWeights = otherItems.reduce(
      (sum, item) => sum + item.weight,
      0,
    );

    // Distribute weights proportionally and truncate to integers
    const updatedItems: ItemsMap = { ...items };
    if (updatedItems[selectedId]) {
      updatedItems[selectedId] = {
        ...updatedItems[selectedId],
        weight: newWeight,
      };
    }

    otherItems.forEach(item => {
      if (totalOtherWeights > 0) {
        const proportionalWeight =
          (item.weight / totalOtherWeights) * remainingWeight;
        updatedItems[item.id] = {
          ...item,
          weight: Math.floor(proportionalWeight),
        };
      } else {
        // If totalOtherWeights is 0, distribute remainingWeight equally
        const equalWeight = Math.floor(remainingWeight / otherItems.length);
        updatedItems[item.id] = { ...item, weight: equalWeight };
      }
    });

    // Calculate the rounding error
    let totalWeightAfterRounding = Object.values(updatedItems).reduce(
      (sum, item) => sum + item.weight,
      0,
    );
    let roundingError = 100 - totalWeightAfterRounding;

    // Function to sort items for adjustment
    const sortItemsForAdjustment = (ascending: boolean = true) => {
      return otherItems
        .map(item => updatedItems[item.id])
        .sort((a, b) =>
          ascending ? a.weight - b.weight : b.weight - a.weight,
        );
    };

    // Distribute the rounding error
    while (roundingError !== 0) {
      if (roundingError > 0) {
        // Distribute the positive error by incrementing the item with the largest weight
        const sortedItems = sortItemsForAdjustment(false);
        for (const item of sortedItems) {
          if (roundingError === 0) break;
          updatedItems[item.id].weight += 1;
          roundingError -= 1;
        }
      } else {
        // Distribute the negative error by decrementing the item with the smallest weight
        const sortedItems = sortItemsForAdjustment(true);
        for (const item of sortedItems) {
          if (roundingError === 0) break;
          if (updatedItems[item.id].weight > 0) {
            updatedItems[item.id].weight -= 1;
            roundingError += 1;
          }
        }
      }
    }
    // Validate the total weight
    const totalWeightAfterAdjustment = Object.values(updatedItems).reduce(
      (sum, item) => sum + item.weight,
      0,
    );
    if (totalWeightAfterAdjustment !== 100) {
      throw new Error(
        `Total weight after adjustment is not 100. Received: ${totalWeightAfterAdjustment}`,
      );
    }
    return updatedItems;
  };

  const handleSaveAutomaticCalculation = () => {
    isLoading.value = true;
    try {
      const weightItems = [
        ..._updatedData.value.map(({ ...item }) => ({
          ...item,
          id: item.id === '' ? 'new' : item.id,
        })),
      ];
      const weightItemsMap = keyBy(weightItems, 'id');

      const updatedItems = updateWeights(
        weightItemsMap,
        _originalCriteria.value?.id === ''
          ? 'new'
          : (_originalCriteria.value?.id ?? 'new'),
        weightItemsMap[_originalCriteria.value?.id ?? 'new']
          ? (_updatedCriteria.value?.weight ?? 0)
          : 0,
      );

      const updatedSummary = _updatedData.value
        .filter(item => item.type === 'lesson')
        .map(item => ({
          lesson: item.id,
          weight: updatedItems[item.id]?.weight ?? item.weight,
        }));

      const updatedCriteria = _updatedData.value
        .filter(item => item.type === 'criteria')
        .map(({ type, title, ...item }) => ({
          ...item,
          id: item.id === '' || item.id === 'new' ? undefined : item.id,
          name: title,
          weight: updatedItems[item.id]?.weight ?? item.weight,
        }));

      const updatedAssessments = _updatedData.value
        .filter(item => item.type === 'assessment')
        .map(({ type, title, ...item }) => ({
          ...item,
          weight: updatedItems[item.id]?.weight ?? item.weight,
        }));

      const { onSuccess, onError } = setModule(_courseModuleId.value, {
        summary: updatedSummary,
        criteria: updatedCriteria,
        assessments: updatedAssessments,
      });
      onSuccess(() => {
        isLoading.value = false;
        isSelectCourseOfActionOpen.value = false;
        studentsStore.setCourseModule(_group.value, _course.value, true);
        toast.add({
          summary:
            'שימו לב! בעקבות שינוי אחוזי המרכיבים, ציוני הקורס לא מעודכנים ויתעדכנו בהקדם',
          life: undefined,
          group: 'headless',
        });
      });

      onError(error => {
        isLoading.value = false;
        errorMessage.value = 'אירעה שגיאה בשמירת השינויים';
        console.error(error);
        toast.add({
          summary: 'אירעה שגיאה בשמירת השינויים',
          life: undefined,
          group: 'headless',
        });
      });

      // TODO fix using a socket

      // onSuccess(() => {
      // studentsStore.fetchEnrollments({
      //   group: group.value,
      //   course: course.value,
      // });
      // studentsStore.fetchSubmissions(group.value, course.value);
      // if (score.value <= 100) return;

      // timeoutId = setTimeout(() => {
      //   messageVisible.value = false;
      // }, 2000);
      //   });
    } catch (error) {
      console.error(error);
      isLoading.value = false;
      errorMessage.value = 'אירעה שגיאה בשמירת השינויים';
      toast.add({
        summary: 'אירעה שגיאה בשמירת השינויים',
        life: undefined,
        group: 'headless',
      });
    }
  };

  const updateCriteriaWeight = (newWeight: number) => {
    _updatedCriteria.value.weight = newWeight;
  };

  watch(
    _originalCriteria,
    newValue => {
      if (newValue) {
        _updatedCriteria.value = JSON.parse(JSON.stringify(newValue));
      } else {
        _updatedCriteria.value = {
          id: '',
          title: '',
          weight: 0,
        };
      }
    },
    { immediate: true },
  );

  watch(isWeightManagementDialogOpen, newValue => {
    if (newValue) {
      errorMessage.value = '';
    }
  });

  return {
    init,
    _originalCriteria,
    _updatedCriteria,
    _data,
    _updatedData,
    isCriteriaUpdated,
    isLoading,
    isWeightManagementDialogOpen,
    isSelectCourseOfActionOpen,
    errorMessage,
    menu,
    weightPanel,
    toggleWeightPanel,
    toggleMenu,
    handleSaveChanges,
    updateWeights,
    handleSaveAutomaticCalculation,
    updateCriteriaWeight,
  };
});

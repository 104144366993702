<template>
  <div class="bg-blue-sea-75 rounded-md relative p-4 flex justify-between mb-5">
    <div class="flex items-start justify-between w-full">
      <div class="flex flex-col">
        <div class="flex items-center gap-1 h-8">
          <span class="font-simplerBold text-[24px] text-secondary-900">
            {{ currentCourse?.caption }}
          </span>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <span class="font-simplerRegular text-sm text-secondary-800 h-3.5">
            {{ currentGroup?.name }}
          </span>
          <ProgressInfo v-if="!isSurvey" :progress="studentsProgress" />
        </div>
      </div>
      <div class="flex items-center gap-2 mt-2">
        <RouterLink
          v-if="isSurvey"
          :to="{
            name: 'survey-progress-visual',
            params: {
              group,
              survey: course,
            },
          }">
          <PVButton class="flex-row-reverse. h-[33px]" label="תצוגת מיפוי >" />
        </RouterLink>
      </div>
    </div>
  </div>
  <BaseTabs
    v-if="!isSurvey && currentGroup && courseModule"
    v-model="activeTab"
    variant="underline"
    directive="show">
    <BaseTab name="lessons" title="שיעורים">
      <div class="mt-2">
        <LessonsTable :course-module="courseModule" />
      </div>
    </BaseTab>
    <BaseTab name="grades" title="ציונים">
      <div class="mt-2">
        <StudentsTable
          :course-module="courseModule"
          :students="currentGroup.students"/>
      </div>
    </BaseTab>
  </BaseTabs>

  <StudentsSurveyTable
    v-else-if="currentGroup && currentCourse && isSurvey"
    :students="currentGroup.students"/>
  <SideWidget v-if="!isSurvey && route.query.view !== 'grades'">
    <EnrollmentSummary :group="currentGroup" :course="currentCourse" />
  </SideWidget>
  <SideWidget v-if="!isSurvey && route.query.view !== 'grades'">
    <TeamsWidget v-if="teamsEnabled" :course-module="courseModule" />
  </SideWidget>
  <SideWidget v-if="!isSurvey && route.query.view !== 'grades'">
    <AmitDriveWidget :course-id="currentCourse?.id" />
  </SideWidget>
  <SideWidget v-if="!isSurvey && route.query.view !== 'grades'">
    <ProjectWidget
      v-if="projectId"
      :project="projectId"
      :group="currentGroup?.id"
      :course="course"/>
  </SideWidget>
  <SideWidget
    v-if="
      currentGroup?.surveys.length && isSurvey && route.query.view !== 'grades'
    "
    base>
    <SurveyRecentOpenQuestionWidget :group="currentGroup" />
  </SideWidget>

  <LoadingOverlay
    :active="!(currentGroup && courseModule) && !isSurvey"
    :can-cancel="false"
    :is-full-page="true"/>
</template>

<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';
import StudentsTable from '/@/views/playground/StudentsTable.vue';
import LessonsTable from '/@/views/playground/LessonsTable.vue';
import StudentsSurveyTable from '/@/views/components/StudentsSurveyTable.vue';
import {
  ref,
  watchEffect,
  computed,
  resolveComponent,
  onMounted,
  watch,
} from 'vue';
import { useAccountStore } from '/@/app/store/account';
import { RouteParams, useRoute, useRouter } from 'vue-router';
import { BaseTabs, BaseTab } from '@nwire/amit-design-system';
import TeamsWidget from '/@/views/partials/TeamsWidget.vue';
import ProjectWidget from '/@/views/partials/ProjectWidget.vue';
import { collect } from 'collect.js';
import { isEmpty } from 'lodash';
import AmitDriveWidget from '/@/views/partials/AmitDriveWidget.vue';
import { SideWidget } from '@amit/layout';
import EnrollmentSummary from '/@/views/partials/EnrollmentSummary.vue';
import SurveyRecentOpenQuestionWidget from '/@/views/partials/SurveyRecentOpenQuestionWidget.vue';
import PVButton from 'primevue/button';
import { ProgressInfo } from '@amit/components';

const LoadingOverlay = resolveComponent('LoadingOverlay');

const accountStore = useAccountStore();
const studentsStore = useStudentsStore();

const router = useRouter();
const route = useRoute();
const currentGroup = ref<any>(null);
const currentCourse = ref<any>(null);
const activeTab = ref('lessons');
const { group, course }: RouteParams | { group: any; course: any } =
  route.params;

const projectId = computed(() => {
  const courseProject = currentCourse.value?.assessments?.find(
    assessment => assessment.type === 'project',
  );
  return courseProject?.reference || '';
});

const isSurvey = computed(() => route.name?.toString() === 'survey-progress');

const courseModule = computed(() =>
  studentsStore.getCourseModule(group, course),
);

const teamsEnabled = computed(() => {
  return courseModule.value?.summary?.some(lesson => lesson.teams_enabled);
});

const studentsProgress = computed(() => {
  const enrollments = studentsStore.enrollments;

  if (!enrollments?.length) {
    return 0;
  }
  const totalStudentsProgress = enrollments.reduce((sum, enrollment) => {
    return sum + (enrollment.total_progress ?? 0);
  }, 0);
  return totalStudentsProgress / enrollments.length;
});

watchEffect(() => {
  if (!isSurvey.value) {
    studentsStore.setCourseModule(group, course);
  }
});

watchEffect(() => {
  try {
    if (!isEmpty(accountStore.user)) {
      console.log('here :>> ');
      const { group, course }: RouteParams | { group: any; course: any } =
        route.params;

      currentGroup.value = collect(accountStore.groups)
        .where('id', group)
        .first();

      const firstCurrentCourse: any = collect(
        isSurvey.value
          ? currentGroup.value.surveys
          : currentGroup.value.courses,
      )
        .where('id', course)
        .first();

      firstCurrentCourse.outline = [];
      currentCourse.value = firstCurrentCourse;

      accountStore.selectedGroup = currentGroup.value;
      accountStore.selectedCourse = currentCourse.value;

      isSurvey.value
        ? studentsStore.fetchSurveyEnrollments({ group, survey: course })
        : studentsStore.fetchEnrollments({ group, course });
    }
  } catch (error) {
    console.error(error);
    router.push({ name: 'home' });
  }
});
onMounted(() => {
  const viewParam = route.query.view;
  if (viewParam && ['lessons', 'grades'].includes(viewParam)) {
    activeTab.value = viewParam;
  } else {
    router.replace({ query: { ...route.query, view: activeTab.value } });
  }
});

watch(activeTab, newVal => {
  router.replace({ query: { ...route.query, view: newVal } });
});
</script>

<style>
.course-description {
  * {
    @apply font-simplerRegular text-sm text-secondary-800 !important;
  }
}
</style>

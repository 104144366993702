<template>
  <div
    class="flex flex-col overflow-hidden"
    :style="{
      height: 'calc(100vh - 60px)',
    }">
    <div class="flex flex-col w-[32%]">
      <span
        class="flex gap-1 cursor-pointer text-secondary-600"
        @click="
          $router.push({
            name: 'course-progress',
            params: { group, course },
            query: { view: 'grades' },
          })
        ">
        <ChevronRight :size="16" />
        <span
          class="text-start text-secondary-600 text-sm overflow-hidden whitespace-nowrap text-ellipsis">
          {{ courseObj?.course?.caption }} / {{ currentGroup?.name }}
        </span>
      </span>
      <PVDropdown
        v-model="selectedLesson"
        filter
        empty-filter-message="לא נמצאו תוצאות"
        :pt="dropdownPT"
        :options="
          courseObj?.summary
            ?.sort((a, b) => a.lesson.lesson - b.lesson.lesson)
            ?.map(l => ({
              id: l.lesson.id,
              title: truncateString(l.lesson.title, 40),
            }))
        "
        option-label="title"
        placeholder=""
        class="w-full md:w-14rem">
        <template #value="{ value, placeholder }">
          <p
            v-if="value"
            :title="value.title"
            :dir="courseObj?.course?.locale != 'he' ? 'ltr' : ''"
            class="text-start text-secondary-900 text-lg font-bold overflow-hidden whitespace-nowrap text-ellipsis">
            {{ value.title }}
          </p>
          <p v-else>{{ placeholder }}</p>
        </template>
        <template #dropdownicon>
          <div
            class="flex justify-center items-center bg-white hover:bg-secondary-75 rounded-sm w-7 h-7">
            <ChevronDown size="16" />
          </div>
        </template>
      </PVDropdown>
    </div>
    <div
      class="flex-1 flex overflow-hidden h-full w-full gap-2.5 border-0 mt-5">
      <div class="h-full bg-white rounded-lg w-[32%]">
        <SelectionPanel
          :active-panel="activePanel"
          :students="students"
          :exercises="exercises"
          :active-student="student"
          :active-exercise="exercise"
          @update:active-panel="num => (activePanel = num)"
          @update:active-student="id => (student = id)"
          @update:active-exercise="id => (exercise = id)"></SelectionPanel>
      </div>
      <div class="w-[68%]">
        <DisplayPanel :students="students"></DisplayPanel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStudentsStore } from '/@/app/store/students';

import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useAccountStore } from '/@/app/store/account';
import { RouteParams, useRoute } from 'vue-router';
import { useCourseStore } from '/@/app/store/course';

import DisplayPanel from '/@/views/components/LessonSubmission/DisplayPanel.vue';
import SelectionPanel from '/@/views/components/LessonSubmission/SelectionPanel.vue';
import { lessonDropdownPT } from '/@/views/components/LessonSubmission/styles';
import PVDropdown from 'primevue/dropdown';
import { useRouteQuery, useRouteParams } from '@vueuse/router';
import { ChevronDown, ChevronRight } from 'lucide-vue-next';
const accountStore = useAccountStore();
const studentsStore = useStudentsStore();
const courseStore = useCourseStore();

const route = useRoute();
let currentGroup = ref<any>(null);
const selectedLesson = ref();

enum reviewBy {
  exercise,
  student,
}

const reviewPer = useRouteQuery<any>('review_per', 'exercise');
const student = useRouteQuery<any>('student', null);
const exercise = useRouteQuery<any>('exercise');
const lesson = useRouteParams('lesson');

const { group, course }: RouteParams = route.params;

const students = computed(() => currentGroup.value?.students);
const exercises = computed(() => courseStore.exercises);
const courseObj = computed(() => studentsStore.getCourseModule(group, course));

const activePanel = ref(reviewBy[reviewPer.value] || 0);

watch(
  () => activePanel.value,
  () => (reviewPer.value = reviewBy[activePanel.value]),
);
watch(
  () => selectedLesson.value,
  () => (lesson.value = selectedLesson.value?.id),
);

const truncateString = (str, length) => {
  return str.length > length ? str.slice(0, length) + '...' : str;
};

const load = () => {
  currentGroup.value = accountStore.groups.find(
    acGroup => acGroup.id === group,
  );
  if (!accountStore.selectedGroup) {
    accountStore.selectedGroup = currentGroup;
  }

  studentsStore.setCourseModule(route.params.group, route.params.course);
  courseStore.fetchCourse(route.params.course);
  courseStore.fetchExercises(route.params.course, route.params.lesson);
  studentsStore.fetchSubmissions(route.params.group, route.params.course);
  studentsStore.fetchEnrollments({
    group: route.params.group,
    course: route.params.course,
  });
};

watchEffect(() => {
  if (!courseObj.value) return;
  const currentLesson = courseObj.value?.summary?.find(
    l => l.lesson.id === lesson.value,
  );
  selectedLesson.value = {
    id: currentLesson.lesson.id,
    title: currentLesson.lesson.title,
  };
});

onMounted(() => {
  load();
});

watch(
  () => [exercises.value],
  () => {
    if (!exercises.value.find(ex => ex.id === exercise.value)) {
      exercise.value = exercises.value[0]?.id;
    }
  },
);

watch(
  () => [students.value],
  () => {
    if (!students.value.find(st => st.id === student.value)) {
      student.value = students.value[0]?.id;
    }
  },
);

const dropdownPT = computed(() =>
  lessonDropdownPT(courseObj.value?.course?.locale),
);
</script>

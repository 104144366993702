<template>
  <FormControl :title="title">
    <div class="flex flex-col space-y-2">
      <div class="flex">
        <span class="w-120 flex font-simplerBold">פעילות</span>
        <span class="w-40 flex font-simplerBold pr-3">
          {{ totalDuration }} שעות
        </span>
      </div>
      <div
        v-for="(task, index) in model"
        :key="task.id"
        class="flex items-center gap-2">
        <div
          class="flex items-center gap-2 p-2 border overflow-hidden w-120 focus-within:border-primary rounded-lg">
          <Pen class="stroke-secondary-800 h-3 w-3" />
          <input
            v-model="task.text"
            placeholder="התשובה שלי"
            type="text"
            class="flex flex-col h-6 focus:ring-0 border-none overflow-hidden w-full"/>
        </div>
        <div class="flex h-11 border rounded-lg">
          <input
            :value="task.duration ? task.duration / 60 : 0"
            type="number"
            class="custom-input bg-transparent w-18 focus:ring-0 border-none overflow-hidden px-3"
            @input="(event: any) => (task.duration = event.data * 60)"/>
          <div class="relative cursor-pointer pl-2">
            <ChevronUp
              class="absolute stroke-1 top-0.5 left-2"
              @click="task.duration += 60"/>
            <ChevronDown
              v-if="task.duration > 0"
              class="absolute stroke-1 bottom-0.5 left-2"
              @click="task.duration -= 60"/>
          </div>
        </div>
        <Trash2
          class="stroke-1 h-4 w-4 cursor-pointer"
          @click="deleteTask(index)"/>
      </div>
      <div
        class="flex items-center px-5 w-140 bg-primary-100 cursor-pointer pl-2 rounded-lg h-12"
        @click.stop="addTask()">
        + הוספה
      </div>
      <BaseAlert
        v-if="totalDuration !== totalHours"
        type="danger"
        text="כמות השעות ביממה היא 24, בדוק את חלוקת הזמנים שלך"
        class="w-140"/>
    </div>
  </FormControl>
</template>

<script setup lang="ts">
import { watch, computed } from 'vue';
import { Pen, ChevronUp, ChevronDown, Trash2 } from 'lucide-vue-next';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { useVModel } from '@vueuse/core';
import { BaseAlert } from '@amit/foundation';
import ObjectID from 'bson-objectid';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  tasks: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Object as any,
    default: () => {},
  },
  totalHours: {
    type: Number,
    default: 24,
  },
});

const emit = defineEmits(['update:modelValue']);
const model = useVModel(props, 'modelValue', emit);

const totalDuration = computed(() => {
  return model.value.reduce((sum, task) => sum + task.duration, 0) / 60;
});

const addTask = () => {
  model.value.push({
    id: ObjectID().toString(),
    text: '',
    duration: 0,
  });
};

const deleteTask = (taskIndex: any) => {
  if (model.value.length === 1 && model.value.length - 1 === taskIndex) {
    return;
  }
  model.value.splice(taskIndex, 1);
};

watch(
  () => props.modelValue,
  () => {
    if (!model.value.length) {
      const tasks = props.tasks.map((task: any) => ({
        id: task.id,
        text: task.text,
        duration: 0,
      }));
      model.value.push(...tasks);
    }
  },
);
</script>

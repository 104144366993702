import ObjectID from 'bson-objectid';

export function generateObjectId(): string {
  const objectID: any = new ObjectID();
  return objectID.toString();
}

export function hasChanges(
  selectedUser: Object,
  initialUserData: Object,
): boolean {
  const selectedUserNorrmalized = normalizeObject(selectedUser);
  const initialUserDataNormalized = normalizeObject(initialUserData);
  return (
    JSON.stringify(selectedUserNorrmalized) !==
    JSON.stringify(initialUserDataNormalized)
  );
}

export function normalizeObject(obj: Object): Object {
  const normalized = { ...obj };
  Object.keys(normalized).forEach(key => {
    if (normalized[key] === '' || normalized[key] === null) {
      delete normalized[key];
    }
  });
  return normalized;
}

export const TEACHER_TRAINING = 'הכשרת מורים';

<template>
  <div class="flex items-center gap-2 pt-2 cursor-pointer w-fit">
    <div
      class="h-6 w-6 flex items-center justify-center bg-secondary-300 rounded-full">
      +
    </div>
    <p>{{ $t('course_settings.add_new_unit') }}</p>
  </div>
</template>
<script setup lang="ts"></script>

<template>
  <div class="flex flex-1 flex-col gap-4">
    <div class="flex items-start justify-between">
      <div class="flex flex-col">
        <p class="font-simplerBold text-sm text-secondary-900">
          {{ course?.caption ?? '---' }}
        </p>
        <p class="font-simplerRegular text-sm text-secondary-800">
          המורה {{ group?.owner_id?.fullName ?? '----' }}
        </p>
      </div>
      <div
        v-for="(discipline, index) in course?.disciplines"
        :key="index"
        class="flex">
        <span
          class="h-5 rounded-full bg-secondary-75 px-2 py-1.5 text-xs text-secondary-900 border-1 border-secondary-250">
          {{ discipline }}
        </span>
      </div>
    </div>
    <div class="flex flex-col gap-1.5">
      <div class="flex justify-between">
        <p class="text-secondary-900 text-sm">{{ currentLessonText }}</p>
        <p class="text-secondary-900 text-sm max-w-[17em]">
          {{ Math.round(progress?.total_progress) }}%
        </p>
      </div>
      <ProgressBar
        class="w-full !h-[5px] group-hover:bg-secondary-75"
        :value="progress?.total_progress"
        :show-value="false"></ProgressBar>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProgressBar from 'primevue/progressbar';
import { type ICourse } from '../../../types/interfaces';
import { computed, PropType } from 'vue';
import { get } from 'lodash';

const props = defineProps({
  course: {
    type: Object as PropType<ICourse>,
    default: () => {},
  },
  progress: {
    type: Object,
    default: () => {},
  },
  group: {
    type: Object,
    default: () => {},
  },
});

const currentLessonText = computed(() => {
  if (props.progress?.total_progress >= 100) {
    return 'סיימת את הקורס, כל הכבוד!';
  }
  if (!props.progress?.summary?.length) {
    return null;
  }
  if (props.progress?.total_progress === 0) {
    return `${props.progress.summary[0].lesson?.title ?? '---'}`;
  }
  const lesson = props.progress?.summary?.find(
    lesson => get(props.progress, 'current_lesson') === lesson.lesson?.id,
  )?.lesson?.title;

  return `${lesson ?? '---'}`;
});
</script>

<template>
  <div
    class="flex bg-white px-5 py-6 gap-2 box-shadow: 0px 5% 10% rgba(12, 74, 110, 0.07)"
  >
    <button
      v-for="color of colors"
      class="h-10 w-10 flex justify-center items-center"
      :class="color"
      @click="updateModelValue(color)"
    >
      <Check v-if="selectedColor === color" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { Check } from 'lucide-vue-next';

const props = defineProps({
  colors: {
    type: Object as PropType<String[]>,
    required: true,
    default: ['#fffff'],
  },
});

const selectedColor = defineModel('selectedColor');

const updateModelValue = (color: string) => {
  selectedColor.value = color;
};
</script>

<style scoped></style>

<template>
  <div class="h-[calc(100vh-60px)] flex flex-col">
    <!-- Content above -->
    <div
      class="sticky top-0 flex flex-col gap-2.5 bg-secondary-75 pb-5 z-[1100]">
      <div class="flex flex-col gap-2.5">
        <PVSkeleton v-if="isLoading" width="15rem" height="1rem"></PVSkeleton>
        <div
          v-else
          class="flex items-center gap-0.5 text-sm text-secondary-500 cursor-pointer"
          @click="$router.back()">
          <div class="flex items-center gap-0.5">
            <ChevronRight :size="16" />
            {{ t('course_settings.home') }}
          </div>
          /
          <p class="text-sm text-secondary-500">
            {{ course.caption || t('course_settings.new_content') }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <div v-if="isLoading" class="flex gap-1">
            <PVSkeleton width="10rem" height="2.2rem"></PVSkeleton>
            <PVSkeleton width="10rem" height="2.2rem"></PVSkeleton>
          </div>
          <div v-else class="relative">
            <PVSelectButton
              v-model="activeSettings"
              :pt="selectButtonPT"
              :options="settings"
              :allow-empty="false"
              option-disabled="disabled">
              <template #option="{ option }">
                <div
                  class="relative flex gap-1.5 items-center"
                  @mouseleave="isDisabledHovered = false"
                  @mouseover="isDisabledHovered = !!option.disabled">
                  <component
                    :is="option.icon"
                    :size="16"
                    :class="{
                      'stroke-secondary-500':
                        option.title !== activeSettings.title,
                    }"/>
                  <p
                    :class="{
                      'text-secondary-500':
                        option.title !== activeSettings.title,
                    }">
                    {{ option.label }}
                  </p>
                </div>
              </template>
            </PVSelectButton>
            <span
              class="-rotate-12 absolute -top-2 -left-2 shake-badge font-simplerBold text-xs text-pink-500"
              :class="{ 'animate-shake': isDisabledHovered }">
              בקרוב
            </span>
          </div>

          <PVSkeleton
            v-if="isLoading"
            width="13rem"
            height="2.2rem"></PVSkeleton>
          <div v-else class="flex gap-2">
            <div v-if="course.id" class="flex gap-1.5">
              <PVButton
                v-if="course.published"
                severity="neutral"
                class="!m-0 !p-1.5 !rounded-md !border-0 !bg-white h-8 w-8 shadow-sm"
                :disabled="isSaving || hasChanges"
                @click="openPreview">
                <Play
                  :size="16"
                  class="stroke-secondary-800"
                  :class="{ 'opacity-50': isSaving || hasChanges }"/>
              </PVButton>
              <div
                @mouseover="showErrors = true"
                @mouseout="showErrors = false">
                <PVButton
                  outlined
                  :disabled="
                    isSaving ||
                    !isValid ||
                    !hasChanges ||
                    !canEdit ||
                    editingState.isAnyEditing
                  "
                  class="!m-0 !py-1.5 border-primary text-primary"
                  @click="saveCourse(false)">
                  <div class="flex items-center gap-1.5">
                    <span class="text-sm leading-sm">
                      {{ t('course_settings.save_settings') }}
                    </span>
                  </div>
                </PVButton>
              </div>
              <div
                @mouseover="showErrors = true"
                @mouseout="showErrors = false">
                <PVButton
                  :title="t('tooltips.toPreview')"
                  :disabled="isSaving || !isValid"
                  :loading="isSaving"
                  class="!m-0 !p-1.5 !rounded-md !border-0 h-8 w-8"
                  @click="navigateToBuilder">
                  <template #icon>
                    <ArrowUpLeft :size="16" />
                  </template>
                </PVButton>
              </div>
            </div>
            <div
              v-else
              @mouseover="showErrors = true"
              @mouseout="showErrors = false">
              <PVButton
                :disabled="isSaving || !isValid || editingState.isAnyEditing"
                class="!m-0 !py-1.5"
                @click="saveCourse">
                <div class="flex items-center gap-1.5">
                  <span class="text-sm leading-sm">
                    {{ t('course_settings.create_course_go_edit') }}
                  </span>
                  <Sparkles :size="13" :class="{ 'animate-spin': isSaving }" />
                </div>
              </PVButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Red div filling remaining space and scrollable -->
    <div class="flex-1 overflow-y-auto overflow-x-hidden no-scrollbar">
      <div
        class="flex flex-col gap-4"
        :class="{ 'pointer-events-none opacity-50': isSaving }">
        <ContentForm
          :course="course"
          :loading="isLoading"
          :can-edit="canEdit"/>
        <CourseCurriculum
          v-model:units="course.units"
          v-model:lessons="course.outline"
          :loading="isLoading"
          :can-edit="canEdit"/>
      </div>
    </div>
  </div>

  <BaseToast />
</template>
<script setup lang="ts">
import BaseToast from '/@/views/playground/components/BaseToast.vue';
import { computed, onMounted, ref, watch, provide, reactive } from 'vue';
import CourseCurriculum from '/@/views/Lobby/CourseSettings/CourseCurriculum.vue';
import ContentForm from '/@/views/Lobby/CourseSettings/ContentForm.vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchCourse } from '/@/app/services/courses';
import { selectButtonPT } from '/@/views/Lobby/styles';
import {
  Settings,
  Settings2,
  Play,
  Sparkles,
  ChevronRight,
  ArrowUpLeft,
} from 'lucide-vue-next';
import { useI18n } from 'vue-i18n';
import PVButton from 'primevue/button';
import PVSelectButton from 'primevue/selectbutton';
import PVSkeleton from 'primevue/skeleton';
import { useEditorStore } from '/@/app/store/editor';
import {
  compareObjects,
  courseSchema,
  compareRules,
} from '/@/views/Lobby/utils';
import { generateObjectId } from '/@/utils';
import { useToast } from 'primevue/usetoast';
import { useAccountStore } from '/@/app/store/account';

import { get, cloneDeep } from 'lodash';
import { ICourse, OutlineItem } from '/@/app/types/interfaces';
import { useEditingTracker } from '/@/views/Lobby/CourseSettings/useEditingTracker';
const { t } = useI18n();
const route = useRoute();
const router = useRouter(); // Access the router instance

// Reactive meta to track the current meta dynamically
const currentMeta = reactive({ ...route.meta });

// Function to update or add meta dynamically
const addMeta = confirmLeave => {
  const currentRoute = router.currentRoute.value;

  // Clone and update meta
  const newMeta = {
    ...currentRoute.meta,
    confirmLeave,
    confirmLeaveTitle: t('course_settings.confirm_leave_tile'),
    confirmLeaveDescription: t('course_settings.confirm_leave_description'),
  };

  // Replace the route's meta with the updated object
  currentRoute.meta = newMeta;

  // Update the reactive `currentMeta` to reflect changes
  Object.assign(currentMeta, newMeta);
};
const toast = useToast();
const editorStore = useEditorStore();
const accountStore = useAccountStore();
const { editingState } = useEditingTracker();

const userId = computed(() => accountStore.user.id);

const originalObj = ref({});
const initialUnit: OutlineItem = {
  _id: generateObjectId(),
  title: 'יחידה',
  type: 'unit',
};
const course = ref<Partial<ICourse>>({
  caption: '',
  description: '',
  units: [initialUnit],
  outline: [
    {
      id: generateObjectId(),
      title: 'נושא',
      type: 'lesson',
      unit: initialUnit._id,
    },
  ],
  cover:
    'https://res.cloudinary.com/dcodkxpej/image/upload/v1712662821/LessonsBackground/image-20240409-101444_pnudss.png',
  // gates: courseGates[1].title,
  settings: {
    // assessmentProfile: courseGates[1].id,
  },
  locale: 'he',
});

const isPublished = computed(() => course.value.published);

const isLoading = ref(false);
const isSaving = ref(false);
const isDisabledHovered = ref(false);
const settings = [
  {
    label: t('course_settings.general_settings'),
    icon: Settings,
  },
  {
    label: t('course_settings.advanced_settings'),
    icon: Settings2,
    disabled: true,
  },
];
const activeSettings = ref(settings[0]);

const fetchContent = courseId => {
  isLoading.value = true;
  const { onSuccess: onCourseFetched } = fetchCourse(courseId);

  onCourseFetched(({ data }) => {
    isLoading.value = false;
    course.value = data;
    originalObj.value = cloneDeep(course.value);
  });
};

const canEdit = computed(() => {
  if (!course.value.id) {
    return true;
  }
  const isCreator = get(course.value, 'created_by.id') === userId.value;

  const hasFullAccess = get(course.value, 'contributors', []).some(
    c =>
      get(c, 'user')?.id === userId.value && get(c, 'access') === 'FULL_ACCESS',
  );

  return isCreator || hasFullAccess;
});

const hasErrors = async course => {
  try {
    await courseSchema.validate(course, { abortEarly: false });
    return false; // No errors
  } catch (err) {
    // Transform Yup errors into a flat object
    const errors = err.inner.reduce((acc, error) => {
      acc[error.path] = error.message;
      return acc;
    }, {});
    return errors;
  }
};

const saveCourse = async (navigate = true) => {
  isSaving.value = true;
  course.value.total_lessons = get(course.value, 'outline', []).length;
  try {
    const courseId = await editorStore.createOrEditCourse(course.value);
    toast.add({
      severity: 'success',
      summary: `הקורס ${course.value.id ? 'עודכן' : 'נוצר'} בהצלחה`,
      life: 4000,
      position: 'top-right',
    });
    fetchContent(courseId);
    // originalObj.value = cloneDeep(course.value);
    if (navigate) editorStore.navigateToEditCourse(courseId);
    console.log(`Course created or edited successfully with ID: ${courseId}`);
  } catch (error) {
    console.error('Failed to create or edit course:', error);
  }

  isSaving.value = false;
};

onMounted(() => {
  if (route.params.course) {
    fetchContent(route.params.course);
  }
});

const hasChanges = ref(false);
const isValid = ref(false);
const errors = ref([]);
const showErrors = ref(false);
watch(
  () => [course.value, originalObj.value],
  async () => {
    hasChanges.value = compareObjects(
      course.value,
      originalObj.value,
      compareRules,
    );

    addMeta(hasChanges.value);

    errors.value = await hasErrors(course.value);
    console.log(errors.value);
    isValid.value = !errors.value;
  },
  { deep: true },
);

const navigateToBuilder = () => {
  if (hasChanges.value) {
    saveCourse(true);
  } else {
    editorStore.navigateToEditCourse(course.value.id);
  }
};

const openPreview = () => {
  const routeData = router.resolve({
    name: 'preview',
    params: {
      course: course.value.id,
      lesson: get(course.value, 'outline[0].id', null),
    },
  });

  window.open(routeData.href, '_blank');
};

provide('isCoursePublished', isPublished);
provide('errors', errors);
provide('showErrors', showErrors);
</script>

<style lang="scss">
//main:has(.course-settings) {
//  height: calc(100vh - 40px) !important;
//}
//main:has(.course-settings) > div {
//  padding: 0 !important;
//}
//.content:has(.course-settings),
//.content:has(.course-settings) > div {
//  display: block !important;
//}

.content:has(.course-settings) {
  > div {
    overflow: inherit;
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0) rotate(-12deg);
  }
  20%,
  60% {
    transform: translate(1px, -1px) rotate(-12deg);
  }
  40%,
  80% {
    transform: translate(-1px, 1px) rotate(-12deg);
  }
}

.animate-shake {
  animation: shake 0.4s ease-in-out;
}
</style>

<template>
  <PVDialog
    v-model:visible="isDeletedOpen"
    modal
    closable
    dismissable-mask
    class="transition-all duration-500"
    :style="{ width: '17vw', minWidth: '10rem' }"
    :pt-options="{ mergeSections: false, mergeProps: true }"
    :pt="{
      header: {
        class: ['!justify-end'],
      },
      content: {
        class: ['!border-t-0', 'pt-0'],
        style: {
          height: '18vh',
          minHeight: '10rem',
        },
      },
    }">
    <div class="flex flex-col gap-4 justify-center items-center">
      <component :is="icon" />
      <p class="font-simplerBold text-secondary-900 text-sm">
        {{ message }}
      </p>
    </div>
  </PVDialog>
</template>

<script setup lang="ts">
import PVDialog from 'primevue/dialog';

const isDeletedOpen = defineModel<boolean>();

defineProps({
  icon: {
    type: Object,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
});

defineEmits(['update:modelValue']);
</script>

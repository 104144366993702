<template>
  <div
    class="h-full add-new-lesson bg-white rounded-md py-2.5 px-4 flex items-center gap-2 shadow-md cursor-pointer hover:shadow-sm">
    <div
      class="w-8 h-8 bg-primary-200 rounded-full flex items-center justify-center">
      +
    </div>
    <p>{{ $t('course_settings.add_new_lesson') }}</p>
  </div>
</template>
<script></script>

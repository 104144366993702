<template>
  <!-- Page header -->
  <div class="flex justify-between items-start mb-5 p-5">
    <div class="mb-2 sm:mb-0 flex items-center justify-between w-full gap-6">
      <span class="text-secondary-900 font-simplerBold font-black text-xl">
        {{ t('schools') }}
      </span>
      <div ref="optionsContainer" class="relative rounded-md">
        <PVButton
          :label="t('csvExport')"
          :style="{
            paddingInline: '0.75rem',
            paddingBlock: '0.375rem',
            margin: 0,
          }"
          :loading="csvLoading"
          class="text-sm cursor-pointer"
          @click="showDownloadOptions = true">
          <template #icon>
            <span>
              <Download :size="14" />
            </span>
          </template>
        </PVButton>
        <div
          v-if="showDownloadOptions"
          class="options-modal absolute z-[100] left-0 top-[130%] w-[9.5rem] h-[6rem] bg-white flex flex-col items-start gap-2 rounded-md p-3"
          @click.stop>
          <button
            class="w-full py-2 px-2 hover:bg-secondary-75 active:bg-secondary-200 text-sm flex items-center justify-start rounded-md"
            @click.stop="exportSurveysCsv">
            {{ t('csvExportQuestionaires') }}
          </button>
          <button
            class="w-full py-2 px-2 hover:bg-secondary-75 active:bg-secondary-200 text-sm flex items-center justify-start rounded-md"
            @click.stop="setVisible">
            {{ t('csvExportByCourse') }}
          </button>
        </div>
      </div>
    </div>
    <BaseModal
      v-if="visible"
      size="md"
      padding="px-5 pt-2 pb-4"
      body-padding="p-5"
      header-padding="px-5 py-2"
      persistent
      @close="closeModal">
      <template #header>
        <div class="flex-1 flex items-center">
          <span class="fond-semibold text-sm">{{ t('csvExport') }}</span>
        </div>
      </template>
      <template #body>
        <div class="flex flex-col items-start justify-start gap-3 h-[11rem]">
          <div>
            <p class="text-sm">{{ t('csvExportTextFirst') }}</p>
            <p class="text-sm">{{ t('csvExportTextSecond') }}</p>
          </div>
          <PVAutoComplete
            v-model="filteredCourse"
            option-label="caption"
            class="auto-complete-search"
            placeholder="חיפוש קורסים"
            empty-search-message="לא נמצא כזה קורס"
            :suggestions="filteredCourses"
            :virtual-scroller-options="{ itemSize: 32 }"
            :disabled="csvLoading"
            @update:model-value="toggleSelectionCourse"
            @complete="searchCourses"/>
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-end text-sm">
          <PVButton
            :loading="csvLoading"
            :disabled="isEmpty(selectedCourse)"
            @click="exportCsv">
            {{ t('csvExportAction') }}
          </PVButton>
        </div>
      </template>
    </BaseModal>
  </div>

  <BaseTable
    :headers="tableHeaders"
    :items="schoolStore.schools"
    :label="t('schools')"
    :row-class="({ manual }) => (manual ? 'bg-slate-100' : '')"
    include-search
    selection-mode="single"
    class="px-2.5"
    @on-row-select="onRowSelect">
    <template #actions="{ data }">
      <div class="flex items-center gap-2 cursor-pointer">
        <RefreshCcwIcon
          v-if="!data.manual"
          :size="16"
          class="hover:text-primary cursor-pointer"
          :class="{ 'animate-spin': data.syncing }"
          @click.stop="() => schoolStore.syncSchool(data)"/>
      </div>
    </template>
  </BaseTable>

  <CreateSchoolModal ref="createSchool" />
</template>

<script setup lang="ts">
import CreateSchoolModal from '/@/views/playground/modals/CreateSchoolModal.vue';
import BaseTable from '/@/views/playground/partials/BaseTable.vue';
import { ref, onMounted, nextTick, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSchoolStore } from '/@/views/playground/store/school-store';
import { useCourseStore } from '/@/app/store/course';
import { RefreshCcwIcon, Download } from 'lucide-vue-next';
import { useElementBounding } from '@vueuse/core';
import { useNavigation } from '@amit/layout';
import { useRouter } from 'vue-router';
import { getSchoolEnrollmentsCsv } from '/@/app/services/facilities';
import {
  exportSurveyEnrollments,
  exportSurveyFillRate,
} from '/@/app/services/surveys';
import BaseModal from '@/components/Modal/BaseModal.vue';
import PVAutoComplete from 'primevue/autocomplete';
import PVButton from 'primevue/button';
import { isEmpty } from 'lodash';
import {
  downloadExcel,
  handleDownloadError,
} from '/@/app/composables/excellDownloadUtils';
import swal from 'sweetalert';
import DownloadStart from '/@/assets/DownloadStart.png';

const router = useRouter();

const createSchool = ref(null);
const tableContainer = ref(null);
const tableContainerRect = ref(null as any);

const visible = ref(false);

const setVisible = () => {
  visible.value = true;
};
const closeModal = () => {
  selectedCourse.value = '';
  filteredCourse.value = null;
  visible.value = false;
};

const showDownloadOptions = ref(false);

const optionsContainer = ref();

const handleClickOutside = event => {
  if (
    optionsContainer.value &&
    !optionsContainer.value.contains(event.target)
  ) {
    showDownloadOptions.value = false;
  }
};

onMounted(async () => {
  await courseStore.fetchCourses();
  await nextTick(() => {
    tableContainerRect.value = useElementBounding(tableContainer.value);
  });
  document.addEventListener('click', handleClickOutside);
});

const schoolStore = useSchoolStore();
const courseStore = useCourseStore();

// const loading = ref(true)
schoolStore.init().then(() => {});

const messages = {
  he: {
    schools: 'בתי ספר',
    headers: {
      schoolName: 'שם בית ספר',
      location: 'מיקום',
      institutionSymbol: 'סמל מוסד',
      institutionCode: 'קוד מוסד',
      id: 'מספר זיהוי',
      students: 'תלמידים',
      courses: 'מורים',
      grades: 'ציונים',
      groups: 'קבוצות',
    },
    csvExport: 'ייצוא דו"ח פעילות',
    csvExportFull: 'ייצוא כל הדוח',
    csvExportByCourse: 'ייצוא דוח לפי קורס',
    csvExportQuestionaires: 'ייצוא דוחות תצאמ',
    csvExportAction: 'ייצוא דו"ח',
    csvExportTextFirst: 'ניתן לייצא דוח עבור קורסים ספציפיים.',
    csvExportTextSecond: 'יש לחפש את הקורס הרצוי.',
  },
};

const courses = computed(() => courseStore.courses);

const selectedCourse = ref('');
const filteredCourse = ref();
const filteredCourses = ref();

const courseName = ref('');

const searchCourses = event => {
  if (!event.query.trim().length) {
    filteredCourses.value = [...courses.value];
  } else {
    filteredCourses.value = courses.value.filter(course =>
      course.caption.toLowerCase().startsWith(event.query.toLowerCase()),
    );
  }
};
const toggleSelectionCourse = (value: { id: string }) => {
  const extractedValue = JSON.parse(JSON.stringify(value));
  selectedCourse.value = extractedValue.id;
  courseName.value = extractedValue.caption;
};

const { t } = useI18n({ messages });

const tableHeaders = ref([
  { title: t('headers.schoolName'), align: 'start', key: 'name' },
  { title: t('headers.location'), align: 'start', key: 'location' },
  { title: t('headers.institutionSymbol'), align: 'start', key: 'semel' },
  { title: t('headers.institutionCode'), align: 'start', key: 'code' },
  { title: t('headers.students'), align: 'start', key: 'students' },
  { title: t('headers.courses'), align: 'start', key: 'courses' },
  { title: t('headers.groups'), align: 'start', key: 'groups' },
]);

// Hebrew translation for academic years
// const hebrewYears = {
//   2021: 'תשפ"ב', // 2021-2022
//   2022: 'תשפ"ג', // 2022-2023
//   2023: 'תשפ"ד', // 2023-2024
//   2024: 'תשפ"ה', // 2024-2025
//   2025: 'תשפ"ו', // 2025-2026
//   2026: 'תשפ"ז', // 2026-2027
//   2027: 'תשפ"ח', // 2027-2028
//   2028: 'תשפ"ט', // 2028-2029
//   2029: 'תש"פ', // 2029-2030
//   2030: 'תשפ"א', // 2030-2031
//   2031: 'תשפ"ב', // 2031-2032
//   2032: 'תשפ"ג', // 2032-2033
//   2033: 'תשפ"ד', // 2033-2034
//   2034: 'תשפ"ה', // 2034-2035
// };

// const changeYear = () => {
//   const { onSuccess } = setSystemConfig(accountStore.config.currentYear);
//   onSuccess(() => {
//     window.location.reload();
//   });
// };

useNavigation(
  computed({
    get() {
      return schoolStore.schools.map(school => {
        return {
          text: school.name,
          to: {
            name: 'school-home',
            params: { school: school.id },
          },
        };
      });
    },
    set() {},
  }),
);

const onRowSelect = event => {
  router.push({
    name: 'school-home',
    params: { school: event.data.id },
  });
};

const csvLoading = ref(false);

const onDownloadEnd = () => {
  csvLoading.value = false;
  selectedCourse.value = '';
  filteredCourse.value = null;
  closeModal();
};

const handleDownload = exportFunction => {
  return new Promise((resolve, reject) => {
    const { response, onSuccess, onError, error } = exportFunction();

    onSuccess(() => {
      downloadExcel(response.value);
      resolve();
    });

    onError(() => {
      handleDownloadError(error);
      reject();
    });
  });
};

const exportSurveysCsv = () => {
  csvLoading.value = true;

  swal({
    title: 'הורדת הקובץ מתבצעת',
    text: 'שים לב ההורדה עשויה להמשך כמספר דקות',
    buttons: false,
    icon: DownloadStart,
    timer: 3000,
  });

  Promise.all([
    handleDownload(exportSurveyEnrollments),
    handleDownload(exportSurveyFillRate),
  ])
    .then(() => {
      csvLoading.value = false;
    })
    .catch(() => {
      csvLoading.value = false;
    });
};

const exportCsv = () => {
  csvLoading.value = true;
  const allCoursesExported = isEmpty(selectedCourse.value);

  swal({
    title: 'הורדת הקובץ מתבצעת',
    text: 'שים לב ההורדה עשויה להמשך כמספר דקות',
    buttons: false,
    icon: DownloadStart,
    timer: 3000,
  });

  const { response, onSuccess, onError, error } = !allCoursesExported
    ? getSchoolEnrollmentsCsv(selectedCourse.value)
    : getSchoolEnrollmentsCsv();

  onSuccess(() => {
    downloadExcel(response.value, courseName.value);
    onDownloadEnd();
  });

  onError(() => {
    handleDownloadError(error);
    onDownloadEnd();
  });
};
</script>

<style>
.auto-complete-search input {
  width: 376px;
}
.options-modal {
  box-shadow: 0px 1px 6px 2px #0c4a6e0d;
  box-shadow: 0px 2px 10px 0px #0c4a6e1a;
}
.swal-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
